import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";

import Swal from "sweetalert2";

import { useSelector } from "react-redux";
import moment from "moment";
import SeeAttachment from "../includes/SeeAttachment";
import AdminFooter from "../includes/AdminFooter";
import ToTop from "../includes/ToTop";
import HeaderSidebar from "../includes/HeaderSidebar";
import UserService from "../../../services/user.service";
import ProductService from "../../../services/product.service";

export default function EditClaim() {
  const states = useSelector((states) => states.stateVals);
  const { id: userId } = states;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  const [name, setName] = useState("");

  const [size, setSize] = useState([]);
  const [sizeVal, setSizeVal] = useState("");
  const [price, setPrice] = useState("");
  const [ship_quantity, setShipQuantity] = useState("");
  const [split_sale_status, setSplitSaleStatus] = useState("");
  const [reward, setReward] = useState("");
  const [deliver_invoice, setDeliverInvoice] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [role, setRole] = useState("");
  const [file, setFile] = useState("");
  const [attachment, setAttachment] = useState("");

  const [storeName, setStoreName] = useState("");
  const [storeState, setStateName] = useState("");
  const [storeCity, setCityName] = useState("");
  const [storeAddress, setStoreAddress] = useState("");
  const [storeZip, setStoreZip] = useState("");
  const [storeAccount, setStoreAccount] = useState("");
  const [emp_number, setEmpNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [fax, setFax] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [creationId, setCreationId] = useState("");

  const [product, setProduct] = useState([]);

  useEffect(() => {
    const getProduct = async (id) => {
      try {
        const { data } = await ProductService.getProductForAdmin();
        const { response: res } = data;
        const results = [];
        res.map((value) => {
          results.push({
            key: value.name,
            value: value.id,
          });
        });
        setProduct([{ key: "Select Product", value: "" }, ...results]);
      } catch (error) {
        setProduct([]);
      }
    };
    const getClaimDetail = async () => {
      try {
        setLoading(true);

        const { data } = await UserService.getClaimDetail(id);
        const { response: res } = data;
        console.log(res);
        let rewardAmount =
          res[0].split_sale_status == "complete "
            ? res[0].ship_quantity * res[0].price
            : res[0].ship_quantity * (res[0].price / 2);
        setName(res[0].prod_id);

        setCreationId(res[0].creation_id);

        setShipQuantity(res[0].ship_quantity);
        setSplitSaleStatus(res[0].split_sale_status);
        setReward(rewardAmount);
        setDeliverInvoice(res[0].deliver_invoice);
        setInvoiceDate(res[0].invoice_date);
        setRole(res[0].role);
        setFile(res[0].file);

        setStoreName(res[0].store_name);
        setStateName(res[0].state_name);
        setCityName(res[0].city_name);
        setStoreAddress(res[0].store_address);
        setStoreZip(res[0].store_zip);
        setStoreAccount(res[0].store_account);

        setEmpNumber(res[0].emp_number);
        setFullName(res[0].first_name + " " + res[0].last_name);
        setUsername(res[0].username);
        setEmail(res[0].email);
        setPhone(res[0].phone);
        setFax(res[0].fax);
        setAddress1(res[0].address1);
        setAddress2(res[0].address2);
        changeSize(res[0].prod_id, res[0].creation_id);
        getProduct(res[0].creation_id);
        setPrice(res[0].price);
        setSizeVal(res[0].size_id);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        navigate("/dashboard");
      }
    };

    getClaimDetail();
  }, []);

  const changeSize = (e, creationId) => {
    setPrice("");
    setSizeVal("");
    setName(e);

    const getSize = async () => {
      try {
        const { data } = await ProductService.getSizeByAdminProduct(e);
        const { response: res } = data;
        const results = [];
        res.map((value) => {
          results.push({
            key: value.size,
            code: value.code,
            value: value.id,
          });
        });
        setSize([...results]);
      } catch (error) {
        setSize([]);
      }
    };

    if (e !== "") {
      getSize();
    } else {
      setSize([]);
    }
  };

  const fetchPrice = (e) => {
    setSizeVal(e);
    setPrice("");
    const getPrice = async () => {
      try {
        const { data } = await UserService.getProdPrice(creationId, name, e);

        const { response } = data;
        setPrice(response);
      } catch (error) {
        setPrice(0);
      }
    };

    if (e != "") {
      getPrice();
    }
  };

  const claimEdit = () => {
    const claimUpgrade = async () => {
      try {
        setLoading(true);
        const response = await UserService.updateClaim(
          name,
          sizeVal,
          creationId,
          id
        );
        console.log(response);
        toast.success(response.data.response, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    if (name !== "" && sizeVal !== "") {
      claimUpgrade();
    } else {
      toast.error("Select product and size", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  return (
    <div className="semi-dark">
      <div className="wrapper">
        <ToastContainer />

        <HeaderSidebar />
        <main className="page-content">
          <div className="row">
            <div className="col">
              <div className="manage-heading-2">
                <h2>Edit Claim</h2>
              </div>

              <div className="detail-form">
                <div className="main-content-box">
                  {/* ******** Claim Info Start ******** */}
                  <div className="claim-outer">
                    <div className="manage-territories-box">
                      <h2 className="manage-territories-heading">
                        CLAIM INFORMATION
                      </h2>

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-floating">
                            <select
                              className="form-select"
                              value={name}
                              onChange={(e) => {
                                changeSize(e.target.value, creationId);
                              }}
                            >
                              {/* <option value="" selected={true} disabled={true}>
                                Select Product
                              </option> */}
                              {product.map((res) => {
                                return (
                                  <option key={res.value} value={res.value}>
                                    {res.key}
                                  </option>
                                );
                              })}
                            </select>

                            <label>Product Name</label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-floating">
                            <select
                              className="form-select"
                              value={sizeVal}
                              onChange={(e) => {
                                fetchPrice(e.target.value);
                              }}
                            >
                              <option value="">Select Product Size</option>
                              {size.map((res) => {
                                return (
                                  <option key={res.value} value={res.value}>
                                    {res.key}
                                  </option>
                                );
                              })}
                            </select>

                            <label>Product Size</label>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Product Price"
                              name="price"
                              value={`$ ${price}`}
                              disabled
                            />
                            <label>Product Price</label>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Quantity"
                              name="ship_quantity"
                              value={ship_quantity || ""}
                              disabled
                            />
                            <label>Quantity</label>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Split sale"
                              name="split_sale_status"
                              value={
                                split_sale_status == "complete" ? "No" : "Yes"
                              }
                              disabled
                            />
                            <label>Split Sale</label>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Reward Amount"
                              name="reward"
                              value={`$ ${
                                split_sale_status == "complete"
                                  ? ship_quantity * price
                                  : ship_quantity * (price / 2)
                              }`}
                              disabled
                            />
                            <label>Reward Amount</label>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Invoice No"
                              name="deliver_invoice"
                              value={deliver_invoice || ""}
                              disabled
                            />
                            <label>Invoice No.</label>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Invoice Date"
                              name="invoiceDate"
                              value={
                                invoiceDate == "0000-00-00"
                                  ? "N/A"
                                  : moment(invoiceDate).format("MM-DD-YYYY")
                              }
                              disabled
                            />
                            <label>Invoice Date</label>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="User Role"
                              name="role"
                              value={role.toUpperCase()}
                              disabled
                            />
                            <label>User Role</label>
                          </div>
                        </div>

                        <div className="col-lg-8">
                          <div className="box-attachment">
                            <label
                              className="text-center"
                              style={{
                                marginLeft: "8px",
                                color: "#808080b0",
                                fontSize: "13px",
                              }}
                            >
                              Attachment
                            </label>
                            <br />
                            <Link
                              to="/"
                              className="orng-textbtn"
                              loading="lazy"
                              onClick={() => {
                                setAttachment(
                                  process.env.REACT_APP_IMAGE_Link + file
                                );
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              type="button"
                            >
                              See Attachment
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />

                  {/* ******** User Info Start ******** */}
                  <div className="claim-outer">
                    <div className="manage-territories-box">
                      <h2 className="manage-territories-heading">
                        STORE INFORMATION
                      </h2>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Store Name"
                              name="storeName"
                              value={storeName || ""}
                              disabled
                            />
                            <label>Store Name</label>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Store State"
                              name="storeState"
                              value={storeState || ""}
                              disabled
                            />
                            <label>Store State</label>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Store City"
                              name="storeCity"
                              value={storeCity || ""}
                              disabled
                            />
                            <label>Store City</label>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Store Address"
                              name="storeAddress"
                              value={storeAddress || ""}
                              disabled
                            />
                            <label>Store Address</label>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Zip"
                              name="storeZip"
                              value={storeZip == "" ? "N/A" : storeZip}
                              disabled
                            />
                            <label>Zip</label>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Account"
                              name="storeAccount"
                              value={storeAccount || ""}
                              disabled
                            />
                            <label>Account</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  {/* ******** Store Info Start ******** */}
                  <div className="claim-outer">
                    <div className="manage-territories-box">
                      <h2 className="manage-territories-heading">
                        USER INFORMATION
                      </h2>
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Employee No"
                              name="emp_number"
                              value={emp_number || ""}
                              disabled
                            />
                            <label>Employee No.</label>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Full Name"
                              name="fullName"
                              value={fullName || ""}
                              disabled
                            />
                            <label>Full Name</label>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="User Name"
                              name="username"
                              value={username || ""}
                              disabled
                            />
                            <label>User Name</label>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Email"
                              name="email"
                              value={email || ""}
                              disabled
                            />
                            <label>Email</label>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Phone No"
                              name="phone"
                              value={phone || ""}
                              disabled
                            />
                            <label>Phone No</label>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Fax No"
                              name="fax"
                              value={fax == "" ? "N/A" : fax}
                              disabled
                            />
                            <label>Fax No.</label>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Address 1"
                              name="address1"
                              value={address1 || ""}
                              disabled
                            />
                            <label>Address 1</label>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Address 2"
                              name="address2"
                              value={address2 == "" ? "N/A" : address2}
                              disabled
                            />
                            <label>Address 2</label>
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-center mt-3">
                        <div className="col-lg-4">
                          <button
                            type="button"
                            onClick={claimEdit}
                            className="btn btn-primary width-100 px-4 back-orange"
                          >
                            Update Claim
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ToTop />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
      <AdminFooter />
      <SeeAttachment src={attachment} />
    </div>
  );
}
