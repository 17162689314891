import { useFormik } from "formik";
import React, { useEffect, useLayoutEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import useSortableData from "../../../hooks/useSortableData";
import useTable from "../../../hooks/useTable";
import { quizSearch } from "../../../schema";
import UserService from "../../../services/user.service";
import Footer from "../Include/Footer";
import "../Include/general.css";
import Header from "../Include/Header";
import $ from "jquery";
import { Helmet } from "react-helmet";

export default function Welcome() {
  const TITLE = "Ashley Sleep Elite | Welcome";
  const state = useSelector((state) => state.stateVals);
  const { id } = state;

  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState("10");

  const [resultData, setResultData] = useState([]);

  const searchData = async (action) => {
    setLoading(true);

    try {
      const response = await UserService.getSearchRecentClaim(
        id,
        values.fieldtype,
        values.searchval,
        limit
      );

      let resultData;
      if (response.status === 200) {
        setLoading(false);
        resultData = response.data.response;
        setResultData(resultData);
        setLimit(response.data.per_page);
      } else {
        setResultData([]);
        setLoading(false);
        Swal.fire({
          text: "Error fetching record",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    } catch (err) {
      if (err.response.status === 500) {
        setLoading(false);
        Swal.fire({
          text: "Internal Server Error",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else {
        setLoading(false);
        setResultData([]);
      }
    }
  };

  useLayoutEffect(() => {
    const getClaimsData = async () => {
      setLoading(true);
      if (id) {
        try {
          const response = await UserService.recentClaim(id);

          let resultData;
          if (response.status === 200) {
            resultData = response.data.response;
            setResultData(resultData);
            setLimit(response.data.per_page);

            setLoading(false);
          }
        } catch (err) {
          if (err.response.status === 404) {
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      }
    };
    getClaimsData();
  }, [id]);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,

      initialValues: {
        fieldtype: "",
        searchval: "",
      },
      validationSchema: quizSearch,
      onSubmit: (values, action) => {
        // action.resetForm();
        searchData(action);
      },
    });

  const Table = ({ data, rowsPerPage }) => {
    const { slice } = useTable(data, 1, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table className="table caption-top align-middle table-borderless table-style-1">
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("id")}
                className={getClassNamesFor("id")}
              >
                No
              </th>
              <th
                scope="col"
                onClick={() => requestSort("deliver_invoice")}
                className={getClassNamesFor("deliver_invoice")}
              >
                Invoice No
              </th>
              <th
                scope="col"
                onClick={() => requestSort("price")}
                className={getClassNamesFor("price")}
              >
                Amount
              </th>
              <th
                scope="col"
                onClick={() => requestSort("sale_status")}
                className={getClassNamesFor("sale_status")}
              >
                Status
              </th>
              <th
                scope="col"
                onClick={() => requestSort("Action")}
                className={getClassNamesFor("Action")}
              >
                Action
              </th>
            </tr>
          </thead>

          {/* <thead className="searchHead">
          <tr role="row" className="tablesorter-filter-row tablesorter-ignoreRow">
              <td data-column="0">
                <input
                  type="search"
                  placeholder=""
                  className="tablesorter-filter disabled"
                  data-column="0"
                  disabled=""
                  data-lastsearchtime="1676628841434"
                />
              </td>
              <td data-column="1">
                <input
                  type="search"
                  placeholder="Search "
                  className="tablesorter-filter"
                  data-column="1"
                  data-lastsearchtime="1676628841434"
                />
              </td>
              <td data-column="2">
                <input
                  type="search"
                  placeholder="Search "
                  className="tablesorter-filter"
                  data-column="2"
                  data-lastsearchtime="1676628841434"
                />
              </td>
              <td data-column="3">
                <input
                  type="search"
                  placeholder="Search "
                  className="tablesorter-filter"
                  data-column="3"
                  data-lastsearchtime="1676628841434"
                />
              </td>
              <td data-column="4">
                <input
                  type="search"
                  placeholder=""
                  className="tablesorter-filter disabled"
                  data-column="4"
                  disabled=""
                  data-lastsearchtime="1676628841434"
                />
              </td>
            </tr>
          </thead> */}

          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{el.deliver_invoice}</td>
                  {/* <td>{"$ " + el.price}</td> */}
                  <td>
                    ${" "}
                    {el.custom_price == "yes"
                      ? el.reward / el.ship_quantity
                      : el.price}
                  </td>

                  {el.sale_status === "rejected" && (
                    <td className="rejected" style={{ color: "#dc3545" }}>
                      <i
                        className="fa fa-thumbs-down"
                        style={{ color: "#dc3545" }}
                      ></i>{" "}
                      Rejected
                    </td>
                  )}
                  {el.sale_status === "pending" && (
                    <td className="rejected" style={{ color: "#e0876c" }}>
                      <i
                        className="fa fa-clock-o"
                        style={{ color: "#e0876c" }}
                      ></i>{" "}
                      Pending
                    </td>
                  )}
                  {el.sale_status === "approved" && (
                    <td className="rejected" style={{ color: "#28a745" }}>
                      <i
                        className="fa fa-thumbs-up"
                        style={{ color: "#28a745" }}
                      ></i>{" "}
                      Approved
                    </td>
                  )}
                  {/* <td>
                    <span
                      style={{
                        color:
                        el.sale_status === "rejected"
                            ? "#dc3545"
                            : el.sale_status === "pending"
                            ? "#e0876c"
                            : el.sale_status === "approved"
                            ? "#28a745"
                            : "",
                        textTransform: "uppercase",
                      }}
                    >
                      {el.sale_status}
                    </span>
                  </td> */}
                  <td>
                    <Link
                      to={`/claim-detail/${el.sales_id}`}
                      className="orng-textbtn"
                    >
                      View Details
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="user-panel">
        <section className="main-ban">
          <div className="container">
            <Header />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-5">
                <div className="traning-awards">
                  <h1 className="mban-head">TRAINING & AWARDS</h1>
                  <h6 className="mban-ligh">Education Center</h6>
                  <a href="/submit-claim" className="red-round-btn">
                    {" "}
                    FILE A CLAIM{" "}
                  </a>
                </div>
              </div>
              <div className="col-lg-7">
                <img
                  src="images/mban-pik.png"
                  className="img-fluid mban-img"
                  alt="images top"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="blue-wlcm">
          <div className="container">
            <div className="bluebg-box">
              <h2>
                welcome to
                <span> Ashley Sleep Elite</span>
              </h2>
              <p>
                For questions regarding your claims Email:
                <a href="mailTo:ashleysleep@eliterewards.biz">
                  {" "}
                  ashleysleep@eliterewards.biz
                </a>{" "}
                or call <a href="tel:800-772-0396"> 800-772-0396</a>. Claims can
                also be faxed to <a href="fax:866-632-6450">866-632-6450</a>.
              </p>
            </div>
          </div>
        </section>

        <section className="claims-part">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <h2 className="gen-hed" id="home">
                  Recent <span className="slide-heading">Claims</span>
                </h2>
              </div>
              <div className="col-md-4">
                <div className="viewall-clm">
                  <a href="/prev-claims" className="red-round-btn">
                    View All Claims
                  </a>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="table-holder rzl-tabl">
                  {/* <form onSubmit={handleSubmit} noValidate>
                  <div className="row mt-3">
                    <div className="col-lg-3">
                      <select
                        className={`form-select ${
                          errors.fieldtype && touched.fieldtype
                            ? "is-danger"
                            : ""
                        }`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="fieldtype"
                        value={values.fieldtype || ""}
                        required
                      >
                        <option value="">Select</option>
                        <option value="1">Invoice No </option>
                        <option value="2">Amount</option>
                        <option value="3">Status</option>
                      </select>
                      {errors.fieldtype && touched.fieldtype ? (
                        <p className="help is-danger">{errors.fieldtype}</p>
                      ) : null}
                    </div>

                    <div className="col-lg-4 ">
                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control ${
                            errors.searchval && touched.searchval
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="searchval"
                          value={values.searchval || ""}
                          required
                        />

                        <button
                          className="btn btn-outline-secondary sebmit-dat"
                          type="submit"
                        >
                          Search
                        </button>
                      </div>
                      {errors.searchval && touched.searchval ? (
                        <p className="help is-danger">{errors.searchval}</p>
                      ) : null}
                    </div>

                    <div className="col-lg-1 ">
                      <button
                        className="btn btn-outline-secondary"
                        type="reset"
                        onClick={() => window.location.reload(false)}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </form> */}

                  <div className="table-responsive">
                    <Table data={resultData} rowsPerPage={limit} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
    </>
  );
}
