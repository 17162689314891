import { Route, Routes, useLocation, useNavigate } from "react-router";
import "./App.css";
import Login from "./Components/User/Login/Login";
import AdminLogin from "./Components/Admin/Login/AdminLogin";
import Dashboard from "./Components/Dashboard/Dashboard";
import AllClaims from "./Components/Admin/Claims/AllClaims";
import AcceptedClaims from "./Components/Admin/Claims/AcceptedClaims";
import RejectedClaims from "./Components/Admin/Claims/RejectedClaims";
import ArchivedClaim from "./Components/Admin/Claims/Archived_Claim/ArchivedClaim";
import ManageTerritory from "./Components/Admin/Stores/ManageTerritory";
import ViewDos from "./Components/Admin/Stores/ViewDos";
import ViewNdos from "./Components/Admin/Stores/ViewNdos";
import ViewRsa from "./Components/Admin/Participants/ViewRSAs";
import ViewManager from "./Components/Admin/Participants/ViewManager";
import ViewCorporateRSAs from "./Components/Admin/Participants/ViewCorporateRSAs";
import ViewDeactiveRSAs from "./Components/Admin/Participants/ViewDeactiveRSAs";
import ViewDeactiveManager from "./Components/Admin/Participants/ViewDeactiveManager";
import ProfileAdmin from "./Components/Admin/ProfileAdmin/ProfileAdmin";
import AddStates from "./Components/Admin/States/AddStates";
import ViewAllStates from "./Components/Admin/States/ViewAllStates";
import AddCities from "./Components/Admin/Cities/AddCities";
import ViewAllCities from "./Components/Admin/Cities/ViewAllCities";
import AddStore from "./Components/Admin/Store/AddStore";
import ViewAllStores from "./Components/Admin/Store/ViewAllStores";
import AddProduct from "./Components/Admin/Products/AddProduct";
import AddProductSize from "./Components/Admin/Products/AddProductSize";
import ViewProduct from "./Components/Admin/Products/ViewProduct";
import ReportsHistory from "./Components/Admin/Reports/ReportsHistory";
import Year from "./Components/Admin/1099/Year";
import AddNewsletter from "./Components/Admin/Newsletter/AddNewsletter";
import ManageNewsletter from "./Components/Admin/Newsletter/ManageNewsletter";
import SendNewsletter from "./Components/Admin/Newsletter/SendNewsletter";
import SendNewsletterkings from "./Components/Admin/Newsletter/SendNewsletterkings";
import ManageDosTerritory from "./Components/Admin/Stores/ManageDosTerritory";
import ViewAcceptedRsa from "./Components/Admin/ManageRsaDos/ViewAcceptedRsa";
import ViewAcceptedManagers from "./Components/Admin/AcceptedManagersDos/ViewAcceptedManagers";
import AllClaimsDos from "./Components/Admin/Claims/AllClaimsDos";
import AcceptedClaimsDos from "./Components/Admin/Claims/AcceptedClaimsDos";
import RejectedClaimsDos from "./Components/Admin/Claims/RejectedClaimsDos";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { bindActionCreators } from "redux";
import { actionCreaters } from "./Redux";
import Welcome from "./Components/User/Welcome/Welcome";
import PrevClaims from "./Components/User/Claims/PrevClaims";
import PrivacyPolicy from "./Components/Help/PrivacyPolicy";
import FAQ from "./Components/Faq/FAQ";
import Contact from "./Components/Help/Contact";
import PrivacyStatement from "./Components/PrivacyStatement/PrivacyStatement";
import ProfileInfo from "./Components/Account/ProfileInfo";
import { useContext } from "react";
import { UserContext } from "./Components/context/UserContext";
import W9Download from "./Components/User/W9-Form/W9Download";
import ViewDetails from "./Components/User/Claims/ViewDetails";
import FileClaims from "./Components/User/Claims/FileClaims";
import ForgotPass from "./Components/User/ForgotPassword/ForgotPass";
import Registration from "./Components/User/Registration/Registration";
import TermCondition from "./Components/User/Registration/TermCondition";
import ChangePass from "./Components/User/ChangePass/ChangePass";
import CheckForgetToken from "./Components/User/ForgotPassword/CheckForgetToken";
import RegPrivacyStatement from "./Components/User/Registration/RegPrivacyStatement";
import ViewDetail from "./Components/Dashboard/ViewDetail";
import EditState from "./Components/Admin/States/EditState";
import EditCity from "./Components/Admin/Cities/EditCity";
import EditStore from "./Components/Admin/Store/EditStore";
import AdminForgotPass from "./Components/Admin/AdminForgotPass/AdminForgotPass";
import CheckAdminForgetToken from "./Components/Admin/AdminForgotPass/CheckAdminForgetToken";
import AdminChangePass from "./Components/Admin/AdminForgotPass/AdminChangePass";
import EditUser from "./Components/Admin/Participants/EditUser";
import UpdateNewsletter from "./Components/Admin/Newsletter/UpdateNewsletter";
import ManageUserTypeStats from "./Components/Admin/AppStatistics/ManageUserTypeStats";
import EditProduct from "./Components/Admin/Products/EditProduct";
import UploadCards from "./Components/Manage 1099/UploadCards";
import SheetImport from "./Components/Manage 1099/SheetImport";
import Balance from "./Components/Manage 1099/Balance";
import SaleComparison from "./Components/Manage 1099/SaleComparison";
import ArchivedDosClaim from "./Components/Admin/Claims/Archived_Claim/ArchivedDosClaim";
import ViewStaticsClaims from "./Components/Admin/AppStatistics/ViewStaticsClaims";
import Login1099 from "./Components/1099Panel/Login/Login1099";
import Dashboard1099 from "./Components/1099Panel/Dashboard/Dashboard1099";
import MissingSales from "./Components/Manage 1099/MissingSales";
import AddMontlySales from "./Components/Manage 1099/AddMontlySales";
import ExportData from "./Components/Manage 1099/ExportData";
import AddUserStates from "./Components/Admin/UserStates/AddUserStates";
import ViewAllUserStates from "./Components/Admin/UserStates/ViewAllUserStates";
import AddUserCities from "./Components/Admin/UserCities/AddUserCities";
import ViewAllUserCities from "./Components/Admin/UserCities/ViewAllUserCities";
import EditUserCity from "./Components/Admin/UserCities/EditUserCity";
import EditUserState from "./Components/Admin/UserStates/EditUserState";
import EditClaim from "./Components/Admin/Claims/EditClaim";

function App() {
  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);
  const navigate = useNavigate();
  const locat = useLocation();
  const state = useSelector((state) => state.stateVals);
  // const { state, dispatch: ctxDispatch } = useContext(UserContext);
  const { id, accessToken, uType } = state;

  let decoded;

  useEffect(() => {
    // auto logout code start
    if (accessToken) {
      decoded = jwt_decode(accessToken);
      // console.log("expiry date:" + decoded.exp);
      // console.log("current date:" + Math.floor(new Date().getTime() / 1000));
      // var date = new Date(decoded.exp * 1000);
      // console.log(date.toLocaleTimeString("en-US"));
      // const current = new Date();
      // const time = current.toLocaleTimeString("en-US");
      // console.log(time);
    }
    // auto logout code end
    if (
      accessToken &&
      uType !== "admin" &&
      uType !== "dos" &&
      uType !== "ndos" &&
      uType !== "1099"
    ) {
      if (Math.floor(new Date().getTime() / 1000) > decoded.exp) {
        userActions.logOut(null);
        Swal.fire({
          title: "Expired!",
          text: "Your Login Session expired!",
          icon: "error",
          confirmButtonText: "Login Again",
        });
        navigate("/");
      }
      if (
        locat.pathname === "/" ||
        locat.pathname === "/forgot-pass" ||
        locat.pathname === "/registration" ||
        locat.pathname === "/forgot-pass/" ||
        locat.pathname === "/registration/" ||
        locat.pathname === "/register-statement" ||
        locat.pathname === "/register-statement/"
      ) {
        navigate("/welcome");
      }
    } else {
      if (
        locat.pathname !== "/forgot-pass" &&
        locat.pathname !== "/forgot-pass/" &&
        locat.pathname !== "/forgot-token" &&
        locat.pathname !== "/change-pass" &&
        locat.pathname !== "/term-and-conditions" &&
        locat.pathname !== "/register-statement" &&
        locat.pathname !== "/register-statement/" &&
        locat.pathname !== "/registration" &&
        locat.pathname.indexOf("admin") <= -1 &&
        locat.pathname.indexOf("1099") <= -1
      ) {
        navigate("/");
      }
    }

    // admin login conditions
    const callFun = window["onUrlChange"];
    if (
      accessToken &&
      uType !== "rsa" &&
      uType !== "manager" &&
      uType !== "corporate" &&
      uType !== "1099"
    ) {
      // auto logout code start
      if (Math.floor(new Date().getTime() / 1000) > decoded.exp) {
        userActions.logOut(null);
        Swal.fire({
          title: "Expired!",
          text: "Your Login Session expired!",
          icon: "error",
          confirmButtonText: "Login Again",
        });
        navigate("/admin");
      }
      // auto logout code end
      if (
        locat.pathname === "/admin" ||
        locat.pathname === "/admin/" ||
        locat.pathname === "/admin/forgot-pass" ||
        locat.pathname === "/admin/forgot-pass/"
      ) {
        callFun();
        navigate("/admin/dashboard");
      }
    } else {
      if (
        locat.pathname !== "/admin/forgot-pass" &&
        locat.pathname !== "/admin/forgot-token" &&
        locat.pathname !== "/admin/change-pass" &&
        locat.pathname !== "/admin/forgot-pass/" &&
        locat.pathname !== "/admin/change-pass/" &&
        locat.pathname.indexOf("admin") > -1
      ) {
        navigate("/admin");
      }
    }

    if (
      accessToken &&
      uType !== "rsa" &&
      uType !== "manager" &&
      uType !== "corporate" &&
      uType !== "admin" &&
      uType !== "dos" &&
      uType !== "ndos"
    ) {
      // auto logout code start
      if (Math.floor(new Date().getTime() / 1000) > decoded.exp) {
        userActions.logOut(null);
        Swal.fire({
          title: "Expired!",
          text: "Your Login Session expired!",
          icon: "error",
          confirmButtonText: "Login Again",
        });
        navigate("/admin");
      }
      // auto logout code end
      if (locat.pathname === "/1099" || locat.pathname === "/1099/") {
        callFun();
        navigate("/1099/dashboard");
      }
    } else {
      if (locat.pathname.indexOf("1099") > -1) {
        navigate("/1099");
      }
    }
  }, [locat.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/prev-claims" element={<PrevClaims />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/privacy-statement" element={<PrivacyStatement />} />
      <Route path="/profile-info" element={<ProfileInfo />} />
      <Route path="/w9-download" element={<W9Download />} />
      <Route path="/claim-detail/:id" element={<ViewDetails />} />
      <Route path="/submit-claim" element={<FileClaims />} />
      <Route path="/forgot-pass" element={<ForgotPass />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/term-and-conditions" element={<TermCondition />} />
      <Route path="/change-pass" element={<ChangePass />} />
      <Route path="/forgot-token" element={<CheckForgetToken />} />
      <Route path="/register-statement" element={<RegPrivacyStatement />} />

      {/* admin routing here  */}
      <Route path="/admin" element={<AdminLogin />} />
      <Route path="/admin/forgot-pass" element={<AdminForgotPass />} />
      <Route path="/admin/forgot-token" element={<CheckAdminForgetToken />} />
      <Route path="/admin/change-pass" element={<AdminChangePass />} />
      <Route path="/admin/profile-admin" element={<ProfileAdmin />} />
      <Route path="/admin/dashboard" element={<Dashboard />} />
      <Route path="/admin/dashboard/view-detail/:id" element={<ViewDetail />} />
      <Route path="/admin/claims" element={<AllClaims />} />
      <Route path="/admin/edit-claims/:id" element={<EditClaim />} />

      <Route path="/admin/accepted-claim" element={<AcceptedClaims />} />
      <Route path="/admin/rejected-claim" element={<RejectedClaims />} />
      <Route path="/admin/archived-claim" element={<ArchivedClaim />} />
      <Route path="/admin/archived-dos-claim" element={<ArchivedDosClaim />} />
      <Route path="/admin/manage-territory" element={<ManageTerritory />} />
      <Route path="/admin/view-dos" element={<ViewDos />} />
      <Route path="/admin/view-vp" element={<ViewNdos />} />
      <Route path="/admin/view-rsa" element={<ViewRsa />} />
      <Route path="/admin/view-manager" element={<ViewManager />} />
      <Route path="/admin/view-corporate" element={<ViewCorporateRSAs />} />
      <Route path="/admin/view-deactive-rsa" element={<ViewDeactiveRSAs />} />
      <Route path="/admin/edit/:type/:id" element={<EditUser />} />
      <Route
        path="/admin/view-deactive-manager"
        element={<ViewDeactiveManager />}
      />
      <Route path="/admin/add-state" element={<AddStates />} />
      <Route path="/admin/view-states" element={<ViewAllStates />} />
      <Route path="/admin/add-user-state" element={<AddUserStates />} />
      <Route path="/admin/view-user-states" element={<ViewAllUserStates />} />
      <Route path="/admin/add-city" element={<AddCities />} />
      <Route path="/admin/view-cities" element={<ViewAllCities />} />
      <Route path="/admin/add-user-city" element={<AddUserCities />} />
      <Route path="/admin/view-user-cities" element={<ViewAllUserCities />} />
      <Route path="/admin/add-store" element={<AddStore />} />
      <Route path="/admin/view-stores" element={<ViewAllStores />} />
      <Route path="/admin/add-product" element={<AddProduct />} />
      <Route path="/admin/add-product-size" element={<AddProductSize />} />
      <Route path="/admin/view-product" element={<ViewProduct />} />
      <Route path="/admin/reports-history" element={<ReportsHistory />} />
      <Route path="/admin/year" element={<Year />} />
      <Route path="/admin/add-newsletter" element={<AddNewsletter />} />
      <Route path="/admin/manage-newsletter" element={<ManageNewsletter />} />
      <Route path="/admin/send-newsletter" element={<SendNewsletter />} />
      <Route
        path="/admin/update-newsletter/:id"
        element={<UpdateNewsletter />}
      />

      <Route path="/admin/edit-state/:id" element={<EditState />} />
      <Route path="/admin/edit-city/:id" element={<EditCity />} />
      <Route path="/admin/edit-user-state/:id" element={<EditUserState />} />
      <Route path="/admin/edit-user-city/:id" element={<EditUserCity />} />
      <Route path="/admin/edit-store/:id" element={<EditStore />} />
      <Route path="/admin/edit-product/:id" element={<EditProduct />} />
      <Route
        path="/admin/send-newsletter-kings"
        element={<SendNewsletterkings />}
      />
      {/* 4 pages for this endpoint */}
      <Route
        path="/admin/manage-user-type/:userType/:userDevice"
        element={<ManageUserTypeStats />}
      />
      <Route
        path="/admin/view-statics-claims/:userId"
        element={<ViewStaticsClaims />}
      />

      <Route
        path="/admin/manage-dos-territory"
        element={<ManageDosTerritory />}
      />
      <Route path="/admin/view-accepted-rsa" element={<ViewAcceptedRsa />} />
      <Route
        path="/admin/view-accepted-manager"
        element={<ViewAcceptedManagers />}
      />
      <Route path="/admin/dos-latest-claims" element={<AllClaimsDos />} />
      <Route path="/admin/accepted-claim-dos" element={<AcceptedClaimsDos />} />
      <Route path="/admin/rejected-claim-dos" element={<RejectedClaimsDos />} />

      {/* 1099 Panel */}
      <Route path="/1099" element={<Login1099 />} />
      <Route path="/1099/dashboard" element={<Dashboard1099 />} />
      <Route path="/1099/balance/:type" element={<Balance />} />
      <Route path="/1099/sale-comparison" element={<SaleComparison />} />
      <Route path="/1099/upload-cards" element={<UploadCards />} />
      <Route path="/1099/sheet-import" element={<SheetImport />} />
      <Route path="/1099/missing-sales" element={<MissingSales />} />
      <Route path="/1099/add-montly-sales" element={<AddMontlySales />} />
      <Route path="/1099/export-1099-data" element={<ExportData />} />
    </Routes>
  );
}

export default App;
