import axios from "axios";
import React, { useEffect, useLayoutEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { toast, ToastContainer } from "react-toastify";
import AdminListService from "../../../src/services/admin-list.service";
import AdminFooter from "../../Components/Admin/includes/AdminFooter";
import CheckUtype from "../../Components/Admin/includes/CheckUtype";
import ToTop from "../../Components/Admin/includes/ToTop";
import HeaderSidebar1099 from "../1099Panel/includes/HeaderSidebar1099";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import { exportdata, montlySales, quizSearch } from "../schema";
import { useRef } from "react";

export default function ExportData() {
  const TITLE = "Ashley Sleep Elite | Export Data";
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState("");
  const [message, setMessage] = useState("");
  const [fileError, setFileError] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [date, setDate] = useState("");
  const [isFile, setIsFile] = useState(false);
  const [isBtn, setIsBtn] = useState(false);
  const [userType, setUserType] = useState("");
  const [sales, setSales] = useState("");

  const [salesFlag, setSalesFlag] = useState(false);
  const [startFlag, setStartFlag] = useState(false);
  const [endFlag, setEndFlag] = useState(false);

  const [isEndDate, setIsEndDate] = useState(false);
  const [isDate, setIsDate] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const dateRef = useRef(null);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const [isExport, setIsExport] = useState(true);

  const sysDate = new Date();
  var time = sysDate.getTime();

  const styles = {
    export: {
      pointerEvents: isExport ? "none" : "",
      opacity: isExport ? "0.6" : "1",
    },
  };
  useEffect(() => {
    setTimeout(() => {
      console.log("loader.....");
      setLoading(false);
    }, 500);
  }, []);

  const changeStartDate = (e) => {
    setEndFlag(true);
    let date = e;
    setStartDate(date);
    endDateRef.current.value = "";

    setIsEndDate(true);
  };

  const changeEndDate = (e) => {
    let date = e;
    setEndDate(date);
    setIsExport(false);
    let startSplit = startDate.split("-");
    let endSplit = date.split("-");
    let date1 = new Date(startSplit[0], startSplit[1], startSplit[2]);
    let date2 = new Date(endSplit[0], endSplit[1], endSplit[2]);
    if (date2 < date1) {
      endDateRef.current.value = "";
      toast.error("Ending date must be greater than starting date!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const changeDate = (e) => {
    console.log("start date");

    setIsFile(true);
    setIsBtn(true);
  };

  const addSaleTable = async (values, action) => {
    setLoading(true);
    console.log(values);
    try {
      const response = await AdminListService.addSaleTable(values);

      setLoading(false);
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // action.resetForm();
    } catch (err) {
      console.log(err);
      if (err.response.status === 422) {
        toast.error("Unprocessable Content !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err.response.status === 409) {
        toast.error(err.response.data.response, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Some thing went wrong!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setLoading(false);
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        fieldtype: "",
        date: "",
        start: "",
        end: "",
      },
      validationSchema: exportdata,
      onSubmit: (values, action) => {
        console.log("submitting");
        addSaleTable(values);
      },
    });

  const changeType = (e) => {
    let type = "";
    setUserType(type);
    setSalesFlag(false);
    setStartFlag(false);
    setEndFlag(false);

    if (e) {
      e == 1 ? (type = "rsa") : (type = "manager");
      setUserType(type);
      setSalesFlag(true);
    }
  };

  const changeSale = (e) => {
    setStartFlag(false);
    setEndFlag(false);

    setSales(e);
    if (e) {
      setStartFlag(true);
    }
  };

  useLayoutEffect(() => {
    const getIp = async () => {
      const res = await axios.get("https://geolocation-db.com/json/");
      const weIp = res.data.IPv4;

      secureLocalStorage.setItem("ip", weIp);
    };
    getIp();
  }, []);
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="semi-dark">
        <div className="wrapper">
          <CheckUtype />

          <HeaderSidebar1099 />
          <ToastContainer />

          <main className="page-content">
            <div className="row">
              <div className="col">
                <div className="manage-heading-2">
                  <h2>Export 1099 Data</h2>
                </div>

                <div className="slides-here">
                  <div className="alert alert-info">
                    <b>Info!</b> You can search your required data by putting
                    text in search box{" "}
                  </div>
                  <div className="main-content-box">
                    {errorData.length ? (
                      <div className="error-msg">
                        {errorData.map((res, index) => {
                          return (
                            <div className="alert alert-danger " key={index}>
                              <b>Note!</b> Problem in row number {res.row} ,
                              <b> Error! </b>
                              {res.errors}
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                    <form onSubmit={handleSubmit} noValidate>
                      <div className="row">
                        <div className="col-lg-3 mb-2">
                          <button
                            className="btn btn-outline-secondary"
                            type="reset"
                            onClick={() => window.location.reload(false)}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3">
                          <label>Select User Type</label>
                          <select
                            className={`form-select ${
                              errors.fieldtype && touched.fieldtype
                                ? "is-danger"
                                : ""
                            }`}
                            onChange={(e) => {
                              handleChange(e);
                              changeType(e.target.value);
                            }}
                            onBlur={handleBlur}
                            name="fieldtype"
                            value={values.fieldtype || ""}
                            required
                          >
                            <option value="">Select</option>
                            <option value="1">Rsa</option>
                            <option value="2">Manager</option>
                          </select>
                          {errors.fieldtype && touched.fieldtype ? (
                            <p className="help is-danger">{errors.fieldtype}</p>
                          ) : null}
                        </div>
                        <div className="col-lg-3 ">
                          <label>Sales Table</label>
                          <div className="input-group">
                            <input
                              type="text"
                              className={`form-control ${
                                errors.sales && touched.sales ? "is-danger" : ""
                              }`}
                              onChange={(e) => {
                                handleChange(e);
                                changeSale(e.target.value);
                              }}
                              // onBlur={handleBlur}
                              name="sales"
                              placeholder="Enter Sales Table Name"
                              value={values.sales || ""}
                              required
                            />
                          </div>
                          {errors.sales && touched.sales ? (
                            <p className="help is-danger">{errors.sales}</p>
                          ) : null}
                        </div>
                        <div className="col-lg-3">
                          <label>Select Start Date</label>
                          <div className="reacter-datepicker">
                            <input
                              type="date"
                              name="start"
                              ref={startDateRef}
                              className={`form-control ${
                                errors.start && touched.start ? "is-danger" : ""
                              }`}
                              onChange={(e) => {
                                handleChange(e);
                                changeStartDate(e.target.value);
                              }}
                              onBlur={handleBlur}
                              placeholder="mm-dd-yyyy"
                            />
                          </div>
                          {errors.start && touched.start ? (
                            <p className="help is-danger">{errors.start}</p>
                          ) : null}
                        </div>
                        <div className="col-lg-3">
                          <label>Select End Date</label>
                          <div className="reacter-datepicker">
                            <input
                              type="date"
                              name="end"
                              ref={endDateRef}
                              className={`form-control ${
                                errors.end && touched.end ? "is-danger" : ""
                              }`}
                              onChange={(e) => {
                                handleChange(e);
                                changeEndDate(e.target.value);
                              }}
                              onBlur={handleBlur}
                              placeholder="mm-dd-yyyy"
                            />
                          </div>
                          {errors.end && touched.end ? (
                            <p className="help is-danger">{errors.end}</p>
                          ) : null}
                        </div>
                      </div>
                      <br />
                      <div className="row">
                        <div className="col-lg-4">
                          <Link
                            className="btn-success btn btn-success-control width-100 back-blue"
                            to={`https://ae-api.elitestacks.com/v1/user/1099/export-1099-${userType}-sales/${sales}/${startDate}/${endDate}?time=${time}`}
                            download=""
                            onClick={isExport}
                            style={styles.export}
                          >
                            <span>
                              {" "}
                              <i className="fadeIn animated bx bx-file"></i>{" "}
                            </span>
                            Download Store Sample Excel Sheet Here
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>

                  {/*FIXME */}
                  {/* {message.map && (
                  <div className="alert alert-primary">{message}</div>
                )} */}

                  {/* {message.length
                  ? message.map((res) => {
                      return <div className="alert alert-primary">{res}</div>;
                    })
                  : ""} */}
                </div>
              </div>
            </div>
          </main>
          <ToTop />
          <div className={`loader ${loading ? "in" : ""}`}>
            <div className="spinner-border main-spin"></div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
}
