import axios from "axios";
import React, { useEffect, useState } from "react";
import ClaimDetailUp from "../../ClaimDetailUp/ClaimDetailUp";
import InputField from "../../InputField";
import Footer from "../Include/Footer";
import Header from "../Include/Header";

import { useFormik } from "formik";
import { submitUserSchema } from "../../schema";
import { toast } from "react-toastify";
import "./FileClaim.css";
import secureLocalStorage from "react-secure-storage";
import UserService from "../../../services/user.service";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreaters } from "../../../Redux";
import ProductService from "../../../services/product.service";

import { Helmet } from "react-helmet";

function FileClaims() {
  const TITLE = "Ashley Sleep Elite | File A Claims";
  // const formData = new FormData();
  const [inputType, setInputType] = useState("text");
  const initialInputTypes = [
    { id: 1, type: "date" },
    { id: 2, type: "date" },
    { id: 3, type: "date" },
  ];
  const [inputTypes, setInputTypes] = useState(initialInputTypes);

  const handleInputClick = (id) => {
    const updatedInputTypes = inputTypes.map((input) => {
      if (input.id === id) {
        return { ...input, type: "date" };
      }
      return input;
    });
    setInputTypes(updatedInputTypes);
  };

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);

  const [prod, setProd] = useState("");
  const [prod2, setProd2] = useState("");
  const [prod3, setProd3] = useState("");

  const [selSize, setSelSize] = useState("");
  const [selSize2, setSelSize2] = useState("");
  const [selSize3, setSelSize3] = useState("");

  const [price, setPrice] = useState("");
  const [price2, setPrice2] = useState("");
  const [price3, setPrice3] = useState("");

  const [product, setProduct] = useState([]);
  const [size, setSize] = useState([
    {
      key: "SELECT PRODUCT SIZE",
      code: "",
      value: "",
    },
  ]);
  const [size2, setSize2] = useState([
    {
      key: "SELECT PRODUCT SIZE",
      code: "",
      value: "",
    },
  ]);
  const [size3, setSize3] = useState([
    {
      key: "SELECT PRODUCT SIZE",
      code: "",
      value: "",
    },
  ]);

  const [split, setSplit] = useState("0");
  const [split2, setSplit2] = useState("0");
  const [split3, setSplit3] = useState("0");

  const [quantity, setQuantity] = useState(1);
  const [quantity2, setQuantity2] = useState(1);
  const [quantity3, setQuantity3] = useState(1);

  const [claimPriceLoader, setClaimPriceLoader] = useState(false);
  const [claimPriceLoader2, setClaimPriceLoader2] = useState(false);
  const [claimPriceLoader3, setClaimPriceLoader3] = useState(false);

  const [sizeLoader, setSizeLoader] = useState(false);
  const [sizeLoader2, setSizeLoader2] = useState(false);
  const [sizeLoader3, setSizeLoader3] = useState(false);
  const [usererror, setUsererror] = useState("");
  // const [emailerror, setEmailerror] = useState("");
  const [disable, setDisable] = useState(false);
  const [userCity, setUserCity] = useState([]);

  const [storeState, setStoreState] = useState(["Loading..."]);
  const [storeCity, setStoreCity] = useState(["Loading..."]);
  const [store, setStore] = useState(["Loading..."]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userActions = bindActionCreators(actionCreaters, dispatch);

  const state = useSelector((state) => state.stateVals);
  const { accessToken, id } = state;
  // console.log(id);

  const onFileChange = (e) => {
    setImage(e);
  };

  const onFileChange2 = (e) => {
    setImage2(e);
  };

  const onFileChange3 = (e) => {
    setImage3(e);
  };

  useEffect(() => {
    setProduct([]);
    const getProduct = async () => {
      try {
        const { data } = await ProductService.getProduct(id);
        const { response: res } = data;
        const results = [];
        res.map((value) => {
          results.push({
            key: value.name,
            value: value.id,
          });
        });
        setProduct([{ key: "SELECT PRODUCT", value: "" }, ...results]);
      } catch (error) {
        setProduct([{ key: "NO PRODUCT FOUND", value: "" }]);
      }
    };

    getProduct();
  }, []);

  const changeQuantity = (e) => {
    if (e == "") {
      e = "1";
    }

    setQuantity(e);
    setPrice("");
    const getPrice = async () => {
      try {
        setClaimPriceLoader(true);
        const { data } = await UserService.getProdPrice(id, prod, selSize);
        const { response } = data;
        setPrice(response * e);
        if (split == true) {
          setPrice((response * e) / 2);
        }
        setClaimPriceLoader(false);
      } catch (error) {
        setPrice(0);
        setClaimPriceLoader(false);
      }
    };

    if (e != "") {
      getPrice();
    }
  };

  const changeQuantity2 = (e) => {
    if (e == "") {
      e = "1";
    }
    setQuantity2(e);
    setPrice2("");
    const getPrice = async () => {
      try {
        setClaimPriceLoader2(true);
        const { data } = await UserService.getProdPrice(id, prod2, selSize2);
        const { response } = data;
        setPrice2(response * e);
        if (split2 == true) {
          setPrice2((response * e) / 2);
        }
        setClaimPriceLoader2(false);
      } catch (error) {
        setClaimPriceLoader2(false);
        setPrice2(0);
      }
    };

    if (e != "") {
      getPrice();
    }
  };

  const changeQuantity3 = (e) => {
    if (e == "") {
      e = "1";
    }
    setQuantity3(e);
    setPrice3("");
    const getPrice = async () => {
      try {
        setClaimPriceLoader3(true);
        const { data } = await UserService.getProdPrice(id, prod3, selSize3);
        const { response } = data;
        setPrice3(response * e);
        if (split3 == true) {
          setPrice3((response * e) / 2);
        }
        setClaimPriceLoader3(false);
      } catch (error) {
        setPrice3(0);
        setClaimPriceLoader3(false);
      }
    };

    if (e != "") {
      getPrice();
    }
  };

  const fetchPrice = (e) => {
    setSelSize(e);
    setPrice("");
    const getPrice = async () => {
      try {
        setClaimPriceLoader(true);
        const { data } = await UserService.getProdPrice(id, prod, e);

        const { response } = data;
        setPrice(response * quantity);
        if (split == true) {
          setPrice((response * quantity) / 2);
        }
        setClaimPriceLoader(false);
      } catch (error) {
        setClaimPriceLoader(false);
        setPrice(0);
      }
    };

    if (e != "") {
      getPrice();
    }
  };

  const fetchPrice2 = (e) => {
    setSelSize2(e);
    setPrice2("");
    const getPrice = async () => {
      try {
        setClaimPriceLoader2(true);
        const { data } = await UserService.getProdPrice(id, prod2, e);
        const { response } = data;
        setPrice2(response * quantity2);
        if (split2 == true) {
          setPrice2((response * quantity2) / 2);
        }
        setClaimPriceLoader2(false);
      } catch (error) {
        setClaimPriceLoader2(false);
        setPrice2(0);
      }
    };

    if (e != "") {
      getPrice();
    }
  };

  const fetchPrice3 = (e) => {
    console.log("price 3");
    setSelSize3(e);
    setPrice3("");

    const getPrice = async () => {
      try {
        setClaimPriceLoader3(true);
        const { data } = await UserService.getProdPrice(id, prod3, e);
        const { response } = data;
        console.log(split3);
        setPrice3(response * quantity3);
        if (split3 == true) {
          setPrice3((response * quantity3) / 2);
        }
        setClaimPriceLoader3(false);
      } catch (error) {
        setPrice3(0);
        setClaimPriceLoader3(false);
      }
    };

    if (e != "") {
      getPrice();
    }
  };

  const changeSplit = (e) => {
    setPrice("");
    setSplit(e);
    const getPrice = async () => {
      try {
        setClaimPriceLoader(true);
        const { data } = await UserService.getProdPrice(id, prod, selSize);
        const { response } = data;
        setPrice(response * quantity);
        if (e === true) {
          setPrice((response * quantity) / 2);
        }
        setClaimPriceLoader(false);
      } catch (error) {
        setPrice(0);
        setClaimPriceLoader(false);
      }
    };

    if (prod != "" && selSize != "") {
      getPrice();
    }
  };

  const changeSplit2 = (e) => {
    setPrice2("");
    setSplit2(e);
    const getPrice = async () => {
      try {
        setClaimPriceLoader2(true);
        const { data } = await UserService.getProdPrice(id, prod2, selSize2);
        const { response } = data;
        setPrice2(response * quantity2);
        if (e === true) {
          setPrice2((response * quantity2) / 2);
        }
        setClaimPriceLoader2(false);
      } catch (error) {
        setPrice2(0);

        setClaimPriceLoader2(false);
      }
    };

    if (prod2 != "" && selSize2 != "") {
      getPrice();
    }
  };

  const changeSplit3 = (e) => {
    setPrice3("");
    setSplit3(e);
    const getPrice = async () => {
      try {
        setClaimPriceLoader3(true);
        const { data } = await UserService.getProdPrice(id, prod3, selSize3);
        const { response } = data;
        setPrice3(response * quantity3);
        if (e === true) {
          setPrice3((response * quantity3) / 2);
        }
        setClaimPriceLoader3(false);
      } catch (error) {
        setPrice3(0);
        setClaimPriceLoader3(false);
      }
    };

    if (prod3 != "" && selSize3 != "") {
      getPrice();
    }
  };

  const changeSize = (e) => {
    setPrice("");
    setProd(e);
    setSize([
      {
        key: "SELECT PRODUCT SIZE",
        code: "",
        value: "",
      },
    ]);
    values.size = "";

    const getSize = async () => {
      try {
        setSizeLoader(true);
        const { data } = await ProductService.getSizeByUserProduct(id, e);
        const { response: res } = data;
        const results = [];
        res.map((value) => {
          results.push({
            key: value.size,
            code: value.code,
            value: value.id,
          });
        });
        setSize([
          {
            key: "SELECT PRODUCT SIZE",
            code: "",
            value: "",
          },
          ...results,
        ]);
        setSizeLoader(false);
      } catch (error) {
        setSizeLoader(false);
        setSize([
          {
            key: "NO PRODUCT FOUND",
            code: "",
            value: "",
          },
        ]);

        toast.error("This product is not available in any size.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    };

    if (e !== "") {
      setProd(e);
      getSize();
    }
  };

  const changeSize2 = (e) => {
    setPrice2("");
    setProd2(e);
    setSize2([
      {
        key: "SELECT PRODUCT SIZE",
        code: "",
        value: "",
      },
    ]);
    values.size2 = "";

    const getSize = async () => {
      try {
        setSizeLoader2(true);
        const { data } = await ProductService.getSizeByUserProduct(id, e);
        const { response: res } = data;
        const results = [];
        res.map((value) => {
          results.push({
            key: value.size,
            code: value.code,
            value: value.id,
          });
        });
        setSize2([
          {
            key: "SELECT PRODUCT SIZE",
            code: "",
            value: "",
          },
          ...results,
        ]);
        setSizeLoader2(false);
      } catch (error) {
        setSizeLoader2(false);
        setSize2([
          {
            key: "NO PRODUCT FOUND",
            code: "",
            value: "",
          },
        ]);

        toast.error("This product is not available in any size.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    };

    if (e !== "") {
      setProd2(e);
      getSize();
    }
  };

  const changeSize3 = (e) => {
    setPrice3("");
    setProd3(e);
    setSize3([
      {
        key: "SELECT PRODUCT SIZE",
        code: "",
        value: "",
      },
    ]);
    values.size3 = "";

    const getSize = async () => {
      try {
        setSizeLoader3(true);
        const { data } = await ProductService.getSizeByUserProduct(id, e);
        const { response: res } = data;
        const results = [];
        res.map((value) => {
          results.push({
            key: value.size,
            code: value.code,
            value: value.id,
          });
        });
        setSize3([
          {
            key: "SELECT PRODUCT SIZE",
            code: "",
            value: "",
          },
          ...results,
        ]);
        setSizeLoader3(false);
      } catch (error) {
        setSizeLoader3(false);
        setSize3([
          {
            key: "NO PRODUCT FOUND",
            code: "",
            value: "",
          },
        ]);

        toast.error("This product is not available in any size.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    };

    if (e !== "") {
      setProd3(e);
      getSize();
    }
  };

  const getStateName = async (val) => {
    try {
      const response = await UserService.getNameState(val);
      console.log(response);
      if (response.status === 200) {
        setStoreState(response.data.response.name);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCityName = async (val) => {
    try {
      const response = await UserService.getNameCity(val);

      if (response.status === 200) {
        setStoreCity(response.data.response.name);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getStoreName = async (val) => {
    try {
      const response = await UserService.getNameStore(val);

      if (response.status === 200) {
        setStore(response.data.response.name);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getProfile = async () => {
    setLoading(true);
    try {
      const response = await UserService.getProfile(accessToken);

      if (response.status === 200) {
        getStateName(response.data.store_state_id);
        getCityName(response.data.store_city_id);
        getStoreName(response.data.store_id);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);

      setLoading(false);
      navigate("/");
    }
  };

  useEffect(() => {
    getProfile();
  }, []);
  // }, [userActions, accessToken, navigate]);

  const resetPrices = () => {
    setPrice("");
    setPrice2("");
    setPrice3("");
  };

  const SubmitClaim = async (action, values) => {
    try {
      setLoading(true);
      const response = await UserService.SubmitClaim(values, id);
      const { data: res } = response;
      setLoading(false);

      toast.success(res.response, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      action.resetForm();
      resetPrices();
    } catch (err) {
      // FIXME first populate error by field name in state object then display it under the related
      //  field just for 422 status code
      if (err.response.status === 422) {
        setLoading(false);
        toast.error(err.response.data.response, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err.response.status === 500) {
        console.log(err.response.data);
        setLoading(false);
        toast.error("Something went wrong", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setLoading(false);
        toast.error(err.response.data.response, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,

      initialValues: {
        product: "",
        product2: "",
        product3: "",

        size: "",
        size2: "",
        size3: "",

        invoice: "",
        invoice2: "",
        invoice3: "",

        invoice_date: "",
        invoice_date2: "",
        invoice_date3: "",

        quantity: "",
        quantity2: "",
        quantity3: "",

        image: "",
        image2: "",
        image3: "",

        split1: " ",
        split2: " ",
        split3: " ",
      },
      validationSchema: submitUserSchema,
      // onSubmit: (values, action) => {
      //   // form data
      //   formData
      //     .append("split_status", values.split1)
      //     .append("product", values.product)
      //     .append("size", values.size)
      //     .append("invoice", values.invoice)
      //     .append("invoice_date", values.invoice_date)
      //     .append("quantity", values.quantity)
      //     .append("image", values.file)
      //     .append("ip", "192.168.10.1");
      //   SubmitClaim(formData, 1);
      //   // if (emailerror === "") {
      //   // }
      // },

      // TODO 2nd + 3rd forms insertion
      onSubmit: (values, action) => {
        var bodyFormData = new FormData();
        // FORM 1
        const splitStatus = values.split1 == "on" ? "1" : "0";
        bodyFormData.append("split_status", splitStatus);
        bodyFormData.append("product", values.product);
        bodyFormData.append("size", values.size);
        bodyFormData.append("invoice", values.invoice);
        bodyFormData.append("invoice_date", values.invoice_date);
        bodyFormData.append("quantity", values.quantity);
        bodyFormData.append("image", image, image.name);

        console.log("split=" + splitStatus);

        if (values.product2 != "") {
          // FORM 2
          const splitStatus2 = values.split2.includes("on") ? "1" : "0";
          bodyFormData.append("split_status2", splitStatus2);
          bodyFormData.append("product2", values.product2);
          bodyFormData.append("size2", values.size2);
          bodyFormData.append("invoice2", values.invoice2);
          bodyFormData.append("invoice_date2", values.invoice_date2);
          bodyFormData.append("quantity2", values.quantity2);
          bodyFormData.append("image2", image2, image2.name);
          console.log("split2=" + splitStatus2);
        }

        if (values.product3 != "") {
          // FORM 3
          const splitStatus3 = values.split3.includes("on") ? "1" : "0";
          bodyFormData.append("split_status3", splitStatus3);
          bodyFormData.append("product3", values.product3);
          bodyFormData.append("size3", values.size3);
          bodyFormData.append("invoice3", values.invoice3);
          bodyFormData.append("invoice_date3", values.invoice_date3);
          bodyFormData.append("quantity3", values.quantity3);
          bodyFormData.append("image3", image3, image3.name);
          console.log("split3=" + splitStatus3);
        }

        bodyFormData.append("ip", secureLocalStorage.getItem("ip"));

        SubmitClaim(action, bodyFormData, id);
        // action.resetForm();
      },
    });

  // const splitValue = (e) => {
  //   console.log("value=" + e);
  //   let value = "";
  //   if (e === "on") {
  //     value = "1";
  //   } else {
  //     value = "0";
  //   }
  //   return value;
  // };

  useEffect(() => {
    const getIp = async () => {
      try {
        const res = await axios.get("https://geolocation-db.com/json/");

        const weIp = res.data.IPv4;
        secureLocalStorage.setItem("ip", weIp);
      } catch (error) {}
    };
    getIp();
  }, [handleSubmit]);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="user-panel">
        <section className="main-ban page-heading">
          <div className="container">
            <Header />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* <div className="traning-awards">
                  <h1 className="mban-head w-100">HOPE TO DREAM SALES</h1>
                </div> */}
                <h2 className="gen-hed">
                  FILE A <span className="slide-heading">CLAIM</span>
                </h2>
              </div>
            </div>
          </div>
        </section>

        <section className="claims-part">
          <div className="container">
            <div className="row">
              <div className="Whitebg-box" id="FileClaimsBox">
                {/* <h2 className="gen-hed">
                  PRIVACY <span>POLICY</span>
                </h2> */}

                {/* FIXME loading */}
                <ClaimDetailUp
                  state={storeState}
                  city={storeCity}
                  store={store}
                />
                <form onSubmit={handleSubmit} noValidate>
                  <ul className="timeline claim_detail">
                    <li>
                      <div className="prfil-set">
                        <div className="form-box">
                          <div className="row">
                            <div className="col-12">
                              <div className="alert alert-danger" role="alert">
                                <h6 className="astric-req">
                                  Field(s) marked with <span>Asterisk (*)</span>{" "}
                                  are mandatory.
                                </h6>
                              </div>
                              <div className="chek-term">
                                <input
                                  id="radio-1"
                                  className="radio-custom"
                                  name="split1"
                                  onChange={(e) => {
                                    handleChange(e);
                                    changeSplit(e.target.checked);
                                  }}
                                  onBlur={handleBlur}
                                  type="checkbox"
                                />
                                <label
                                  htmlFor="radio-1"
                                  className={`radio-custom-label ${
                                    errors.split1 && touched.split1
                                      ? "is-danger"
                                      : ""
                                  }`}
                                >
                                  SPLIT SALE
                                </label>

                                {errors.split1 && touched.split1 ? (
                                  <p className="help is-danger">
                                    {errors.split1}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <br />

                          <div className="row ">
                            <div className="col-6">
                              <h4 className="prf-hed">PRODUCT INFORMATION</h4>{" "}
                            </div>

                            {price !== "" ? (
                              <div className="col-6">
                                <div className="claimPrice">
                                  Reward: ${price}
                                </div>{" "}
                              </div>
                            ) : (
                              ""
                            )}

                            {claimPriceLoader && (
                              <div className="col-6">
                                <div className="claimPrice">
                                  <span
                                    className="spinner-border spinner-border-smSplit1"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="form-filds">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="select-leading">
                                  <div
                                    className={`form-floating ${
                                      errors.product && touched.product
                                        ? "is-danger"
                                        : ""
                                    }`}
                                  >
                                    <select
                                      className="form-select"
                                      onChange={(e) => {
                                        changeSize(e.target.value);
                                        handleChange(e);
                                      }}
                                      onBlur={handleBlur}
                                      name="product"
                                      value={values.product || ""}
                                      required
                                    >
                                      {/* <option value="">SELECT PRODUCT</option> */}
                                      {product.map((res) => {
                                        return (
                                          <option
                                            key={res.key}
                                            value={res.value}
                                          >
                                            {res.key}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <label>
                                      <span>*</span> PRODUCT SOLD
                                    </label>
                                    {errors.product && touched.product ? (
                                      <p className="help is-danger">
                                        {errors.product}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="select-leading">
                                  {sizeLoader ? (
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : null}
                                  <div
                                    className={`form-floating ${
                                      errors.size && touched.size
                                        ? "is-danger"
                                        : ""
                                    }`}
                                  >
                                    <select
                                      className="form-select"
                                      onChange={(e) => {
                                        handleChange(e);
                                        fetchPrice(e.target.value);
                                      }}
                                      onBlur={handleBlur}
                                      name="size"
                                      value={values.size || ""}
                                      required
                                    >
                                      {size.map((res) => {
                                        return (
                                          <option
                                            key={res.value}
                                            value={res.value}
                                          >
                                            {`${res.key} 
                                            
                                            ${res.code ? `(${res.code})` : ""}`}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <label>
                                      <span>*</span> SIZE
                                    </label>
                                    {errors.size && touched.size ? (
                                      <p className="help is-danger">
                                        {errors.size}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <InputField
                                  errors={errors.invoice}
                                  touched={touched.invoice}
                                  values={values.invoice}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  placeholder="DELIVERED INVOICE No"
                                  spanText="DELIVERED INVOICE NO"
                                  fieldName="invoice"
                                  fieldType="text"
                                  required={true}
                                />
                              </div>

                              <div className="col-lg-6">
                                <div
                                  className={`form-floating ${
                                    errors.invoice_date && touched.invoice_date
                                      ? "is-danger"
                                      : ""
                                  }`}
                                >
                                  <input
                                    id={inputTypes[0].id}
                                    type={inputTypes[0].type}
                                    // onClick={() => handleInputClick(inputTypes[0].id)}
                                    onFocus={() =>
                                      handleInputClick(inputTypes[0].id)
                                    }
                                    placeholder=" "
                                    className="form-control"
                                    onChange={handleChange}
                                    onKeyUp={(e) => e.preventDefault()}
                                    onKeyDown={(e) => e.preventDefault()}
                                    onBlur={handleBlur}
                                    name="invoice_date"
                                    value={values.invoice_date || ""}
                                    required
                                  />
                                  <label className="date">
                                    <span>*</span> INVOICE DATE
                                  </label>
                                  {errors.invoice_date &&
                                  touched.invoice_date ? (
                                    <p className="help is-danger">
                                      {errors.invoice_date}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="select-leading">
                                  <div
                                    className={`form-floating ${
                                      errors.quantity && touched.quantity
                                        ? "is-danger"
                                        : ""
                                    }`}
                                  >
                                    <select
                                      className="form-select"
                                      onChange={(e) => {
                                        handleChange(e);
                                        changeQuantity(e.target.value);
                                      }}
                                      onBlur={handleBlur}
                                      name="quantity"
                                      value={values.quantity || ""}
                                      required
                                    >
                                      <option value="">SELECT QUANTITY</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                      <option value="6">6</option>
                                      <option value="7">7</option>
                                      <option value="8">8</option>
                                    </select>
                                    <label>
                                      <span>*</span> QUANTITY SHIPPED
                                    </label>
                                    {errors.quantity && touched.quantity ? (
                                      <p className="help is-danger">
                                        {errors.quantity}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <InputField
                                  className="file"
                                  errors={errors.image}
                                  touched={touched.image}
                                  values={values.image}
                                  handleChange={(e) => {
                                    handleChange(e);
                                    onFileChange(e.target.files[0]);
                                  }}
                                  handleBlur={handleBlur}
                                  placeholder="UPLOAD FILE"
                                  spanText="UPLOAD FILE"
                                  fieldName="image"
                                  fieldType="file"
                                  required={true}
                                />
                              </div>
                              <div className="col-md-12">
                                <p>
                                  {" "}
                                  You can upload only <b>
                                    jpg, jpeg, png, gif
                                  </b>{" "}
                                  and <b>pdf</b> files. Maximum size allowed is{" "}
                                  <b>10MB.</b>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="prfil-set">
                        <div className="form-box">
                          <div className="row">
                            <div className="col-12">
                              <div className="chek-term">
                                <input
                                  id="radio-2"
                                  className="radio-custom"
                                  name="split2"
                                  onChange={(e) => {
                                    handleChange(e);
                                    changeSplit2(e.target.checked);
                                  }}
                                  onBlur={handleBlur}
                                  type="checkbox"
                                />
                                <label
                                  htmlFor="radio-2"
                                  className={`radio-custom-label ${
                                    errors.split2 && touched.split2
                                      ? "is-danger"
                                      : ""
                                  }`}
                                >
                                  SPLIT SALE
                                </label>

                                {errors.split2 && touched.split2 ? (
                                  <p className="help is-danger">
                                    {errors.split2}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <br />

                          <div className="row">
                            <div className="col-lg-12">
                              <div className="row justify-content-between">
                                <div className="col-6">
                                  <h4 className="prf-hed">
                                    PRODUCT INFORMATION
                                  </h4>{" "}
                                </div>
                                {price2 !== "" ? (
                                  <div className="col-6">
                                    <div className="claimPrice2">
                                      Reward: ${price2}
                                    </div>{" "}
                                  </div>
                                ) : (
                                  ""
                                )}

                                {claimPriceLoader2 && (
                                  <div className="col-6">
                                    <div className="claimPrice2">
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="form-filds">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="select-leading">
                                  <div
                                    className={`form-floating ${
                                      errors.product2 && touched.product2
                                        ? "is-danger"
                                        : ""
                                    }`}
                                  >
                                    <select
                                      className="form-select"
                                      onChange={(e) => {
                                        changeSize2(e.target.value);
                                        handleChange(e);
                                      }}
                                      onBlur={handleBlur}
                                      name="product2"
                                      value={values.product2 || ""}
                                      required
                                    >
                                      {/* <option value="">PRODUCT SOLD</option> */}
                                      {product.map((res) => {
                                        return (
                                          <option
                                            key={res.value}
                                            value={res.value}
                                          >
                                            {res.key}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <label>
                                      <span>*</span> PRODUCT SOLD
                                    </label>
                                    {errors.product2 && touched.product2 ? (
                                      <p className="help is-danger">
                                        {errors.product2}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="select-leading">
                                  {sizeLoader2 ? (
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : null}
                                  <div
                                    className={`form-floating ${
                                      errors.size2 && touched.size2
                                        ? "is-danger"
                                        : ""
                                    }`}
                                  >
                                    <select
                                      className="form-select"
                                      onChange={(e) => {
                                        handleChange(e);
                                        fetchPrice2(e.target.value);
                                      }}
                                      onBlur={handleBlur}
                                      name="size2"
                                      value={values.size2 || ""}
                                      required
                                    >
                                      {size2.map((res) => {
                                        return (
                                          <option
                                            key={res.value}
                                            value={res.value}
                                          >
                                            {`${res.key} ${
                                              res.code ? `(${res.code})` : ""
                                            }`}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <label>
                                      <span>*</span> SIZE
                                    </label>
                                    {errors.size2 && touched.size2 ? (
                                      <p className="help is-danger">
                                        {errors.size2}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <InputField
                                  errors={errors.invoice2}
                                  touched={touched.invoice2}
                                  values={values.invoice2}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  placeholder="DELIVERED INVOICE NO"
                                  spanText="DELIVERED INVOICE NO"
                                  fieldName="invoice2"
                                  fieldType="text"
                                  required={true}
                                />
                              </div>

                              <div className="col-lg-6">
                                <div
                                  className={`form-floating ${
                                    errors.invoice_date2 &&
                                    touched.invoice_date2
                                      ? "is-danger"
                                      : ""
                                  }`}
                                >
                                  <input
                                    id={inputTypes[1].id}
                                    type={inputTypes[1].type}
                                    // onClick={() => handleInputClick(inputTypes[1].id)}
                                    onFocus={() =>
                                      handleInputClick(inputTypes[1].id)
                                    }
                                    placeholder=" "
                                    className="form-control"
                                    onKeyUp={(e) => e.preventDefault()}
                                    onKeyDown={(e) => e.preventDefault()}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name="invoice_date2"
                                    value={values.invoice_date2 || ""}
                                    required
                                  />
                                  <label className="date">
                                    <span>*</span> INVOICE DATE
                                  </label>
                                  {errors.invoice_date2 &&
                                  touched.invoice_date2 ? (
                                    <p className="help is-danger">
                                      {errors.invoice_date2}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="select-leading">
                                  <div
                                    className={`form-floating ${
                                      errors.quantity2 && touched.quantity2
                                        ? "is-danger"
                                        : ""
                                    }`}
                                  >
                                    <select
                                      className="form-select"
                                      onChange={(e) => {
                                        handleChange(e);
                                        changeQuantity2(e.target.value);
                                      }}
                                      onBlur={handleBlur}
                                      name="quantity2"
                                      value={values.quantity2 || ""}
                                      required
                                    >
                                      <option value="">SELECT QUANTITY</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                      <option value="6">6</option>
                                      <option value="7">7</option>
                                      <option value="8">8</option>
                                    </select>
                                    <label>
                                      <span>*</span> QUANTITY SHIPPED
                                    </label>
                                    {errors.quantity2 && touched.quantity2 ? (
                                      <p className="help is-danger">
                                        {errors.quantity2}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <InputField
                                  className="file"
                                  errors={errors.image2}
                                  touched={touched.image2}
                                  values={values.image2}
                                  handleChange={(e) => {
                                    handleChange(e);
                                    onFileChange2(e.target.files[0]);
                                  }}
                                  handleBlur={handleBlur}
                                  placeholder="UPLOAD IMAGE"
                                  spanText="UPLOAD IMAGE"
                                  fieldName="image2"
                                  fieldType="file"
                                  required={true}
                                />
                              </div>
                              <div className="col-md-12">
                                <p>
                                  {" "}
                                  You can upload only <b>
                                    jpg, jpeg, png, gif
                                  </b>{" "}
                                  and <b>pdf</b> files. Maximum size allowed is{" "}
                                  <b>10MB.</b>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="prfil-set">
                        <div className="form-box">
                          <div className="row">
                            <div className="col-12">
                              <div className="chek-term">
                                <input
                                  id="radio-3"
                                  className="radio-custom"
                                  name="split3"
                                  onChange={(e) => {
                                    handleChange(e);
                                    changeSplit3(e.target.checked);
                                  }}
                                  onBlur={handleBlur}
                                  type="checkbox"
                                />
                                <label
                                  htmlFor="radio-3"
                                  className={`radio-custom-label ${
                                    errors.split3 && touched.split3
                                      ? "is-danger"
                                      : ""
                                  }`}
                                >
                                  SPLIT SALE
                                </label>

                                {errors.split3 && touched.split3 ? (
                                  <p className="help is-danger">
                                    {errors.split3}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <br />

                          <div className="row">
                            <div className="col-lg-12">
                              <div className="row justify-content-between">
                                <div className="col-6">
                                  <h4 className="prf-hed">
                                    PRODUCT INFORMATION
                                  </h4>{" "}
                                </div>
                                {price3 !== "" ? (
                                  <div className="col-6">
                                    <div className="claimPrice3">
                                      Reward: ${price3}
                                    </div>{" "}
                                  </div>
                                ) : (
                                  ""
                                )}

                                {claimPriceLoader3 && (
                                  <div className="col-6">
                                    <div className="claimPrice3">
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="form-filds">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="select-leading">
                                  <div
                                    className={`form-floating ${
                                      errors.product3 && touched.product3
                                        ? "is-danger"
                                        : ""
                                    }`}
                                  >
                                    <select
                                      className="form-select"
                                      onChange={(e) => {
                                        changeSize3(e.target.value);
                                        handleChange(e);
                                      }}
                                      onBlur={handleBlur}
                                      name="product3"
                                      value={values.product3 || ""}
                                      required
                                    >
                                      {product.map((res) => {
                                        return (
                                          <option
                                            key={res.value}
                                            value={res.value}
                                          >
                                            {res.key}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <label>
                                      <span>*</span> PRODUCT SOLD
                                    </label>
                                    {errors.product3 && touched.product3 ? (
                                      <p className="help is-danger">
                                        {errors.product3}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="select-leading">
                                  {sizeLoader3 ? (
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  ) : null}
                                  <div
                                    className={`form-floating ${
                                      errors.size3 && touched.size3
                                        ? "is-danger"
                                        : ""
                                    }`}
                                  >
                                    <select
                                      className="form-select"
                                      onChange={(e) => {
                                        handleChange(e);
                                        fetchPrice3(e.target.value);
                                      }}
                                      onBlur={handleBlur}
                                      name="size3"
                                      value={values.size3 || ""}
                                      required
                                    >
                                      {size3.map((res) => {
                                        return (
                                          <option
                                            key={res.value}
                                            value={res.value}
                                          >
                                            {`${res.key} ${
                                              res.code ? `(${res.code})` : ""
                                            }`}
                                          </option>
                                        );
                                      })}
                                    </select>
                                    <label>
                                      <span>*</span> SIZE
                                    </label>
                                    {errors.size3 && touched.size3 ? (
                                      <p className="help is-danger">
                                        {errors.size3}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <InputField
                                  errors={errors.invoice3}
                                  touched={touched.invoice3}
                                  values={values.invoice3}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  placeholder="DELIVERED INVOICE NO"
                                  spanText="DELIVERED INVOICE NO"
                                  fieldName="invoice3"
                                  fieldType="text"
                                  required={true}
                                />
                              </div>

                              <div className="col-lg-6">
                                <div
                                  className={`form-floating ${
                                    errors.invoice_date3 &&
                                    touched.invoice_date3
                                      ? "is-danger"
                                      : ""
                                  }`}
                                >
                                  <input
                                    id={inputTypes[2].id}
                                    type={inputTypes[2].type}
                                    // onClick={() => handleInputClick(inputTypes[2].id)}
                                    onFocus={() =>
                                      handleInputClick(inputTypes[2].id)
                                    }
                                    className="form-control"
                                    // className="form-input"
                                    onChange={handleChange}
                                    onKeyUp={(e) => e.preventDefault()}
                                    onKeyDown={(e) => e.preventDefault()}
                                    onBlur={handleBlur}
                                    name="invoice_date3"
                                    value={values.invoice_date3 || ""}
                                    required
                                    placeholder=" "
                                  />
                                  <label className="date">
                                    <span>*</span> INVOICE DATE
                                  </label>
                                  {errors.invoice_date3 &&
                                  touched.invoice_date3 ? (
                                    <p className="help is-danger">
                                      {errors.invoice_date3}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="select-leading">
                                  <div
                                    className={`form-floating ${
                                      errors.quantity3 && touched.quantity3
                                        ? "is-danger"
                                        : ""
                                    }`}
                                  >
                                    <select
                                      className="form-select"
                                      onChange={(e) => {
                                        handleChange(e);
                                        changeQuantity3(e.target.value);
                                      }}
                                      onBlur={handleBlur}
                                      name="quantity3"
                                      value={values.quantity3 || ""}
                                      required
                                    >
                                      <option value="">SELECT QUANTITY</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                      <option value="6">6</option>
                                      <option value="7">7</option>
                                      <option value="8">8</option>
                                    </select>
                                    <label>
                                      <span>*</span> QUANTITY SHIPPED
                                    </label>
                                    {errors.quantity3 && touched.quantity3 ? (
                                      <p className="help is-danger">
                                        {errors.quantity3}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <InputField
                                  className="file"
                                  errors={errors.image3}
                                  touched={touched.image3}
                                  values={values.image3}
                                  handleChange={(e) => {
                                    handleChange(e);
                                    onFileChange3(e.target.files[0]);
                                  }}
                                  handleBlur={handleBlur}
                                  placeholder="UPLOAD IMAGE"
                                  spanText="UPLOAD IMAGE"
                                  fieldName="image3"
                                  fieldType="file"
                                  required={true}
                                />
                              </div>
                              <div className="col-md-12">
                                <p>
                                  {" "}
                                  You can upload only <b>
                                    jpg, jpeg, png, gif
                                  </b>{" "}
                                  and <b>pdf</b> files. Maximum size allowed is{" "}
                                  <b>10MB.</b>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-4 col-md-3 col-lg-2">
                        <input
                          type="reset"
                          className="round-red-btn w-100 mt-3 clr"
                          value="RESET"
                          onClick={() => window.location.reload(false)}
                          disabled={disable}
                        />{" "}
                      </div>
                      <div className="col-sm-4 col-md-3 col-lg-2">
                        <input
                          type="submit"
                          className="round-red-btn w-100 mt-3"
                          value="SUBMIT"
                          disabled={disable}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <Footer />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
        {/* <SeeAttachment /> */}
      </div>
    </>
  );
}

export default FileClaims;
