import React, { useState } from "react";
import "./W9Download.css";
import Footer from "../Include/Footer";
import Header from "../Include/Header";
// import SeeAttachment from "../Include/SeeAttachment";
import { Helmet } from "react-helmet";
function W9Download() {
  const TITLE = "Ashley Sleep Elite | Form W-9";
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="user-panel">
        <section className="main-ban page-heading">
          <div className="container">
            <Header />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* <div className="traning-awards">
                  <h1 className="mban-head w-100">FORM W-9</h1>
                </div> */}
                <h2 className="gen-hed">
                  FORM <span className="slide-heading">W-9</span>
                </h2>
              </div>
            </div>
          </div>
        </section>

        <section className="claims-part">
          {/* <h2 className="gen-hed">
                  FORM <span>W-9</span>
                </h2> */}
          <div className="content-area">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="whitebg-box" id="W9box">
                    <div className="text-right">
                      <a
                        href="https://ae-api.elitestacks.com/public/docs/fw9.pdf"
                        download=""
                      >
                        <i className="fa fa-download"></i> &nbsp;Download
                      </a>
                    </div>
                    <embed
                      src="https://ae-api.elitestacks.com/public/docs/fw9.pdf"
                      height="1000"
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
        {/* <SeeAttachment /> */}
      </div>
    </>
  );
}

export default W9Download;
