import axios from "axios";

import secureLocalStorage from "react-secure-storage";
import authHeader from "./authHeader";

const API_URL = process.env.REACT_APP_API_Link;

const login = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/login",
    {
      username: values.name,
      password: values.password,
    },
    {
      headers: headers,
    }
  );
};

const forgotPass = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/forgot",
    {
      email: values.email,
    },
    {
      headers: headers,
    }
  );
};
const AdminforgotPass = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/forgot-admin",
    {
      email: values.email,
    },
    {
      headers: headers,
    }
  );
};

const changePass = (values, forgotToken) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "user/update-password/" + forgotToken,
    {
      password: values.password,
      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const checkToken = (token) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/check-forgot-token/" + token, {
    headers: headers,
  });
};

const validateProfileEmail = (values, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/check-profile-email-exist/" + id + "/" + values.email,
    {
      headers: headers,
    }
  );
};

const getProfile = (accessToken) => {
  return axios.get(API_URL + "user/profile", {
    headers: authHeader(accessToken),
  });
};

const UpdateStoreInfo = (values, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "user/update/" + id,
    {
      store_state_id: values.stateprov,
      store_city_id: values.city,
      store_id: values.store,
      updated_by: id,
      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const getUserState = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/state/list", {
    headers: headers,
  });
};

const getCityByStateId = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/city/get-by-state-id/" + id, {
    headers: headers,
  });
};
// const SubmitClaim = (values, id) => {
//   const headers = {
//     "Content-Type": "multipart/form-data",
//   };
//   return axios.post(
//     API_URL + "user/claim/submit-claim/" + id,
//     {
//       split_status: values.split1,
//       product: values.product,
//       size: values.size,
//       invoice: values.invoice,
//       invoice_date: values.invoice_date,
//       quantity: values.quantity,
//       image: values.file, //file type
//       ip: "192.168.10.1",
//     },
//     {
//       headers: headers,
//     }
//   );
// };

const SubmitClaim = (formData, id) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return axios.post(API_URL + "user/claim/submit-claim/" + id, formData, {
    headers: headers,
  });
};

const rejectClaimById = (formData, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(API_URL + "user/claim/admin/reject-claim/" + id, formData, {
    headers: headers,
  });
};
const acceptClaimById = (formData, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(API_URL + "user/claim/admin/accept-claim/" + id, formData, {
    headers: headers,
  });
};

const acceptSelectedClaims = (formData) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/claim/admin/accept-selected-claims",
    formData,
    {
      headers: headers,
    }
  );
};

const acceptAllClaims = (formData) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(API_URL + "user/claim/admin/accept-all-claims", formData, {
    headers: headers,
  });
};

const rejectSelectedClaims = (formData) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/claim/admin/reject-selected-claims",
    formData,
    {
      headers: headers,
    }
  );
};

const updateClaimPending = (formData, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(`${API_URL}user/claim/pending-claim/${id}`, formData, {
    headers: headers,
  });
};

const rejectAllClaims = (formData) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(API_URL + "user/claim/admin/reject-all-claims", formData, {
    headers: headers,
  });
};

const getProdPrice = (uId, pId, sId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/claim/get-product-price/" + uId + "/" + pId + "/" + sId,
    {
      headers: headers,
    }
  );
};

const contact = (values) => {
  console.log(JSON.stringify(values));
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/contact-submit",
    {
      subject: values.subject,
      name: values.name,
      phone: values.phone,
      email: values.email,
      contactback: values.contactback,
      question: values.question,
    },
    {
      headers: headers,
    }
  );
};

const UpdateUserInfo = (values, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "user/update/" + id,
    {
      first_name: values.fname,
      last_name: values.lname,
      email: values.email,
      zip: values.zipcode,
      address1: values.address1,
      address2: values.address2,
      fax: values.fax,
      phone: values.phone,

      state_id: values.stateprov2,
      city_id: values.city2,
      updated_by: id,

      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const UpdateUserInfoWithPass = (values, id, pass) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "user/update/" + id,
    {
      first_name: values.fname,
      last_name: values.lname,
      email: values.email,
      zip: values.zipcode,
      address1: values.address1,
      address2: values.address2,
      fax: values.fax,
      phone: values.phone,
      updated_by: id,
      password: pass,

      state_id: values.stateprov2,
      city_id: values.city2,
      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const UpdateAdminInfo = (values, id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "user/update/" + id,
    {
      first_name: values.fname,
      last_name: values.lname,
      email: values.email,
      state_id: values.state,
      city_id: values.city,
      zip: values.zipcode,
      address1: values.address1,
      phone: values.phone,
      updated_by: id,

      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const UpdateAdminInfoWithPass = (values, id, pass) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "user/update/" + id,
    {
      first_name: values.fname,
      last_name: values.lname,
      email: values.email,
      state_id: values.state,
      city_id: values.city,
      zip: values.zipcode,
      address1: values.address1,
      phone: values.phone,
      updated_by: id,
      password: pass,
      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

// recent claims welcome page
const recentClaim = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/claims/list-recent-claims/" + id, {
    headers: headers,
  });
};

const getLimitRecentClaim = (id, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/claims/list-recent-claims/" + id + "?limit=" + limit,
    {
      headers: headers,
    }
  );
};

const getClaimDetail = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/claim/get-claim-detail/" + id, {
    headers: headers,
  });
};

const checkClaimStatus = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}user/claim/get-claim-status/${id}`, {
    headers: headers,
  });
};

const getSearchRecentClaim = (id, col, val, limit) => {
  if (col === "1") {
    col = "invoice";
  } else if (col === "2") {
    col = "amount";
  } else if (col === "3") {
    col = "sale_status";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claims/list-recent-claims/" +
      id +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

// Previous claims page
const getPrevClaimstById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/claims/list-previous-claims/" + id, {
    headers: headers,
  });
};

const getLimitPrevClaimstById = (id, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claims/list-previous-claims/" +
      id +
      "?limit=" +
      limit +
      "&page=1",
    {
      headers: headers,
    }
  );
};

const getAllLatestClaims = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/dashboard/list-latest-claims" +
      "?limit=" +
      limit +
      "&page=1",
    {
      headers: headers,
    }
  );
};
const getAllLatestClaimsStats = (
  id,
  limit,
  pageNo,
  col,
  val,
  startDate,
  endDate,
  invoiceStart,
  invoiceEnd
) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "prodName";
  } else if (col === "13") {
    col = "prodCode";
  } else if (col === "14") {
    col = "splitStatus";
  } else if (col === "15") {
    col = "role";
  } else if (col === "16") {
    col = "quantity";
  } else if (col === "17") {
    col = "price";
  } else if (col === "18") {
    col = "totalPrice";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  let query = ``;
  if (startDate && endDate) {
    query = `${API_URL}user/stats/get-claim-detail/${id}?${col}=${val}&startDate=${startDate}&endDate=${endDate}&limit=${limit}&page${pageNo}`;
  } else if (invoiceStart && invoiceEnd) {
    query = `${API_URL}user/stats/get-claim-detail/${id}?${col}=${val}&invoiceStart=${invoiceStart}&invoiceEnd=${invoiceEnd}&page=${pageNo}&limit=${limit}`;
  } else if (limit && pageNo) {
    query = `${API_URL}user/stats/get-claim-detail/${id}&limit=${limit}&page${pageNo}`;
  } else if (limit) {
    query = `${API_URL}user/stats/get-claim-detail/${id}&limit=${limit}`;
  } else if (pageNo) {
    query = `${API_URL}user/stats/get-claim-detail/${id}&page=${pageNo}`;
  } else {
    query = `${API_URL}user/stats/get-claim-detail/${id}`;
  }

  return axios.get(query, {
    headers: headers,
  });
};
const getAllLatestDosClaims = (id, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/dashboard/list-dos-latest-claims/" +
      id +
      "?limit=" +
      limit +
      "&page=1",
    {
      headers: headers,
    }
  );
};

const getAllYears = () => {
  const headers = {
    "Content-Type": "application/json",
  };

  return axios.get(`${API_URL}user/claim/archive/list-years`, {
    headers: headers,
  });
};

const getAllArchivedClaims = (year, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/archive/list-year-claims/" +
      year +
      "?limit=" +
      limit +
      "&page=1",
    {
      headers: headers,
    }
  );
};
const getAllArchivedDosClaims = (id, year, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/archive/list-year-claims/" +
      id +
      "/" +
      year +
      "?limit=" +
      limit +
      "&page=1",
    {
      headers: headers,
    }
  );
};

const getAllAcceptedClaims = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/admin/list-accepted-claims" +
      "?limit=" +
      limit +
      "&page=1",
    {
      headers: headers,
    }
  );
};

const getAllRejectedClaims = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/admin/list-rejected-claims" +
      "?limit=" +
      limit +
      "&page=1",
    {
      headers: headers,
    }
  );
};
const getAllDosAcceptedClaims = (id, limit, pageNo) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}user/claim/admin/list-accepted-dos-claims/${id}?limit=${limit}&page=${pageNo}`,
    {
      headers: headers,
    }
  );
};

const getAllDosRejectedClaims = (id, limit, pageNo) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}user/claim/admin/list-rejected-dos-claims/${id}?limit=${limit}&page=${pageNo}`,
    {
      headers: headers,
    }
  );
};
const getPaginatedPrevClaimstById = (id, pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claims/list-previous-claims/" +
      id +
      "?page=" +
      pageNo +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

const getPaginatedLatestClaims = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/dashboard/list-latest-claims" +
      "?page=" +
      pageNo +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

const getPaginatedAcceptedClaims = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/admin/list-accepted-claims" +
      "?page=" +
      pageNo +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};
const getPaginatedDosAcceptedClaims = (id, pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/admin/list-accepted-dos-claims/" +
      id +
      "?page=" +
      pageNo +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

const getPaginatedDosRejectedClaims = (id, pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/admin/list-rejected-dos-claims/" +
      id +
      "?page=" +
      pageNo +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

const getPaginatedRejectedClaims = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/admin/list-rejected-claims" +
      "?page=" +
      pageNo +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};
const getSearchPrevClaimstById = (id, col, val, limit, pageNo) => {
  if (col === "1") {
    col = "invoice";
  } else if (col === "2") {
    col = "product";
  } else if (col === "3") {
    col = "size";
  } else if (col === "4") {
    col = "quantity";
  } else if (col === "5") {
    col = "amount";
  } else if (col === "6") {
    col = "store";
  } else if (col === "7") {
    col = "city";
  } else if (col === "8") {
    col = "state";
  } else if (col === "9") {
    col = "status";
  } else {
    col = "invoice_date";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claims/list-previous-claims/" +
      id +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};

const getSearchAllClaims = (col, val, limit, pageNo) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "prodName";
  } else if (col === "13") {
    col = "prodCode";
  } else if (col === "14") {
    col = "splitStatus";
  } else if (col === "15") {
    col = "role";
  } else if (col === "16") {
    col = "quantity";
  } else if (col === "17") {
    col = "price";
  } else if (col === "18") {
    col = "totalPrice";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/dashboard/list-latest-claims" +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};
const getSearchAllDosClaims = (id, col, val, limit, pageNo) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "prodName";
  } else if (col === "13") {
    col = "prodCode";
  } else if (col === "14") {
    col = "splitStatus";
  } else if (col === "15") {
    col = "role";
  } else if (col === "16") {
    col = "quantity";
  } else if (col === "17") {
    col = "price";
  } else if (col === "18") {
    col = "totalPrice";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/dashboard/list-dos-latest-claims/" +
      id +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};
const getSearchAllArchivedClaims = (year, col, val, limit, pageNo) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "product";
  } else if (col === "13") {
    col = "size";
  } else if (col === "14") {
    col = "status";
  } else if (col === "15") {
    col = "role";
  } else if (col === "16") {
    col = "quantity";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}user/claim/archive/list-year-claims/${year}?${col}=${val}&limit=${limit}&page=${pageNo}`,
    {
      headers: headers,
    }
  );
};
const getSearchAllArchivedDosClaims = (id, year, col, val, limit, pageNo) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "product";
  } else if (col === "13") {
    col = "status";
  } else if (col === "14") {
    col = "role";
  } else if (col === "15") {
    col = "quantity";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}user/claim/archive/list-year-claims/${id}/${year}?${col}=${val}&limit=${limit}&page=${pageNo}`,
    {
      headers: headers,
    }
  );
};
const getSearchAllAcceptedClaims = (col, val, limit, pageNo) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "product";
  } else if (col === "13") {
    col = "prodCode";
  } else if (col === "14") {
    col = "splitStatus";
  } else if (col === "15") {
    col = "role";
  } else if (col === "16") {
    col = "quantity";
  } else if (col === "17") {
    col = "price";
  } else if (col === "18") {
    col = "totalPrice";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/admin/list-accepted-claims" +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};

const getSearchAllRejectedClaims = (col, val, limit, pageNo) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "product";
  } else if (col === "13") {
    col = "prodCode";
  } else if (col === "14") {
    col = "splitStatus";
  } else if (col === "15") {
    col = "role";
  } else if (col === "16") {
    col = "quantity";
  } else if (col === "17") {
    col = "price";
  } else if (col === "18") {
    col = "totalPrice";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/admin/list-rejected-claims" +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};
const getSearchAllDosAcceptedClaims = (id, col, val, limit, pageNo) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "product";
  } else if (col === "13") {
    col = "splitStatus";
  } else if (col === "14") {
    col = "role";
  } else if (col === "15") {
    col = "quantity";
  } else if (col === "16") {
    col = "price";
  } else if (col === "17") {
    col = "totalPrice";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/admin/list-accepted-dos-claims/" +
      id +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};

const getSearchAllDosRejectedClaims = (id, col, val, limit, pageNo) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "product";
  } else if (col === "13") {
    col = "prodCode";
  } else if (col === "14") {
    col = "splitStatus";
  } else if (col === "15") {
    col = "role";
  } else if (col === "16") {
    col = "quantity";
  } else if (col === "17") {
    col = "price";
  } else if (col === "18") {
    col = "totalPrice";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/admin/list-rejected-dos-claims/" +
      id +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};
const getSearchWithDatePrevClaimstById = (id, col, val, invoiceDate, limit) => {
  if (col === "1") {
    col = "invoice";
  } else if (col === "2") {
    col = "product";
  } else if (col === "3") {
    col = "size";
  } else if (col === "4") {
    col = "quantity";
  } else if (col === "5") {
    col = "amount";
  } else if (col === "6") {
    col = "store";
  } else if (col === "7") {
    col = "city";
  } else if (col === "8") {
    col = "state";
  } else if (col === "9") {
    col = "sale_statu";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claims/list-previous-claims/" +
      id +
      "?" +
      col +
      "=" +
      val +
      "&invoice_date=" +
      invoiceDate +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

const getSearchWithoutDateAllClaims = (col, val, limit) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "prodName";
  } else if (col === "13") {
    col = "prodCode";
  } else if (col === "14") {
    col = "splitStatus";
  } else if (col === "15") {
    col = "role";
  } else if (col === "16") {
    col = "quantity";
  } else if (col === "17") {
    col = "price";
  } else {
    col = "totalPrice";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/dashboard/list-latest-claims" +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};
const getSearchWithoutDateAllArchivedClaims = (year, col, val, limit) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "invoice_date";
  } else if (col === "8") {
    col = "created_at";
  } else if (col === "9") {
    col = "account";
  } else if (col === "10") {
    col = "store";
  } else if (col === "11") {
    col = "state";
  } else if (col === "12") {
    col = "city";
  } else if (col === "13") {
    col = "zip";
  } else if (col === "14") {
    col = "product";
  } else if (col === "15") {
    col = "size";
  } else if (col === "16") {
    col = "status";
  } else if (col === "17") {
    col = "role";
  } else if (col === "18") {
    col = "quantity";
  } else if (col === "19") {
    col = "price";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/archive/list-year-claims/" +
      year +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};
const getSearchWithoutDateAllArchivedDosClaims = (
  id,
  year,
  col,
  val,
  limit
) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "invoice_date";
  } else if (col === "8") {
    col = "created_at";
  } else if (col === "9") {
    col = "account";
  } else if (col === "10") {
    col = "store";
  } else if (col === "11") {
    col = "state";
  } else if (col === "12") {
    col = "city";
  } else if (col === "13") {
    col = "zip";
  } else if (col === "14") {
    col = "product";
  } else if (col === "15") {
    col = "size";
  } else if (col === "16") {
    col = "status";
  } else if (col === "17") {
    col = "role";
  } else if (col === "18") {
    col = "quantity";
  } else if (col === "19") {
    col = "price";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/archive/list-year-claims/" +
      id +
      year +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};
const getSearchWithoutDateAcceptedClaims = (col, val, limit) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "product";
  } else if (col === "13") {
    col = "prodCode";
  } else if (col === "14") {
    col = "splitStatus";
  } else if (col === "15") {
    col = "role";
  } else if (col === "16") {
    col = "quantity";
  } else if (col === "17") {
    col = "price";
  } else {
    col = "totalPrice";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/admin/list-accepted-claims" +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};
const getSearchWithoutDateRejectedClaims = (col, val, limit) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "product";
  } else if (col === "13") {
    col = "prodCode";
  } else if (col === "14") {
    col = "splitStatus";
  } else if (col === "15") {
    col = "role";
  } else if (col === "16") {
    col = "quantity";
  } else if (col === "17") {
    col = "price";
  } else {
    col = "totalPrice";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/admin/list-rejected-claims" +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};
const getPaginatedAllClaims = (
  startDate,
  endDate,
  invoiceStart,
  invoiceEnd,
  pageNo,
  limit
) => {
  const headers = {
    "Content-Type": "application/json",
  };
  let query = ``;
  if (startDate && endDate) {
    query = `${API_URL}user/claim/dashboard/list-latest-claims?startDate=${startDate}&endDate=${endDate}&page=${pageNo}&limit=${limit}`;
  } else if (invoiceStart && invoiceEnd) {
    query = `${API_URL}user/claim/dashboard/list-latest-claims?invoiceStart=${invoiceStart}&invoiceEnd=${invoiceEnd}&page=${pageNo}&limit=${limit}`;
  }
  return axios.get(query, {
    headers: headers,
  });
};
const getPaginatedAllArchivedClaims = (
  year,
  startDate,
  endDate,
  startInvDate,
  endInvDate,
  pageNo,
  limit
) => {
  const headers = {
    "Content-Type": "application/json",
  };

  let query = ``;
  if (startDate && endDate) {
    query = `${API_URL}user/claim/archive/list-year-claims/${year}?startDate=${startDate}&endDate=${endDate}&page=${pageNo}&limit=${limit}`;
  } else if (startInvDate && endInvDate) {
    query = `${API_URL}user/claim/archive/list-year-claims/${year}?invoiceStart=${startInvDate}&invoiceEnd=${endInvDate}&page=${pageNo}&limit=${limit}`;
  } else {
    query = `${API_URL}user/claim/archive/list-year-claims/${year}?page=${pageNo}&limit=${limit}`;
  }

  return axios.get(query, {
    headers: headers,
  });
};
const getPaginatedAllArchivedDosClaims = (
  id,
  year,
  startDate,
  endDate,
  startInvDate,
  endInvDate,
  pageNo,
  limit
) => {
  const headers = {
    "Content-Type": "application/json",
  };

  let query = ``;
  if (startDate && endDate) {
    query = `${API_URL}user/claim/archive/list-year-claims/${id}/${year}?startDate=${startDate}&endDate=${endDate}&page=${pageNo}&limit=${limit}`;
  } else if (startInvDate && endInvDate) {
    query = `${API_URL}user/claim/archive/list-year-claims/${id}/${year}?invoiceStart=${startInvDate}&invoiceEnd=${endInvDate}&page=${pageNo}&limit=${limit}`;
  } else {
    query = `${API_URL}user/claim/archive/list-year-claims/${id}/${year}?page=${pageNo}&limit=${limit}`;
  }

  return axios.get(query, {
    headers: headers,
  });
};
const getPaginatedAcceptedDateClaims = (startDate, endDate, pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/admin/list-accepted-claims" +
      "?startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&page=" +
      pageNo +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

const getPaginatedInvAcceptedDateClaims = (
  startDate,
  endDate,
  pageNo,
  limit
) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/admin/list-accepted-claims" +
      "?invoiceStart=" +
      startDate +
      "&invoiceEnd=" +
      endDate +
      "&page=" +
      pageNo +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

const getPaginatedRejectedDateClaims = (startDate, endDate, pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/admin/list-rejected-claims" +
      "?startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&page=" +
      pageNo +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

const getPaginatedRejectedInvDateClaims = (
  startDate,
  endDate,
  pageNo,
  limit
) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/admin/list-rejected-claims" +
      "?invoiceStart=" +
      startDate +
      "&invoiceEnd=" +
      endDate +
      "&page=" +
      pageNo +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

// final function
const getSearchWithDateAllClaims = (
  col,
  val,
  startDate,
  endDate,
  invoiceStart,
  invoiceEnd,
  limit,
  pageNo
) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "prodName";
  } else if (col === "13") {
    col = "prodCode";
  } else if (col === "14") {
    col = "splitStatus";
  } else if (col === "15") {
    col = "role";
  } else if (col === "16") {
    col = "quantity";
  } else if (col === "17") {
    col = "price";
  } else if (col === "18") {
    col = "totalPrice";
  } else {
    col = "";
  }

  const headers = {
    "Content-Type": "application/json",
  };

  console.log(col);
  let query = ``;
  if (col && val && startDate && endDate) {
    query = `${API_URL}user/claim/dashboard/list-latest-claims?col=${col}&val=${val}&startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${pageNo}`;
  } else if (col && val && invoiceStart && invoiceEnd) {
    query = `${API_URL}user/claim/dashboard/list-latest-claims?col=${col}&val=${val}&invoiceStart=${invoiceStart}&invoiceEnd=${invoiceEnd}&limit=${limit}&page=${pageNo}`;
  } else if (startDate && endDate) {
    query = `${API_URL}user/claim/dashboard/list-latest-claims?startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${pageNo}`;
  } else if (invoiceStart && invoiceEnd) {
    query = `${API_URL}user/claim/dashboard/list-latest-claims?invoiceStart=${invoiceStart}&invoiceEnd=${invoiceEnd}&limit=${limit}&page=${pageNo}`;
  }

  return axios.get(query, {
    headers: headers,
  });
};
const getSearchWithDateAllClaimsDos = (
  id,
  col,
  val,
  startDate,
  endDate,
  invoiceStart,
  invoiceEnd,
  limit,
  pageNo
) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "prodName";
  } else if (col === "13") {
    col = "prodCode";
  } else if (col === "14") {
    col = "splitStatus";
  } else if (col === "15") {
    col = "role";
  } else if (col === "16") {
    col = "quantity";
  } else if (col === "17") {
    col = "price";
  } else if (col === "18") {
    col = "totalPrice";
  } else {
    col = "";
  }

  const headers = {
    "Content-Type": "application/json",
  };

  console.log(col);
  let query = ``;
  if (col && val && startDate && endDate) {
    query = `${API_URL}user/claim/dashboard/list-dos-latest-claims/${id}?col=${col}&val=${val}&startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${pageNo}`;
  } else if (col && val && invoiceStart && invoiceEnd) {
    query = `${API_URL}user/claim/dashboard/list-dos-latest-claims/${id}?col=${col}&val=${val}&invoiceStart=${invoiceStart}&invoiceEnd=${invoiceEnd}&limit=${limit}&page=${pageNo}`;
  } else if (startDate && endDate) {
    query = `${API_URL}user/claim/dashboard/list-dos-latest-claims/${id}?startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${pageNo}`;
  } else if (invoiceStart && invoiceEnd) {
    query = `${API_URL}user/claim/dashboard/list-dos-latest-claims/${id}?invoiceStart=${invoiceStart}&invoiceEnd=${invoiceEnd}&limit=${limit}&page=${pageNo}`;
  }

  return axios.get(query, {
    headers: headers,
  });
};

const searchInvoiceDate = (startDate, endDate, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}user/claim/dashboard/list-rejected-dos-claims?invoiceStart=${startDate}&invoiceEnd=${endDate}&limit=${limit}`,
    {
      headers: headers,
    }
  );
};

const searchRejectedInvoiceDate = (startDate, endDate, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/admin/list-rejected-claims" +
      "?invoiceStart=" +
      startDate +
      "&invoiceEnd=" +
      endDate +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

const searchAcceptedInvoiceDate = (startDate, endDate, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/claim/admin/list-accepted-claims" +
      "?invoiceStart=" +
      startDate +
      "&invoiceEnd=" +
      endDate +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

// FIXME name colliding
const getSearchWithDateAllArchivedClaims = (
  year,
  startDate,
  endDate,
  invoiceStart,
  invoiceEnd,
  limit,
  pageNo
) => {
  const headers = {
    "Content-Type": "application/json",
  };
  console.log("endpoint called");
  let query = ``;
  if (startDate && endDate) {
    query = `${API_URL}user/claim/archive/list-year-claims/${year}?startDate=${startDate}&endDate=${endDate}&page=${pageNo}&limit=${limit}`;
  } else if (invoiceStart && invoiceEnd) {
    query = `${API_URL}user/claim/archive/list-year-claims/${year}?invoiceStart=${invoiceStart}&invoiceEnd=${invoiceEnd}&page=${pageNo}&limit=${limit}`;
  }
  return axios.get(query, {
    headers: headers,
  });
};
const getSearchWithDateAllArchivedDosClaims = (
  id,
  year,
  startDate,
  endDate,
  invoiceStart,
  invoiceEnd,
  limit,
  pageNo
) => {
  const headers = {
    "Content-Type": "application/json",
  };
  console.log(
    `id=${id}, year=${year}, startDate=${startDate}, endDate=${endDate}, invoiceStart=${invoiceStart}, invoiceEnd=${invoiceEnd}, limit=${limit}, pageNo=${pageNo}`
  );
  let query = ``;
  if (startDate && endDate) {
    query = `${API_URL}user/claim/archive/list-year-claims/${id}/${year}?startDate=${startDate}&endDate=${endDate}&page=${pageNo}&limit=${limit}`;
  } else if (invoiceStart && invoiceEnd) {
    query = `${API_URL}user/claim/archive/list-year-claims/${id}/${year}?invoiceStart=${invoiceStart}&invoiceEnd=${invoiceEnd}&page=${pageNo}&limit=${limit}`;
  }
  return axios.get(query, {
    headers: headers,
  });
};
const getSearchWithDatesAllArchivedClaims = (
  year,
  col,
  val,
  startDate,
  endDate,
  limit,
  pageNo
) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "invoice_date";
  } else if (col === "8") {
    col = "created_at";
  } else if (col === "9") {
    col = "account";
  } else if (col === "10") {
    col = "store";
  } else if (col === "11") {
    col = "state";
  } else if (col === "12") {
    col = "city";
  } else if (col === "13") {
    col = "zip";
  } else if (col === "14") {
    col = "product";
  } else if (col === "15") {
    col = "size";
  } else if (col === "16") {
    col = "status";
  } else if (col === "17") {
    col = "role";
  } else if (col === "18") {
    col = "quantity";
  } else if (col === "19") {
    col = "price";
  }
  const headers = {
    "Content-Type": "application/json",
  };

  let query = `${API_URL}user/claim/archive/list-year-claims/${year}?${col}=${val}&startDate=${startDate}&endDate=${endDate}&page=${pageNo}&limit=${limit}`;
  return axios.get(query, {
    headers: headers,
  });
};
const getSearchWithDatesAllArchivedDosClaims = (
  id,
  year,
  col,
  val,
  startDate,
  endDate,
  limit,
  pageNo
) => {
  console.log(
    `id=${id}, year=${year}, col=${col}, val=${val}, startDate=${startDate}, endDate=${endDate}, limit=${limit}, pageNo=${pageNo}`
  );
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "invoice_date";
  } else if (col === "8") {
    col = "created_at";
  } else if (col === "9") {
    col = "account";
  } else if (col === "10") {
    col = "store";
  } else if (col === "11") {
    col = "state";
  } else if (col === "12") {
    col = "city";
  } else if (col === "13") {
    col = "zip";
  } else if (col === "14") {
    col = "product";
  } else if (col === "15") {
    col = "size";
  } else if (col === "16") {
    col = "status";
  } else if (col === "17") {
    col = "role";
  } else if (col === "18") {
    col = "quantity";
  } else if (col === "19") {
    col = "price";
  }
  const headers = {
    "Content-Type": "application/json",
  };

  let query = `${API_URL}user/claim/archive/list-year-claims/${id}/${year}?${col}=${val}&startDate=${startDate}&endDate=${endDate}&page=${pageNo}&limit=${limit}`;
  return axios.get(query, {
    headers: headers,
  });
};
// TODO date merge with search filter if it needs to be
const getSearchWithDateAccptedClaims = (
  col,
  val,
  startDate,
  endDate,
  limit,
  pageNo
) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "product";
  } else if (col === "13") {
    col = "prodCode";
  } else if (col === "14") {
    col = "splitStatus";
  } else if (col === "15") {
    col = "role";
  } else if (col === "16") {
    col = "quantity";
  } else if (col === "17") {
    col = "price";
  } else if (col === "18") {
    col = "totalPrice";
  } else {
    col = "";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  let query = `${API_URL}user/claim/admin/list-accepted-claims?startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${pageNo}`;
  if (col && val) {
    query = `${API_URL}user/claim/dashboard/list-accepted-claims?col=${col}&val=${val}&startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${pageNo}`;
  }
  return axios.get(query, {
    headers: headers,
  });
};

const getSearchWithInvDateAccptedClaims = (
  col,
  val,
  startDate,
  endDate,
  limit,
  pageNo
) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "product";
  } else if (col === "13") {
    col = "prodCode";
  } else if (col === "14") {
    col = "splitStatus";
  } else if (col === "15") {
    col = "role";
  } else if (col === "16") {
    col = "quantity";
  } else if (col === "17") {
    col = "price";
  } else if (col === "18") {
    col = "totalPrice";
  } else {
    col = "";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  let query = `${API_URL}user/claim/admin/list-accepted-claims?invoiceStart=${startDate}&invoiceEnd=${endDate}&limit=${limit}&page=${pageNo}`;
  if (col && val) {
    query = `${API_URL}user/claim/dashboard/list-accepted-claims?col=${col}&val=${val}&invoiceStart=${startDate}&invoiceEnd=${endDate}&limit=${limit}&page=${pageNo}`;
  }
  return axios.get(query, {
    headers: headers,
  });
};

const getSearchWithInvDateRejectedClaims = (
  col,
  val,
  startDate,
  endDate,
  limit,
  pageNo
) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "product";
  } else if (col === "13") {
    col = "prodCode";
  } else if (col === "14") {
    col = "splitStatus";
  } else if (col === "15") {
    col = "role";
  } else if (col === "16") {
    col = "quantity";
  } else if (col === "17") {
    col = "price";
  } else if (col === "18") {
    col = "totalPrice";
  } else {
    col = "";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  let query = `${API_URL}user/claim/admin/list-rejected-claims?invoiceStart=${startDate}&invoiceEnd=${endDate}&limit=${limit}&page=${pageNo}`;
  if (col && val) {
    query = `${API_URL}user/claim/dashboard/list-rejected-claims?col=${col}&val=${val}&invoiceStart=${startDate}&invoiceEnd=${endDate}&limit=${limit}&page=${pageNo}`;
  }
  return axios.get(query, {
    headers: headers,
  });
};

const getSearchWithDateRejectedClaims = (
  col,
  val,
  startDate,
  endDate,
  limit,
  pageNo
) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "product";
  } else if (col === "13") {
    col = "prodCode";
  } else if (col === "14") {
    col = "splitStatus";
  } else if (col === "15") {
    col = "role";
  } else if (col === "16") {
    col = "quantity";
  } else if (col === "17") {
    col = "price";
  } else if (col === "18") {
    col = "totalPrice";
  } else {
    col = "";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  let query = `${API_URL}user/claim/admin/list-rejected-claims?startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${pageNo}`;
  if (col && val) {
    query = `${API_URL}user/claim/dashboard/list-rejected-claims?col=${col}&val=${val}&startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${pageNo}`;
  }
  return axios.get(query, {
    headers: headers,
  });
};

const getSearchWithDateDosAccptedClaims = (
  id,
  col,
  val,
  startDate,
  endDate,
  limit,
  pageNo
) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "prodName";
  } else if (col === "13") {
    col = "prodCode";
  } else if (col === "14") {
    col = "splitStatus";
  } else if (col === "15") {
    col = "role";
  } else if (col === "16") {
    col = "quantity";
  } else if (col === "17") {
    col = "price";
  } else if (col === "18") {
    col = "totalPrice";
  } else {
    col = "";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  let query = `${API_URL}user/claim/admin/list-accepted-dos-claims/${id}?startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${pageNo}`;
  if (col && val) {
    query = `${API_URL}user/claim/dashboard/list-accepted-dos-claims/${id}?col=${col}&val=${val}&startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${pageNo}`;
  }
  return axios.get(query, {
    headers: headers,
  });
};

const getSearchWithInvDateDosAccptedClaims = (
  id,
  col,
  val,
  startDate,
  endDate,
  limit,
  pageNo
) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "prodName";
  } else if (col === "13") {
    col = "prodCode";
  } else if (col === "14") {
    col = "splitStatus";
  } else if (col === "15") {
    col = "role";
  } else if (col === "16") {
    col = "quantity";
  } else if (col === "17") {
    col = "price";
  } else if (col === "18") {
    col = "totalPrice";
  } else {
    col = "";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  let query = `${API_URL}user/claim/admin/list-accepted-dos-claims/${id}?invoiceStart=${startDate}&invoiceEnd=${endDate}&limit=${limit}&page=${pageNo}`;
  if (col && val) {
    query = `${API_URL}user/claim/dashboard/list-accepted-dos-claims/${id}?col=${col}&val=${val}&invoiceStart=${startDate}&invoiceEnd=${endDate}&limit=${limit}&page=${pageNo}`;
  }
  return axios.get(query, {
    headers: headers,
  });
};

const getSearchWithInvDateDosRejectedClaims = (
  id,
  col,
  val,
  invoiceStart,
  invoiceEnd,
  limit,
  pageNo
) => {
  console.log(
    `id=${id}, col=${col}, val=${val}, invoiceStart=${invoiceStart}, invoiceEnd=${invoiceEnd}, limit=${limit}, pageNo=${pageNo}`
  );
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "prodName";
  } else if (col === "13") {
    col = "prodCode";
  } else if (col === "14") {
    col = "splitStatus";
  } else if (col === "15") {
    col = "role";
  } else if (col === "16") {
    col = "quantity";
  } else if (col === "17") {
    col = "price";
  } else if (col === "18") {
    col = "totalPrice";
  } else {
    col = "";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  let query = `${API_URL}user/claim/admin/list-rejected-dos-claims/${id}?invoiceStart=${invoiceStart}&invoiceEnd=${invoiceEnd}&limit=${limit}&page=${pageNo}`;
  if (col && val) {
    query = `${API_URL}user/claim/dashboard/list-rejected-dos-claims/${id}?col=${col}&val=${val}&invoiceStart=${invoiceStart}&invoiceEnd=${invoiceEnd}&limit=${limit}&page=${pageNo}`;
  }
  return axios.get(query, {
    headers: headers,
  });
};

const getSearchWithInvDateDosAcceptedClaims = (
  id,
  col,
  val,
  invoiceStart,
  invoiceEnd,
  limit,
  pageNo
) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "prodName";
  } else if (col === "13") {
    col = "prodCode";
  } else if (col === "14") {
    col = "splitStatus";
  } else if (col === "15") {
    col = "role";
  } else if (col === "16") {
    col = "quantity";
  } else if (col === "17") {
    col = "price";
  } else if (col === "18") {
    col = "totalPrice";
  } else {
    col = "";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  let query = `${API_URL}user/claim/admin/list-accepted-dos-claims/${id}?invoiceStart=${invoiceStart}&invoiceEnd=${invoiceEnd}&limit=${limit}&page=${pageNo}`;
  if (col && val) {
    query = `${API_URL}user/claim/dashboard/list-accepted-dos-claims/${id}?col=${col}&val=${val}&invoiceStart=${invoiceStart}&invoiceEnd=${invoiceEnd}&limit=${limit}&page=${pageNo}`;
  }
  return axios.get(query, {
    headers: headers,
  });
};

const getSearchWithDateDosRejectedClaims = (
  id,
  col,
  val,
  startDate,
  endDate,
  limit,
  pageNo
) => {
  if (col === "1") {
    col = "username";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "employee";
  } else if (col === "6") {
    col = "invoice";
  } else if (col === "7") {
    col = "account";
  } else if (col === "8") {
    col = "store";
  } else if (col === "9") {
    col = "state";
  } else if (col === "10") {
    col = "city";
  } else if (col === "11") {
    col = "zip";
  } else if (col === "12") {
    col = "prodName";
  } else if (col === "13") {
    col = "prodCode";
  } else if (col === "14") {
    col = "splitStatus";
  } else if (col === "15") {
    col = "role";
  } else if (col === "16") {
    col = "quantity";
  } else if (col === "17") {
    col = "price";
  } else if (col === "18") {
    col = "totalPrice";
  } else {
    col = "";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  let query = `${API_URL}user/claim/admin/list-rejected-dos-claims/${id}?startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${pageNo}`;
  if (col && val) {
    query = `${API_URL}user/claim/dashboard/list-rejected-dos-claims/${id}?col=${col}&val=${val}&startDate=${startDate}&endDate=${endDate}&limit=${limit}&page=${pageNo}`;
  }
  return axios.get(query, {
    headers: headers,
  });
};
const validateRegister = (values) => {
  console.log(values);
  let userType = "";
  if (values.role === "0") {
    userType = "rsa";
  } else if (values.role === "1") {
    userType = "manager";
  }

  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/add",
    {
      username: values.username,
      password: values.password,
      first_name: values.fname,
      last_name: values.lname,
      address1: values.address1,
      address2: values.address2,
      email: values.email,
      state_id: values.stateprov2,
      city_id: values.city2,
      zip: values.zipcode,
      signature: values.signature,
      manager_signature: values.manager_signature,
      store_id: values.store,
      store_city_id: values.city,
      store_state_id: values.stateprov,
      ssn: values.ssn,
      phone: values.phone,
      fax: values.fax,
      user_type: userType,
      register_from: "web",
      // created_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const validateUser = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/check-username-exist/" + values.username, {
    headers: headers,
  });
};
const validateEmail = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/check-email-exist/" + values.email, {
    headers: headers,
  });
};

const validateSSN = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/check-ssn-exist/" + values.ssn, {
    headers: headers,
  });
};
const validateSSNAdmin = (values) => {
  console.log("values=" + JSON.stringify(values));
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}user/check-admin-ssn-exist/${values.id}/${values.ssn_number}`,
    {
      headers: headers,
    }
  );
};
const getNameState = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/state/get-name-by-id/" + id, {
    headers: headers,
  });
};
const getNameCity = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/city/get-info-by-id/" + id, {
    headers: headers,
  });
};

const getNameStore = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/get-name-by-id/" + id, {
    headers: headers,
  });
};

const pendingClaims = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/claim/dashboard/count-pending-claims", {
    headers: headers,
  });
};
const pendingClaimsForDos = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/claim/dashboard/count-dos-pending-claims/" + id,
    {
      headers: headers,
    }
  );
};

const pendingClaimsList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/claim/dashboard/list-limited-latest-claims",
    {
      headers: headers,
    }
  );
};
const pendingClaimsForDosList = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/claim/dashboard/list-limited-dos-latest-claims/" + id,
    {
      headers: headers,
    }
  );
};

const latestClaimsList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/claim/dashboard/list-latest-claims", {
    headers: headers,
  });
};
const doslatestClaimsList = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}user/claim/dashboard/list-dos-latest-claims/${id}`,
    {
      headers: headers,
    }
  );
};
const acceptedClaimsList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/claim/admin/list-accepted-claims", {
    headers: headers,
  });
};
const rejectedClaimsList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/claim/admin/list-rejected-claims", {
    headers: headers,
  });
};
const acceptedClaimsDosList = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/claim/admin/list-accepted-dos-claims/" + id,
    {
      headers: headers,
    }
  );
};
const rejectedClaimsDosList = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/claim/admin/list-rejected-dos-claims/" + id,
    {
      headers: headers,
    }
  );
};
const latestClaimsForDosList = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/claim/dashboard/list-dos-latest-claims/" + id,
    {
      headers: headers,
    }
  );
};

const totalClaims = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/claim/dashboard/count-claims", {
    headers: headers,
  });
};
const totalClaimsForDos = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/claim/dashboard/count-dos-claims/" + id, {
    headers: headers,
  });
};

const totalRsa = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/count-rsa", {
    headers: headers,
  });
};
const totalRsaForDos = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/count-dos-rsa/" + id, {
    headers: headers,
  });
};

const totalManager = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/count-manager", {
    headers: headers,
  });
};

const totalManagerForDos = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/count-dos-manager/" + id, {
    headers: headers,
  });
};

const getPercentage = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}user/claim/dashboard/get-percentage`, {
    headers: headers,
  });
};
const getPercentageDos = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}user/claim/dashboard/get-percentage/${id}`, {
    headers: headers,
  });
};

const getSearchWithDateAllBalance = (type, sales, tina, startDate, endDate) => {
  const headers = {
    "Content-Type": "application/json",
  };
  let query = `${API_URL}user/1099/list-${type}-balance/${sales}/${tina}/${startDate}/${endDate}`;
  return axios.get(query, {
    headers: headers,
  });
};
const getSearchWithDateAllComparison = (sales, tina, startDate, endDate) => {
  const headers = {
    "Content-Type": "application/json",
  };
  let query = `${API_URL}user/1099/list-sale-comparison/${sales}/${tina}/${startDate}/${endDate}`;
  return axios.get(query, {
    headers: headers,
  });
};

const getOutstandingById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/outstandings/" + id, {
    headers: headers,
  });
};

const updateClaim = (product, size, id, claimId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "user/claim/update-claim/" + claimId,
    {
      product_id: product,
      size_id: size,
      updated_by: id,
    },
    {
      headers: headers,
    }
  );
};

const UserService = {
  login,
  getProfile,
  UpdateStoreInfo,
  getUserState,
  getCityByStateId,
  forgotPass,
  AdminforgotPass,
  changePass,
  checkToken,
  validateProfileEmail,
  contact,
  UpdateUserInfo,
  UpdateUserInfoWithPass,
  UpdateAdminInfo,
  UpdateAdminInfoWithPass,
  recentClaim,
  getLimitRecentClaim,
  getSearchRecentClaim,
  getPrevClaimstById,
  getLimitPrevClaimstById,
  getSearchPrevClaimstById,
  getPaginatedPrevClaimstById,
  getSearchWithDatePrevClaimstById,
  validateUser,
  validateRegister,
  validateEmail,
  validateSSN,
  validateSSNAdmin,
  getNameState,
  getNameCity,
  getNameStore,
  getClaimDetail,
  checkClaimStatus,
  SubmitClaim,
  rejectClaimById,
  acceptClaimById,
  getProdPrice,
  pendingClaims,
  pendingClaimsForDos,
  totalClaims,
  totalClaimsForDos,
  totalRsa,
  totalRsaForDos,
  totalManager,
  totalManagerForDos,
  pendingClaimsList,
  pendingClaimsForDosList,
  latestClaimsList,
  doslatestClaimsList,
  acceptedClaimsList,
  rejectedClaimsList,
  acceptedClaimsDosList,
  rejectedClaimsDosList,
  latestClaimsForDosList,
  getAllLatestClaims,
  getAllLatestClaimsStats,
  getAllLatestDosClaims,
  getAllArchivedClaims,
  getAllArchivedDosClaims,
  getAllAcceptedClaims,
  getAllRejectedClaims,
  getAllDosAcceptedClaims,
  getAllDosRejectedClaims,
  getPaginatedLatestClaims,
  getPaginatedAcceptedClaims,
  getPaginatedDosAcceptedClaims,
  getPaginatedDosRejectedClaims,
  getPaginatedRejectedClaims,
  getSearchAllClaims,
  getSearchAllDosClaims,
  getSearchAllArchivedClaims,
  getSearchAllArchivedDosClaims,
  getSearchAllAcceptedClaims,
  getSearchAllRejectedClaims,
  getSearchAllDosAcceptedClaims,
  getSearchAllDosRejectedClaims,
  getSearchWithDateAllClaims,
  getSearchWithDateAllClaimsDos,
  getSearchWithDateAllBalance,
  getSearchWithDateAllComparison,
  getSearchWithDateAllArchivedClaims,
  getSearchWithDateAllArchivedDosClaims,
  getSearchWithDateAccptedClaims,
  getSearchWithInvDateAccptedClaims,
  getSearchWithDateRejectedClaims,
  getSearchWithInvDateRejectedClaims,
  getSearchWithDateDosAccptedClaims,
  getSearchWithInvDateDosAccptedClaims,
  getSearchWithDateDosRejectedClaims,
  getSearchWithInvDateDosRejectedClaims,
  getSearchWithInvDateDosAcceptedClaims,
  rejectSelectedClaims,
  updateClaimPending,
  acceptSelectedClaims,
  getSearchWithoutDateAllClaims,
  getSearchWithoutDateAllArchivedClaims,
  getSearchWithoutDateAllArchivedDosClaims,
  getSearchWithoutDateAcceptedClaims,
  getSearchWithoutDateRejectedClaims,
  getPaginatedAllClaims,
  getPaginatedAllArchivedClaims,
  getPaginatedAllArchivedDosClaims,
  getPaginatedAcceptedDateClaims,
  getPaginatedInvAcceptedDateClaims,
  getPaginatedRejectedDateClaims,
  getPaginatedRejectedInvDateClaims,
  getAllYears,
  rejectAllClaims,
  acceptAllClaims,
  getPercentage,
  getPercentageDos,
  searchInvoiceDate,
  getSearchWithDatesAllArchivedClaims,
  getSearchWithDatesAllArchivedDosClaims,
  searchRejectedInvoiceDate,
  searchAcceptedInvoiceDate,
  getOutstandingById,
  updateClaim,
};

export default UserService;
