import React, { useState } from "react";
import "./FAQ.css";
import Footer from "../User/Include/Footer";
import Header from "../User/Include/Header";
// import SeeAttachment from "../User/Include/SeeAttachment";
import { Helmet } from "react-helmet";
function FAQ() {
  const TITLE = "Ashley Sleep Elite | FAQ";
  const [loading, setLoading] = useState(false);

  return (
    <>
       <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="user-panel">
        <section className="main-ban page-heading">
          <div className="container">
            <Header />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="traning-awards">
                  {/* <h1 className="mban-head w-100">
                    FREQUENTLY ASKED QUESTIONS
                  </h1> */}
                  <h2 className="gen-hed">
                  FREQUENTLY ASKED <span className="slide-heading">QUESTIONS</span>
                </h2>
                </div>
              </div>
            </div>
          </div>
        </section>

      <section className="slide-up" id="faqtest">
        <div className="container">
        <div className="whitebg-box" id="faqbox">
          <div className="slides-here">
           <br/><br/>
            <video
              width="100%"
              className="faq-vid"
              poster="https://res.cloudinary.com/sparkcloudsforewards/image/upload/v1689662924/app-thumbnail_ryi5op.jpg"
              controls
            >
              <source
                src="https://res.cloudinary.com/sparkcloudsforewards/video/upload/v1689663195/ashley-app_krrse6.mp4"
                type="video/mp4"
              />
            
              Your browser does not support the video tag.
            </video>
          </div>
          </div>
        </div>
      </section>


        <Footer />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
        {/* <SeeAttachment /> */}
      </div>
    </>
  );
}

export default FAQ;
