import axios from "axios";
import React, { useEffect, useLayoutEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { toast, ToastContainer } from "react-toastify";
import AdminListService from "../../../src/services/admin-list.service";
import AdminFooter from "../../Components/Admin/includes/AdminFooter";
import CheckUtype from "../../Components/Admin/includes/CheckUtype";
import ToTop from "../../Components/Admin/includes/ToTop";
import HeaderSidebar1099 from "../1099Panel/includes/HeaderSidebar1099";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function MissingSales() {
  const TITLE = "Ashley Sleep Elite | Missing Sales Import";
  const [loading, setLoading] = useState(true);
  const [fieldClass, setFieldClass] = useState("form-input blink-soft");
  const [file, setFile] = useState("");
  const [message, setMessage] = useState("");
  const [fileError, setFileError] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [date, setDate] = useState("");
  const [isFile, setIsFile] = useState(false);
  const [isBtn, setIsBtn] = useState(false);

  const sysDate = new Date();
  var time = sysDate.getTime();

  useEffect(() => {
    setTimeout(() => {
      console.log("loader.....");
      setLoading(false);
    }, 500);
  }, []);

  const changeDate = (e) => {
    if (e != "") {
      setFieldClass("form-date");
      setDate(e);
    } else {
      setFieldClass("form-date blink-soft");
      setDate("");
    }
    console.log("start date");
    // setIsRecords(true);
    // setIsExport(true);
    // endDateRef.current.value = "";

    setIsFile(true);
    setIsBtn(true);
  };

  const handleUpload = async (event) => {
    setFile(event.target.files[0]);
  };

  const putStore = async () => {
    if (file === "") {
      setFileError(true);
    } else {
      setFileError(false);

      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);
      formData.append("created_by", "123");
      formData.append("created_ip", secureLocalStorage.getItem("ip"));
      setLoading(true);

      try {
        setLoading(true);

        const response = await AdminListService.importMissingSales(
          formData,
          date
        );

        setMessage(response.data.message);
        // if (response.status === 200) {
        setLoading(false);
        toast.success(response.data.response, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setErrorData([]);
        // }
      } catch (err) {
        if (err.response.status === 422) {
          if (err.response.data.data.failure !== "") {
            const errors = [];

            err.response.data.data.failure.map((value) => {
              errors.push({
                row: value.row,
                errors: value.errors.toString().replaceAll("_", " "),
              });
            });

            setErrorData([...errors]);
            toast.error(err.response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            setErrorData([]);

            toast.error(err.response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }

          setLoading(false);
        } else {
          toast.error("Some thing went wrong!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }
      }
    }
  };

  useLayoutEffect(() => {
    const getIp = async () => {
      const res = await axios.get("https://geolocation-db.com/json/");
      const weIp = res.data.IPv4;

      secureLocalStorage.setItem("ip", weIp);
    };
    getIp();
  }, []);
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="semi-dark">
        <div className="wrapper">
          <CheckUtype />

          <HeaderSidebar1099 />
          <ToastContainer />

          <main className="page-content">
            <div className="row">
              <div className="col">
                <div className="manage-heading-2">
                  <h2>Missing Sales Import</h2>
                </div>

                <div className="slides-here">
                  <div className="alert alert-info">
                    <b>Information!</b> You May Upload .xls, .xlsx files.{" "}
                  </div>
                  <div className="main-content-box">
                    {errorData.length ? (
                      <div className="error-msg">
                        {errorData.map((res, index) => {
                          return (
                            <div className="alert alert-danger " key={index}>
                              <b>Note!</b> Problem in row number {res.row} ,
                              <b> Error! </b>
                              {res.errors}
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                    <div className="row">
                      <div className="col-lg-3">
                        <label>Select Date</label>
                        <div className="reacter-datepicker">
                          <input
                            type="date"
                            name="date"
                            // ref={dateRef}
                            className={`form-control ${fieldClass}`}
                            onChange={(e) => {
                              changeDate(e.target.value);
                            }}
                            placeholder="mm-dd-yyyy"
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-lg-6">
                        <div
                          className={`control-fileupload ${
                            fileError ? "is-danger" : ""
                          }`}
                        >
                          <input
                            type="file"
                            className={`w-100 cursor-pointer ${
                              fileError ? "is-danger" : ""
                            }`}
                            id="file"
                            accept=".xlsx,.xls"
                            onClick={(e) => {
                              e.target.value = null;
                              setFile("");
                            }}
                            onChange={handleUpload}
                            disabled={!isFile}
                          />
                        </div>
                        {fileError ? (
                          <p className="help is-danger">Please Select File</p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-lg-2">
                        <button
                          type="submit"
                          className="btn btn-primary d-block    width-100 back-blue"
                          onClick={putStore}
                          disabled={!isBtn}
                        >
                          Upload Sheet
                        </button>
                      </div>
                      <div className="col-lg-4">
                        <Link
                          className="btn-success btn btn-success-control width-100 back-blue"
                          to={`http://ae-api.elitestacks.com/resources/sheets/Missing%20Claims.xlsx?time=${time}`}
                          download=""
                        >
                          <span>
                            {" "}
                            <i className="fadeIn animated bx bx-file"></i>{" "}
                          </span>
                          Download Store Sample Excel Sheet Here
                        </Link>
                      </div>
                    </div>
                  </div>

                  {/*FIXME */}
                  {/* {message.map && (
                  <div className="alert alert-primary">{message}</div>
                )} */}

                  {/* {message.length
                  ? message.map((res) => {
                      return <div className="alert alert-primary">{res}</div>;
                    })
                  : ""} */}
                </div>
              </div>
            </div>
          </main>
          <ToTop />
          <div className={`loader ${loading ? "in" : ""}`}>
            <div className="spinner-border main-spin"></div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
}
