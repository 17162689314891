import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { bindActionCreators } from "redux";
import { actionCreaters } from "../../../Redux/index";
import { useSelector } from "react-redux";
import AdminListService from "../../../services/admin-list.service";

export default function HeaderSidebar1099() {
  const [loading, setLoading] = useState(false);
  const [allYears, setAllYears] = useState([]);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);

  const state = useSelector((state) => state.stateVals);
  const { name, uType } = state;

  var welcomeFname;
  if (name) {
    welcomeFname = name.split(" ");
  } else {
    welcomeFname = "Unknown";
  }

  const logOut = async (e) => {
    e.preventDefault();
    setLoading(true);
    userActions.logOut(null);
    setLoading(false);
    navigate("/1099");
  };

  const getAllYears = async () => {
    try {
      const response = await AdminListService.getAllYears();
      console.log(response.data.response);
      setAllYears(response.data.response);
    } catch (err) {
      setAllYears([]);
    }
  };

  useLayoutEffect(() => {
    getAllYears();
  }, []);

  return (
    <>
      <header className="top-header">
        <nav className="navbar navbar-expand gap-3">
          <div className="mobile-toggle-icon fs-3">
            <i className="bi bi-list"></i>
          </div>
          <div className="top-navbar-right ms-auto">
            <ul className="navbar-nav align-items-center">
              <li className="nav-item">
                <Link className="nav-link" to="/1099/dashboard">
                  Home
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="/admin/profile-admin">
                  Profile
                </Link>
              </li> */}
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/1099/"
                  onClick={(e) => {
                    logOut(e);
                  }}
                >
                  Logout
                </Link>
              </li>
              <li className="nav-item">
                <div className="red-welcome">
                  <div className="trianles">
                    {" "}
                    <img
                      src="/admin_assets/images/lef-red.png"
                      className="lef-red"
                      alt=""
                    />{" "}
                    <img
                      src="/admin_assets/images/rig-red.png"
                      className="rig-red"
                      alt=""
                    />{" "}
                    <div className="welcome-txt">
                      Welcome - {welcomeFname[0] ? welcomeFname[0] : "Unknown"}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      <aside className="sidebar-wrapper" data-simplebar="true">
        <div className="sidebar-header">
          <Link to="/1099/dashboard">
            <img
              src="/admin_assets/images/logo-icon.png"
              className="logo-icon"
              alt="logo icon"
            />
          </Link>
          <div>
            <img
              src="/admin_assets/images/logo-icon-2.png"
              className="logo-icon-2"
              alt="logo icon"
            />
          </div>
          <div className="toggle-icon ms-auto">
            {" "}
            <i className="bi bi-list"></i>
          </div>
        </div>

        <ul className="metismenu" id="menu">
          <li>
            <Link to="/1099/dashboard">
              <div className="parent-icon">
                <i className="fadeIn animated bx bx-home-circle"></i>
              </div>
              <div className="menu-title">Dashboard</div>
            </Link>
          </li>

          <>
            <li>
              <a
                href="/"
                className="has-arrow"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="parent-icon">
                  <i className="bi bi-grid-fill"></i>
                </div>
                <div className="menu-title">Balance</div>
              </a>
              <ul>
                <li>
                  <Link to="/1099/balance/rsa">
                    <i className="bi bi-circle"></i>Rsa Balance
                  </Link>
                </li>
                <li>
                  <Link to="/1099/balance/manager">
                    <i className="bi bi-circle"></i>Manager Balance
                  </Link>
                </li>
              </ul>
            </li>
          </>

          <li>
            <a
              href="/"
              className="has-arrow"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <div className="parent-icon">
                <i className="fadeIn animated bx bx-archive-in"></i>
              </div>
              <div className="menu-title">Import Data</div>
            </a>
            <ul>
              <li>
                <Link to={"/1099/sheet-import"}>
                  <i className="bi bi-circle"></i>Tina Sheet Import
                </Link>
              </li>
              <li>
                <Link to={"/1099/upload-cards"}>
                  <i className="bi bi-circle"></i>Expired Cards Import
                </Link>
              </li>
              <li>
                <Link to={"/1099/missing-sales"}>
                  <i className="bi bi-circle"></i>Missing Sales Import
                </Link>
              </li>
              <li>
                <Link to={"/1099/add-montly-sales"}>
                  <i className="bi bi-circle"></i>Add Montly Sales
                </Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/1099/sale-comparison">
              <div className="parent-icon">
                <i className="lni lni-pencil-alt"></i>
              </div>
              <div className="menu-title">Sales Comparison</div>
            </Link>
          </li>
          <li>
            <Link to="/1099/export-1099-data">
              <div className="parent-icon">
              <i class="bi bi-cloud-download"></i>
              </div>
              <div className="menu-title">Export Data</div>
            </Link>
          </li>

          {/* <li>
            <a
              href="/"
              className="has-arrow"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <div className="parent-icon">
                <i className="lni lni-pencil-alt"></i>
              </div>
              <div className="menu-title">Sales Comparison</div>
            </a>
            <ul>
              <li>
                <Link to={"/1099/sale-comparison"}>
                  <i className="bi bi-circle"></i>Rsa
                </Link>
              </li>
              <>
                <li>
                  <Link to="/1099/sale-comparison">
                    <i className="bi bi-circle"></i>Manager
                  </Link>
                </li>
              </>
            </ul>
          </li> */}
        </ul>
      </aside>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
}
