import { useFormik } from "formik";
import React, { useLayoutEffect, useState } from "react";
import { quizSearch } from "../../../schema";
import { Link } from "react-router-dom";
import AdminListService from "../../../services/admin-list.service";
import AdminFooter from "../includes/AdminFooter";
import HeaderSidebar from "../includes/HeaderSidebar";
import MoveTable from "../includes/MoveTable";
import ToTop from "../includes/ToTop";
import { Table } from "react-bootstrap";
import Pagination from "react-js-pagination";
import useTable from "../../../hooks/useTable";
import useSortableData from "../../../hooks/useSortableData";
import moment from "moment";
import Swal from "sweetalert2";
import $ from "jquery";
import { toast, ToastContainer } from "react-toastify";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

export default function ViewManager() {
  const state = useSelector((state) => state.stateVals);
  const { id: adminId } = state;

  const TITLE = "Ashley Sleep Elite | Manager";
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState("10");
  const [resultData, setResultData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { height, width } = useWindowDimensions();
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("1");

  const date = new Date();
  var time = date.getTime();

  var singleSelections = "";
  //select export
  // FIXME
  const changeSelection = () => {
    let selectedRecords = $(".table-style-1 tbody tr td input:checkbox:checked")
      .map(function () {
        return this.value;
      })
      .get()
      .join("+");
    singleSelections = selectedRecords;
    // console.log(selectedRecords);
    // $(".delete-selection").attr(
    //   "href",
    //   "https://ae-api.elitestacks.com/v1/user/claim/admin/delete-selected/"
    // );
  };
  const deleteSelected = async () => {
    var counter = singleSelections.length;
    console.log(counter);
    if (counter > 0) {
      setLoading(true);
      try {
        await AdminListService.deleteSelectedParticipants(singleSelections);
        setLoading(false);
        toast.success("Record(s) has been deleted!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } catch (err) {
        setLoading(false);
      }
    } else {
      console.log("else");
      toast.error("Please select some record!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    getResultData();
  };

  const getResultData = async (e) => {
    setLoading(true);

    try {
      if (values.fieldtype === "" && values.searchval === "") {
        const response = await AdminListService.getSearchManagerList(
          "",
          e,
          limit,
          1
        );
        let resultData;
        if (response.status === 206) {
          resultData = response.data.response;
          setResultData(resultData);
          setTotalResults(response.data.records);
          setTotalPages(response.data.total_pages);
          setLimit(response.data.per_page);
          setLoading(false);
        }
      } else if (values.fieldtype === "" || values.searchval === "") {
        setLoading(false);

        handleSubmit();
      } else {
        const response = await AdminListService.getSearchManagerByDate(
          values.fieldtype,
          values.searchval,
          e,
          limit,
          1
        );
        let resultData;
        if (response.status === 206) {
          resultData = response.data.response;
          setResultData(resultData);
          setTotalResults(response.data.records);
          setTotalPages(response.data.total_pages);
          setLimit(response.data.per_page);
          setLoading(false);
        }
      }
    } catch (err) {
      setResultData([]);
      setTotalResults("0");
      setTotalPages("1");
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const changeLimit = (e) => {
    let newLimit = e;
    const getResultData = async () => {
      try {
        if (values.fieldtype === "" && values.searchval === "") {
          setLoading(true);

          setLimit(newLimit);

          const response = await AdminListService.getSearchManagerList(
            "",
            "",
            newLimit,
            1
          );

          let resultData;
          if (response.status === 206) {
            setLoading(false);
            resultData = response.data.response;
            setResultData(resultData);
            setTotalResults(response.data.records);
            setCurrentPage(1);
            setTotalPages(response.data.total_pages);
            setLimit(response.data.per_page);
          } else {
            setTotalResults("0");
            setTotalPages("1");
            setResultData([]);
            setLoading(false);
          }
        } else if (values.fieldtype !== "" && values.searchval !== "") {
          setLoading(true);

          setLimit(newLimit);

          const response = await AdminListService.getSearchManagerList(
            values.fieldtype,
            values.searchval,
            newLimit,
            1
          );

          let resultData;
          if (response.status === 206) {
            setLoading(false);
            resultData = response.data.response;

            setResultData(resultData);
            setTotalResults(response.data.records);
            setTotalPages(response.data.total_pages);
            setLimit(response.data.per_page);
          } else {
            setTotalResults("0");
            setTotalPages("1");
            setResultData([]);
            setLoading(false);
          }
        } else if (values.fieldtype !== "" || values.searchval !== "") {
          handleSubmit();
        }
      } catch (err) {
        if (err.response.status === 404) {
          setTotalResults("0");
          setTotalPages("1");
          setResultData([]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getResultData();
  };

  const changePagination = (e) => {
    setCurrentPage(e);

    // console.log(e)
    let pageNo = e;
    // setLimit(newLimit);
    console.log(limit);
    const getResultData = async () => {
      setLoading(true);

      try {
        const response = await AdminListService.getSearchManagerList(
          values.fieldtype,
          values.searchval,
          limit,
          pageNo
        );

        let resultData;
        if (response.status === 206) {
          resultData = response.data.response;

          setResultData(resultData);
          setLimit(response.data.per_page);
          setLoading(false);
        }
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getResultData();
  };

  const searchData = async (action) => {
    setLoading(true);
    try {
      const response = await AdminListService.getSearchManagerList(
        values.fieldtype,
        values.searchval,
        limit,
        1
      );
      let resultData;
      if (response.status === 206) {
        setLoading(false);
        resultData = response.data.response;
        setResultData(resultData);
        setTotalResults(response.data.records);
        setTotalPages(response.data.total_pages);
        setLimit(response.data.per_page);
      } else {
        setTotalResults("0");
        setTotalPages("1");
        setResultData([]);
        setLoading(false);
      }
    } catch (err) {
      setTotalPages("1");
      setTotalResults("0");
      if (err.response.status === 500) {
        setLoading(false);
      } else {
        setLoading(false);
        setResultData([]);
      }
    }
  };

  const delData = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00a9e0",
      cancelButtonColor: "#e0876c",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);

        try {
          const response = await AdminListService.deleteParticipantsData(delId);
          if (response.status === 200) {
            setLoading(false);
            toast.success("Deleted Successfully!", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            getResultData();
          }
        } catch (err) {
          toast.error("Something went wrong!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }
      }
    });
  };
  const deactiveData = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to deactive this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00a9e0",
      cancelButtonColor: "#e0876c",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);

        try {
          await AdminListService.deactiveParticipantsData(adminId, delId);
          setLoading(false);
          toast.success("Deactive Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          getResultData();
        } catch (err) {
          toast.error("Something went wrong!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }
      }
    });
  };
  useLayoutEffect(() => {
    const getResultData = async () => {
      setLoading(true);
      try {
        const response = await AdminListService.getManagerList();
        let resultData;
        if (response.status === 206) {
          resultData = response.data.response;
          setResultData(resultData);
          setLimit(response.data.per_page);
          setTotalResults(response.data.records);
          setTotalPages(response.data.total_pages);
          setLoading(false);
        }
      } catch (err) {
        setTotalPages("1");
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getResultData();
  }, []);

  // const Table = ({ data, rowsPerPage }) => {
  //   const [page, setPage] = useState(1);
  //   const { slice, range } = useTable(data, page, rowsPerPage);
  //   const { items, requestSort, sortConfig } = useSortableData(slice);

  //   const getClassNamesFor = (name) => {
  //     if (!sortConfig) {
  //       return;
  //     }
  //     return sortConfig.key === name ? sortConfig.direction : undefined;
  //   };

  //   const allSelect = async () => {
  //     $("input:checkbox").prop("checked", $(".slect_all").prop("checked"));
  //   };
  //   return (
  //     <>
  //       <table className="table caption-top align-middle table-borderless table-style-1">
  //         <thead>
  //           <tr>
  //             <th
  //               scope="col"
  //               onClick={() => requestSort("Select")}
  //               className={getClassNamesFor("Select")}
  //             >
  //               <label htmlFor="Select">
  //                 <input
  //                   id="Select"
  //                   className="form-check-input slect_all"
  //                   type="checkbox"
  //                   onChange={allSelect}
  //                 />{" "}
  //                 {/* Select */}
  //               </label>
  //             </th>
  //             <th
  //               scope="col"
  //               onClick={() => requestSort("id")}
  //               className={getClassNamesFor("id")}
  //             >
  //               No
  //             </th>
  //             <th
  //               style={{ textAlign: "center" }}
  //               scope="col"
  //               onClick={() => requestSort("Infomation")}
  //               className={getClassNamesFor("Infomation")}
  //             >
  //               Infomation
  //             </th>

  //             <th
  //               style={{ textAlign: "center" }}
  //               scope="col"
  //               onClick={() => requestSort("Action")}
  //               className={getClassNamesFor("Action")}
  //             >
  //               Action
  //             </th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {items.length ? (
  //             items.map((el, index) => (
  //               <tr key={el.id}>
  //                 <td>
  //                   <input
  //                     className="form-check-input select_one"
  //                     id={el.id}
  //                     value={el.id}
  //                     type="checkbox"
  //                     onChange={changeSelection}
  //                   />
  //                 </td>
  //                 <td>
  //                   {currentPage === 1
  //                     ? index + 1
  //                     : (currentPage - 1) * limit + index + 1}
  //                 </td>

  //                 <td>
  //                   <div className="row">
  //                     <div className="col-xl-6">
  //                       <div className="claim-details-tab">
  //                         <h5 className="claim-detail-heading">User Info</h5>
  //                         <ul className="rob-rollmann">
  //                           <li>
  //                             <b>Employee No: </b>
  //                             {el.emp_number > ""
  //                               ? el.emp_number.toUpperCase()
  //                               : "N/A"}
  //                           </li>
  //                           <li>
  //                             <b>First Name:</b> {el.first_name}
  //                           </li>
  //                           <li>
  //                             <b>Last Name:</b> {el.last_name}
  //                           </li>
  //                           <li>
  //                             <b>Username:</b> {el.username}
  //                           </li>
  //                           <li>
  //                             <b>Password:</b> {el.password}
  //                           </li>
  //                           <li>
  //                             <b>Email:</b> {el.email}
  //                           </li>
  //                           <li>
  //                             <b>Address:</b> {el.address1}
  //                           </li>
  //                           <li>
  //                             <b>State:</b>{" "}
  //                             {el.state.length > 0
  //                               ? el.state.toUpperCase()
  //                               : "N/A"}
  //                           </li>
  //                           <li>
  //                             <b>City:</b>{" "}
  //                             {el.city.length > 0
  //                               ? el.city.toUpperCase()
  //                               : "N/A"}
  //                           </li>
  //                           <li>
  //                             <b>Zip:</b> {el.zip}
  //                           </li>
  //                           <li>
  //                             <b>Date Registered: </b>
  //                             {moment(el.created_at).format("MM-DD-YYYY")}
  //                           </li>
  //                         </ul>
  //                       </div>
  //                     </div>

  //                     <div className="col-xl-6">
  //                       <div className="claim-details-tab">
  //                         <h5 className="claim-detail-heading">Store Info</h5>
  //                         <ul className="rob-rollmann">
  //                           <li>
  //                             <b>Store Name:</b> {el.store_name}
  //                           </li>
  //                           <li>
  //                             <b>Address:</b> {el.store_address}
  //                           </li>
  //                           <li>
  //                             <b>City:</b>{" "}
  //                             {el.store_city ? el.store_city : "N/A"}
  //                           </li>
  //                           <li>
  //                             <b>Zip:</b> {el.store_zip}
  //                           </li>
  //                         </ul>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </td>

  //                 <td className="add-edit-delete-inline">
  //                   <button
  //                     className="btn btn-primary px-4 back-blue"
  //                     onClick={() => deactiveData(el.id)}
  //                   >
  //                     Deactive <i class="bi bi-person-x-fill"></i>
  //                   </button>
  //                   <Link
  //                     to={"/admin/edit/manager/" + el.id}
  //                     className="btn btn-primary px-4 back-blue"
  //                   >
  //                     Edit <i className="bi bi-pencil-fill"></i>{" "}
  //                   </Link>
  //                   {/* <button className="btn btn-primary px-4 back-blue">
  //                     Edit <i className="bi bi-pencil-fill"></i>{" "}
  //                   </button> */}
  //                   <button
  //                     className="btn btn-primary px-4 back-blue"
  //                     onClick={() => delData(el.id)}
  //                   >
  //                     Delete <i className="bi bi-trash-fill"></i>
  //                   </button>
  //                 </td>
  //               </tr>
  //             ))
  //           ) : (
  //             <tr>
  //               <td colSpan="6" className="text-center text-capitalize">
  //                 No record found
  //               </td>
  //             </tr>
  //           )}
  //         </tbody>
  //       </table>
  //       {/* {totalResults > limit && totalPages > 1 ? (
  //         <Pagination
  //           activePage={currentPage}
  //           itemsCountPerPage={parseInt(limit)}
  //           totalItemsCount={totalResults}
  //           onChange={(e) => {
  //             changePagination(e);
  //           }}
  //           pageRangeDisplayed={8}
  //           itemClass="page-item"
  //           linkClass="page-link"
  //           firstPageText="First Page"
  //           lastPageText="Last Page"
  //         />
  //       ) : (
  //         ""
  //       )} */}
  //     </>
  //   );
  // };

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const allSelect = async () => {
      $("input:checkbox").prop("checked", $(".slect_all").prop("checked"));
    };
    return (
      <>
        <table className=" caption-top align-middle table-borderless table-style-1">
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("Select")}
                className={getClassNamesFor("Select")}
              >
                <label htmlFor="Select">
                  Select{" "}
                  <input
                    id="Select"
                    className="form-check-input slect_all"
                    type="checkbox"
                    onChange={allSelect}
                  />
                </label>
              </th>
              <th
                scope="col"
                onClick={() => requestSort("id")}
                className={getClassNamesFor("id")}
              >
                No
              </th>
              <th
                scope="col"
                onClick={() => requestSort("emp_number")}
                className={getClassNamesFor("emp_number")}
              >
                Employee No
              </th>
              <th
                scope="col"
                onClick={() => requestSort("first_name")}
                className={getClassNamesFor("first_name")}
              >
                Login Info
              </th>
              <th
                scope="col"
                onClick={() => requestSort("address")}
                className={getClassNamesFor("address")}
              >
                Address
              </th>
              <th
                width="17%"
                scope="col"
                onClick={() => requestSort("store_name")}
                className={getClassNamesFor("store_name")}
              >
                Store Information
              </th>
              <th
                scope="col"
                onClick={() => requestSort("created_at")}
                className={getClassNamesFor("created_at")}
              >
                Date Registered
              </th>

              <th
                scope="col"
                onClick={() => requestSort("Action")}
                className={getClassNamesFor("Action")}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={el.id}>
                  <td>
                    <input
                      className="form-check-input select_one"
                      id={el.id}
                      value={el.id}
                      type="checkbox"
                      onChange={changeSelection}
                    />
                  </td>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>
                    {el.emp_number > "" ? el.emp_number.toUpperCase() : "N/A"}
                  </td>

                  <td>
                    <ul className="rob-rollmann">
                      <li>
                        <b>First Name:</b> {el.first_name}
                      </li>
                      <li>
                        <b>Last Name:</b> {el.last_name}
                      </li>
                      <li>
                        <b>Username:</b> {el.username}
                      </li>
                      <li>
                        <b>Password:</b> {el.password}
                      </li>
                      <li>
                        <b>Email:</b> {el.email}
                      </li>
                    </ul>
                  </td>

                  <td>
                    <ul className="rob-rollmann">
                      <li>
                        <b>Address:</b> {el.address1}
                      </li>
                      <li>
                        <b>State:</b>{" "}
                        {el.state.length > 0 ? el.state.toUpperCase() : "N/A"}
                      </li>
                      <li>
                        <b>City:</b>{" "}
                        {el.city.length > 0 ? el.city.toUpperCase() : "N/A"}
                      </li>
                      <li>
                        <b>Zip:</b> {el.zip}
                      </li>
                    </ul>
                  </td>

                  <td>
                    <ul className="rob-rollmann">
                      <li>
                        <b>Store Name:</b> {el.store_name}
                      </li>
                      <li>
                        <b>Address:</b> {el.store_address}
                      </li>
                      <li>
                        <b>City:</b> {el.store_city ? el.store_city : "N/A"}
                      </li>
                      <li>
                        <b>Zip:</b> {el.store_zip}
                      </li>
                    </ul>
                  </td>
                  <td>{moment(el.created_at).format("MM-DD-YYYY")}</td>

                  <td className="add-edit-delete-inline">
                    <button
                      className="btn btn-primary px-2 back-blue btn-sm mb-2"
                      onClick={() => deactiveData(el.id)}
                    >
                      Deactive <i class="bi bi-person-x-fill"></i>
                    </button>
                    <Link
                      to={"/admin/edit/manager/" + el.id}
                      className="btn btn-primary px-2 back-blue btn-sm mb-2"
                    >
                      Edit <i className="bi bi-pencil-fill"></i>{" "}
                    </Link>
                    {/* <button className="btn btn-primary px-4 back-blue">
                      Edit <i className="bi bi-pencil-fill"></i>{" "}
                    </button> */}
                    <button
                      className="btn btn-primary px-2 back-blue btn-sm mb-2"
                      onClick={() => delData(el.id)}
                    >
                      Delete <i className="bi bi-trash-fill"></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    );
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        fieldtype: "",
        searchval: "",
      },
      validationSchema: quizSearch,
      onSubmit: (values, action) => {
        searchData(action);
      },
    });

  setTimeout(() => {
    setLoading(false);
  }, 1000);
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="semi-dark">
        <div className="wrapper">
          <ToastContainer />
          <HeaderSidebar />
          <main className="page-content">
            <div className="row">
              <div className="col">
                <div className="manage-heading-2">
                  <h2>
                    View Manager <span>[{totalResults}]</span>
                  </h2>
                </div>

                <div className="slides-here">
                  <div className="alert alert-info">
                    <b>Info!</b> You can search your required data by putting
                    text in search box
                  </div>
                  <div className="main-content-box">
                    <div className="manage-territories-box">
                      <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                          <div className="col-lg-12">
                            <h2 className="manage-territories-heading">
                              All Users
                            </h2>
                          </div>

                          <div className="col-lg-3">
                            <div className="form-group custom-group mt-4">
                              <select
                                className={`form-select ${
                                  errors.fieldtype && touched.fieldtype
                                    ? "is-danger"
                                    : ""
                                }`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="fieldtype"
                                value={values.fieldtype || ""}
                                required
                              >
                                <option value="">Search By</option>
                                <option value="1">Employee No</option>
                                <option value="2">First Name</option>
                                <option value="3">Last Name</option>
                                <option value="4">Username</option>
                                <option value="5">Email</option>
                                <option value="6">Address</option>
                                <option value="7">State</option>
                                <option value="8">City</option>
                                <option value="9">Zip</option>
                                {/* <option value="10">Date Registered</option> */}
                                <option value="11">Store Name</option>
                                <option value="12">Store Address</option>
                                <option value="13">Store City</option>
                                <option value="14">Store Zip</option>
                              </select>
                              {errors.fieldtype && touched.fieldtype ? (
                                <p className="help is-danger">
                                  {errors.fieldtype}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="input-group custom-group mt-4">
                              <input
                                type="text"
                                className={`form-control ${
                                  errors.searchval && touched.searchval
                                    ? "is-danger"
                                    : ""
                                }`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="searchval"
                                placeholder="Enter something to search"
                                value={values.searchval || ""}
                                required
                              />{" "}
                              <button
                                className="input-group-text back-orange"
                                id="basic-addon2"
                              >
                                Search
                              </button>
                              {errors.searchval && touched.searchval ? (
                                <p className="help is-danger">
                                  {errors.searchval}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-2">
                            <div className="form-group custom-group">
                              <label className="form-label"></label>
                              <select
                                className="form-select"
                                value={limit}
                                onChange={(e) => changeLimit(e.target.value)}
                              >
                                <option>10</option>
                                <option>50</option>
                                <option>100</option>
                                <option>200</option>
                                <option>300</option>
                                <option>400</option>
                                <option>500</option>
                                <option>600</option>
                                <option>700</option>
                                <option>800</option>
                                <option>900</option>
                                <option>1000</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-lg-1">
                            <li className="dropdown">
                              <button
                                className="btn btn-primary back-blue dropdown-toggle viewRSA"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                id="RsaAction"
                              >
                                Action
                              </button>
                              <ul className="dropdown-menu">
                                <li>
                                  <a
                                    className="dropdown-item delete-selection"
                                    name="delete_selected"
                                    href="javascript:void(0)"
                                    onClick={(e) => {
                                      deleteSelected(e);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Delete Selected
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href={`${process.env.REACT_APP_API_Link}user/export-all-manager?time=${time}`}
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                  >
                                    Export All
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </div>
                          <div className="col-lg-3">
                            <button
                              className="btn btn-primary back-orange viewRSA1"
                              type="reset"
                              onClick={() => window.location.reload(false)}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    {/* <MoveTable /> */}
                    <div className="claim-table">
                      <Table data={resultData} rowsPerPage={limit} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="pagi">
                  {totalResults > limit && totalPages > 1 ? (
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={parseInt(limit)}
                      totalItemsCount={totalResults}
                      onChange={(e) => {
                        changePagination(e);
                      }}
                      pageRangeDisplayed={
                        width >= 1024
                          ? 8
                          : width >= 768
                          ? 6
                          : width >= 425
                          ? 3
                          : width >= 375
                          ? 2
                          : 1
                      }
                      itemClass="page-item"
                      linkClass="page-link"
                      firstPageText="First Page"
                      lastPageText="Last Page"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </main>
          <ToTop />
          <div className={`loader ${loading ? "in" : ""}`}>
            <div className="spinner-border main-spin"></div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
}
