import { useFormik } from "formik";
import React, { useLayoutEffect, useRef, useState } from "react";

import Pagination from "react-js-pagination";
import { Link, useParams } from "react-router-dom";
import useSortableData from "../../../src/hooks/useSortableData";
import useTable from "../../../src/hooks/useTable";
import { quizSearch } from "../../schema";
import AdminListService from "../../../src/services/admin-list.service";
import AdminFooter from "../../Components/Admin/includes/AdminFooter";
import MoveTable from "../../Components/Admin/includes/MoveTable";
import ToTop from "../../Components/Admin/includes/ToTop";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import HeaderSidebar1099 from "../1099Panel/includes/HeaderSidebar1099";
import UserService from "../../services/user.service";

export default function Balance() {
  const TITLE = "Ashley Sleep Elite | Balance Users";
  const [loading, setLoading] = useState(false);
  const [fieldClass, setFieldClass] = useState("form-input blink-soft");
  const [fieldClass2, setFieldClass2] = useState("form-input blink-soft");
  const [fieldClass3, setFieldClass3] = useState("form-date blink-soft");
  const [fieldClass4, setFieldClass4] = useState("form-date blink-soft");
  const [limit, setLimit] = useState("10");
  const [selectedCol, setSelectedCol] = useState("");
  const [selectedVal, setSelectedVal] = useState("");
  const [selectedSales, setSelectedSales] = useState("");
  const [selectedTina, setSelectedTina] = useState("");
  const [resultData, setResultData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { height, width } = useWindowDimensions();
  const [totalResults, setTotalResults] = useState("0");
  const [unbalanced, setUnbalanced] = useState("0");
  const [salesAdd, setSalesAdd] = useState("0");
  const [salesDeduct, setSalesDeduct] = useState("0");
  const [totalPages, setTotalPages] = useState("1");
  const { type } = useParams();
  const [date, setDate] = useState({
    start: "0000-00-00",
    end: "0000-00-00",
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isRecords, setIsRecords] = useState(true);

  const [isExport, setIsExport] = useState(true);
  const [isEndDate, setIsEndDate] = useState(false);
  const [insertedVal, setInsertedVal] = useState("");

  const dateRef = useRef(null);
  const endDateRef = useRef(null);
  const date1 = new Date();
  var time = date1.getTime();

  const styles = {
    export: {
      pointerEvents: isExport ? "none" : "",
      opacity: isExport ? "0.6" : "1",
      marginTop: "21px",
    },
  };
  const getResultData = async (
    resultType,
    resultCol,
    resultVal,
    sales,
    tina,
    resultStart,
    resultEnd,
    start,
    end
  ) => {
    setLoading(true);
    try {
      const response = await AdminListService.getUserBalanceList(
        resultType,
        resultCol,
        resultVal,
        sales,
        tina,
        resultStart,
        resultEnd,
        start,
        end
      );
      let resultData;
      resultData = response.data.response;
      console.log(response.data);
      setResultData(resultData);
      setLimit(response.data.per_page);
      setTotalResults(response.data.records);
      setUnbalanced(response.data.unbalanced_users);
      setSalesAdd(response.data.sales_added);
      setSalesDeduct(response.data.sales_deducted);
      setTotalPages(response.data.total_pages);
      setLoading(false);
    } catch (err) {
      setTotalPages("1");
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  // const changeLimit = (e) => {
  //   let newLimit = e;

  //   setCurrentPage(1);

  //   getResultData(
  //     type,
  //     selectedCol,
  //     selectedVal,
  //     date.start,
  //     date.end,
  //     newLimit,
  //     1
  //   );
  // };

  // const changePagination = (e) => {
  //   console.log(e);
  //   setCurrentPage(e);

  //   let pageNo = e;
  //   getResultData(
  //     type,
  //     selectedCol,
  //     selectedVal,
  //     date.start,
  //     date.end,
  //     limit,
  //     pageNo
  //   );
  // };

  const searchData = async (action) => {
    // setCurrentPage(1);
    // getResultData(
    //   type,
    //   values.fieldtype,
    //   values.searchval,
    //   date.start,
    //   date.end,
    //   limit,
    //   1
    // );
  };

  // const changeSelect = (e) => {
  //   setSelectedCol(e);
  // };

  // const changeValue = (e) => {
  //   setSelectedVal(e);
  // };

  const changeSale = (e) => {
    setSelectedSales(e);
    if (e != "") {
      setFieldClass("form-input");
      console.log("form-input");
    } else {
      setFieldClass("form-input blink-soft");
      console.log("form-input2");
    }
  };

  const changeTina = (e) => {
    setSelectedTina(e);
    if (e != "") {
      setFieldClass2("form-input");
    } else {
      setFieldClass2("form-input blink-soft");
    }
  };
  // endDateRef
  const changeStartDate = (e) => {
    if (e != "") {
      setFieldClass3("form-date");
      console.log("form-date");
    } else {
      setFieldClass3("form-date blink-soft");
      console.log("form-date");
    }
    console.log("start date");
    let date = e;
    setStartDate(date);
    setIsRecords(true);
    setIsExport(true);
    // $('.reacter-datepicker input[name="end"]').val("");
    endDateRef.current.value = "";

    setIsEndDate(true);
  };

  const changeEndDate = (e) => {
    if (e != "") {
      setFieldClass4("form-date");
      console.log("form-date");
    } else {
      setFieldClass4("form-date blink-soft");
      console.log("form-date");
    }
    let date = e;
    setEndDate(date);
    setIsRecords(false);
    setIsExport(false);
    let startSplit = startDate.split("-");
    let endSplit = date.split("-");
    let date1 = new Date(startSplit[0], startSplit[1], startSplit[2]);
    let date2 = new Date(endSplit[0], endSplit[1], endSplit[2]);
    if (date2 < date1) {
      endDateRef.current.value = "";
      toast.error("Ending date must be greater than starting date!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const showRecords = async () => {
    // TODO api call, start end date from states
    // console.log(startDate);
    // console.log(endDate);
    // TODO
    if (selectedSales && selectedTina && startDate && endDate) {
      try {
        setLoading(true);
        const response = await UserService.getSearchWithDateAllBalance(
          type,
          selectedSales,
          selectedTina,
          startDate,
          endDate
        );
        const { data } = response;
        const { response: res } = data;
        console.log(data.records);
        setResultData(res);
        setTotalResults(data.records);
        setTotalPages(data.total_pages);
        setLimit(data.per_page);
        setLoading(false);
      } catch (err) {
        setIsExport(true);
        setResultData([]);
        setTotalResults("0");
        setTotalPages("1");
        setLoading(false);
      }
    } else {
      toast.error("Please select Filed Claim Start & End Dates", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    // setLoading(true);

    // if record found then update the state
  };

  useLayoutEffect(() => {
    getResultData(
      type,
      selectedCol,
      selectedVal,
      selectedSales,
      selectedTina,
      dateRef.current.value,
      endDateRef.current.value
    );
  }, [type]);

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);
    console.log(data);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table className="table caption-top align-middle table-borderless table-style-1">
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("index")}
                className={getClassNamesFor("index")}
              >
                No
              </th>
              <th
                scope="col"
                onClick={() => requestSort("udf")}
                className={getClassNamesFor("udf")}
              >
                UDF2
              </th>
              <th
                scope="col"
                onClick={() => requestSort("username")}
                className={getClassNamesFor("username")}
              >
                Username
              </th>
              <th
                scope="col"
                onClick={() => requestSort("email")}
                className={getClassNamesFor("email")}
              >
                Email Address
              </th>
              <th
                scope="col"
                onClick={() => requestSort("dbtotal")}
                className={getClassNamesFor("dbtotal")}
              >
                DB Sale Amount
              </th>
              <th
                scope="col"
                onClick={() => requestSort("tinatotal")}
                className={getClassNamesFor("tinatotal")}
              >
                Tina Sheet Amount
              </th>
              <th
                scope="col"
                onClick={() => requestSort("difference")}
                className={getClassNamesFor("difference")}
              >
                Difference
              </th>
              {/* <th
                scope="col"
                onClick={() => requestSort("date")}
                className={getClassNamesFor("date")}
              >
                Date
              </th> */}
            </tr>
          </thead>
          <tbody>
            {console.log(data.length)}
            {data.length ? (
              data.map((el, index) => (
                <tr key={index}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.udf}</td>
                  <td>{el.username}</td>
                  <td>{el.email}</td>
                  <td>{el.dbtotal}</td>
                  <td>{el.tinatotal}</td>
                  <td style={{ color: el.difference < 0 ? "red" : "" }}>
                    {el.difference}
                  </td>
                  {/* <td>{el.date}</td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    );
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        fieldtype: "",
        searchval: "",
      },
      validationSchema: quizSearch,
      onSubmit: (values, action) => {
        searchData(action);
      },
    });

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="semi-dark">
        <div className="wrapper">
          <HeaderSidebar1099 />
          <main className="page-content">
            <div className="row">
              <div className="col">
                <div className="manage-heading-2">
                  <h2>
                    Balance {type} <span>[{totalResults}]</span>
                  </h2>
                </div>

                <div className="slides-here">
                  <div className="alert alert-info">
                    You can search your required data by putting text in search
                    box
                  </div>
                  <div className="main-content-box rzl-tabl">
                    <form onSubmit={handleSubmit} noValidate>
                      <div className="row mb-3">
                        <div className="col-lg-12">
                          <h2 className="manage-territories-heading">
                            Ashley Elite Sales Balance
                          </h2>
                        </div>

                        {/* <div className="col-lg-3">
                        <select
                          className={`form-select ${
                            errors.fieldtype && touched.fieldtype
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={(e) => {
                            handleChange(e);
                            changeSelect(e.target.value);
                          }}
                          onBlur={handleBlur}
                          name="fieldtype"
                          value={values.fieldtype || ""}
                          required
                        >
                          <option value="">Select</option>
                          <option value="1">UDF2</option>
                          <option value="2">Username </option>
                          <option value="3">Email Address </option>
                          <option value="4">DB Sale Amount </option>
                          <option value="5">Tina Sheet Amount </option>
                        </select>
                        {errors.fieldtype && touched.fieldtype ? (
                          <p className="help is-danger">{errors.fieldtype}</p>
                        ) : null}
                      </div> */}

                        {/* <div className="col-lg-4 ">
                        <div className="input-group">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.searchval && touched.searchval
                                ? "is-danger"
                                : ""
                            }`}
                            onChange={(e) => {
                              handleChange(e);
                              changeValue(e.target.value);
                            }}
                            onBlur={handleBlur}
                            name="searchval"
                            placeholder="Enter something to search"
                            value={values.searchval || ""}
                            required
                          />

                          <button
                            className="btn btn-outline-secondary sebmit-dat"
                            type="submit"
                          >
                            Search
                          </button>
                        </div>
                        {errors.searchval && touched.searchval ? (
                          <p className="help is-danger">{errors.searchval}</p>
                        ) : null}
                      </div> */}

                        {/* <div className="col-lg-2 ">
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) => changeLimit(e.target.value)}
                        >
                          <option>10</option>
                          <option>50</option>
                          <option>100</option>
                          <option>200</option>
                          <option>300</option>
                          <option>400</option>
                          <option>500</option>
                          <option>600</option>
                          <option>700</option>
                          <option>800</option>
                          <option>900</option>
                          <option>1000</option>
                        </select>
                      </div> */}

                        <div className="col-lg-6 ">
                          <div className="input-group">
                            <input
                              type="text"
                              className={`form-control ${
                                errors.sales && touched.sales ? "is-danger" : ""
                              }${fieldClass}`}
                              onChange={(e) => {
                                handleChange(e);
                                changeSale(e.target.value);
                              }}
                              // onBlur={handleBlur}
                              name="sales"
                              placeholder="Enter Sales Table Name"
                              value={values.sales || ""}
                              required
                            />
                          </div>
                          {errors.sales && touched.sales ? (
                            <p className="help is-danger">{errors.sales}</p>
                          ) : null}
                        </div>
                        <div className="col-lg-6">
                          <div className="input-group">
                            <input
                              type="text"
                              className={`form-control ${
                                errors.tina && touched.tina ? "is-danger" : ""
                              }${fieldClass2}`}
                              onChange={(e) => {
                                handleChange(e);
                                changeTina(e.target.value);
                              }}
                              // onBlur={handleBlur}
                              name="tina"
                              placeholder="Enter Tina Table Name"
                              value={values.tina || ""}
                              required
                            />
                          </div>
                          {errors.tina && touched.tina ? (
                            <p className="help is-danger">{errors.tina}</p>
                          ) : null}
                        </div>
                        <div className="col-lg-3">
                          <label>Start Date</label>
                          <div className="reacter-datepicker">
                            <input
                              type="date"
                              name="start"
                              ref={dateRef}
                              className={`form-control ${fieldClass3}`}
                              onChange={(e) => {
                                changeStartDate(e.target.value);
                              }}
                              placeholder="mm-dd-yyyy"
                            />
                          </div>
                        </div>

                        <div className="col-lg-5">
                          <label>End Date</label>
                          <div className="input-group">
                            <input
                              type="date"
                              name="end"
                              ref={endDateRef}
                              className={`form-control ${fieldClass4}`}
                              onChange={(e) => changeEndDate(e.target.value)}
                              placeholder="mm-dd-yyyy"
                              disabled={!isEndDate}
                            />
                            <button
                              className="btn btn-primary back-orange show-records mt-0"
                              onClick={showRecords}
                              disabled={isRecords}
                            >
                              Show records
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-2" style={{ marginTop: "21px" }}>
                          <button
                            className="btn btn-outline-secondary"
                            type="reset"
                            onClick={() => window.location.reload(false)}
                          >
                            Reset
                          </button>
                        </div>
                        <div className="col-md-2">
                          <a
                            href={
                              `${process.env.REACT_APP_API_Link}user/1099/export-${type}-sales/${selectedSales}/${selectedTina}/${startDate}/${endDate}?time=${time}`
                            }
                            type="button"
                            className="btn btn-outline-secondary"
                            // style={ `marginTop: "21px" styles.export}`}
                            style={styles.export}
                          >
                            Export All
                          </a>
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-md-3">
                          <div className="card-counter info">
                            <i className="fa fa-code-fork"></i>
                            <span className="count-namee0">
                              UnBalance Sales Users
                            </span>
                            <span className="count-numberss0">
                              {unbalanced}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="card-counter success">
                            <i className="fa fa-plus"></i>
                            <span className="count-name1">
                              Sales To Be Added
                            </span>
                            <span className="count-numbers1">{salesAdd}</span>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="card-counter danger">
                            <i className="fa fa-minus"></i>
                            <span className="count-name2">
                              Sales To Be Deduction
                            </span>
                            <span className="count-numbers2">
                              {salesDeduct}
                            </span>
                          </div>
                        </div>
                        {/* <div className="col-md-3">
                        <button type="button" className="btn btn-primary back-orange ">
                          Export All
                        </button>
                      </div> */}
                      </div>
                    </form>
                    <MoveTable />
                    <div className="table-responsive">
                      <Table data={resultData} rowsPerPage={limit} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="container">
            <div className="row">
              <div className="pagi">
                {totalResults > limit && totalPages > 1 ? (
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={parseInt(limit)}
                    totalItemsCount={totalResults}
                    onChange={(e) => {
                      changePagination(e);
                    }}
                    pageRangeDisplayed={
                      width >= 1024
                        ? 8
                        : width >= 768
                        ? 6
                        : width >= 425
                        ? 3
                        : width >= 375
                        ? 2
                        : 1
                    }
                    itemClass="page-item"
                    linkClass="page-link"
                    firstPageText="First Page"
                    lastPageText="Last Page"
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div> */}
          </main>
          <ToTop />
          <div className={`loader ${loading ? "in" : ""}`}>
            <div className="spinner-border main-spin"></div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
}
