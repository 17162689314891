import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { bindActionCreators } from "redux";
import { actionCreaters } from "../../../Redux/index";
import { useSelector } from "react-redux";
import AdminListService from "../../../services/admin-list.service";

export default function HeaderSidebar() {
  const [loading, setLoading] = useState(false);
  const [allYears, setAllYears] = useState([]);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);

  const state = useSelector((state) => state.stateVals);
  const { name, uType } = state;

  var welcomeFname;
  if (name) {
    welcomeFname = name.split(" ");
  } else {
    welcomeFname = "Unknown";
  }

  const logOut = async (e) => {
    e.preventDefault();
    setLoading(true);
    userActions.logOut(null);
    setLoading(false);
    navigate("/admin");
  };

  const getAllYears = async () => {
    try {
      const response = await AdminListService.getAllYears();
      console.log(response.data.response);
      setAllYears(response.data.response);
    } catch (err) {
      setAllYears([]);
    }
  };

  useLayoutEffect(() => {
    getAllYears();
  }, []);

  return (
    <>
      <header className="top-header">
        <nav className="navbar navbar-expand gap-3">
          <div className="mobile-toggle-icon fs-3">
            <i className="bi bi-list"></i>
          </div>
          <div className="top-navbar-right ms-auto">
            <ul className="navbar-nav align-items-center">
              <li className="nav-item">
                <Link className="nav-link" to="/admin/dashboard">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/admin/profile-admin">
                  Profile
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/admin/"
                  onClick={(e) => {
                    logOut(e);
                  }}
                >
                  Logout
                </Link>
              </li>
              <li className="nav-item">
                <div className="red-welcome">
                  <div className="trianles">
                    {" "}
                    <img
                      src="/admin_assets/images/lef-red.png"
                      className="lef-red"
                      alt=""
                    />{" "}
                    <img
                      src="/admin_assets/images/rig-red.png"
                      className="rig-red"
                      alt=""
                    />{" "}
                    <div className="welcome-txt">
                      Welcome - {welcomeFname[0] ? welcomeFname[0] : "Unknown"}
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      <aside className="sidebar-wrapper" data-simplebar="true">
        <div className="sidebar-header">
          <Link to="/admin/dashboard">
            <img
              src="/admin_assets/images/logo-icon.png"
              className="logo-icon"
              alt="logo icon"
            />
          </Link>
          <div>
            <img
              src="/admin_assets/images/logo-icon-2.png"
              className="logo-icon-2"
              alt="logo icon"
            />
          </div>
          <div className="toggle-icon ms-auto">
            {" "}
            <i className="bi bi-list"></i>
          </div>
        </div>

        <ul className="metismenu" id="menu">
          <li>
            <Link to="/admin/dashboard">
              <div className="parent-icon">
                <i className="fadeIn animated bx bx-home-circle"></i>
              </div>
              <div className="menu-title">Dashboard</div>
            </Link>
          </li>

          {uType !== "dos" ? (
            <>
              <li>
                <a
                  href="/"
                  className="has-arrow"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="parent-icon">
                    <i className="bi bi-grid-fill"></i>
                  </div>
                  <div className="menu-title">Claims</div>
                </a>
                <ul>
                  <li>
                    <Link to="/admin/claims">
                      <i className="bi bi-circle"></i>Latest Claims
                    </Link>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="has-arrow"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <i className="bi bi-circle"></i>Processed Claims
                    </a>
                    <ul>
                      <li>
                        <Link to="/admin/accepted-claim">
                          <i className="bi bi-circle"></i>Accepted Claims
                        </Link>
                      </li>
                      <li>
                        <Link to="/admin/rejected-claim">
                          <i className="bi bi-circle"></i>Rejected Claims
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </>
          ) : null}

          <li>
            <a
              href="/"
              className="has-arrow"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <div className="parent-icon">
                <i className="fadeIn animated bx bx-archive-in"></i>
              </div>
              <div className="menu-title">Archive Claims</div>
            </a>
            <ul>
              <li>
                <Link
                  to={
                    uType == "dos"
                      ? "/admin/archived-dos-claim"
                      : "/admin/archived-claim"
                  }
                >
                  <i className="bi bi-circle"></i>All Archive Claims
                </Link>
              </li>
              {/* <li>
                    <Link to="/admin/archived-claim">
                      <i className="bi bi-circle"></i>2017 Claims
                    </Link>
                  </li> */}
              {/* <li>
                    <Link to="/admin/archived-claim">
                      <i className="bi bi-circle"></i>2018 Claims
                    </Link>
                  </li> */}
            </ul>
          </li>

          <li>
            <a
              href="/"
              className="has-arrow"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <div className="parent-icon">
                <i className="lni lni-pencil-alt"></i>
              </div>
              <div className="menu-title">Stores</div>
            </a>
            <ul>
              <li>
                <Link
                  to={
                    uType !== "dos"
                      ? "/admin/manage-territory"
                      : "/admin/manage-dos-territory"
                  }
                >
                  <i className="bi bi-circle"></i>Manage Territories
                </Link>
              </li>
              {uType !== "dos" ? (
                <>
                  <li>
                    <Link to="/admin/view-dos">
                      <i className="bi bi-circle"></i>Manage DOS
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/view-vp">
                      <i className="bi bi-circle"></i>Manage VP
                    </Link>
                  </li>
                </>
              ) : null}
            </ul>
          </li>
          {uType !== "dos" ? (
            <>
              <li>
                <a
                  href="/"
                  className="has-arrow"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="parent-icon">
                    <i className="bi bi-person-lines-fill"></i>
                  </div>
                  <div className="menu-title">Participants</div>
                </a>
                <ul>
                  <li>
                    <Link to="/admin/view-rsa">
                      <i className="bi bi-circle"></i>View RSA's
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/view-manager">
                      <i className="bi bi-circle"></i>View Managers
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/view-corporate">
                      <i className="bi bi-circle"></i>View Corporate RSA's
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/view-deactive-rsa">
                      <i className="bi bi-circle"></i>View Deactive RSA's
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/view-deactive-manager">
                      <i className="bi bi-circle"></i>View Deactive Manager
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  href="/"
                  className="has-arrow"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="parent-icon">
                    <i className="fadeIn animated bx bx-layer"></i>
                  </div>
                  <div className="menu-title">Product</div>
                </a>
                <ul>
                  <li>
                    <Link to="/admin/add-product">
                      <i className="bi bi-circle"></i>Add Product
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/add-product-size">
                      <i className="bi bi-circle"></i>Add Product Size
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/view-product">
                      <i className="bi bi-circle"></i>View All Products
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <a
                  href="/"
                  className="has-arrow"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="parent-icon">
                    <i className="lni lni-world-alt"></i>
                  </div>
                  <div className="menu-title">Store States</div>
                </a>
                <ul>
                  <li>
                    <Link to="/admin/add-state">
                      <i className="bi bi-circle"></i>Add State
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/view-states">
                      <i className="bi bi-circle"></i>View All States
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <a
                  href="/"
                  className="has-arrow"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="parent-icon">
                    <i className="fadeIn animated bx bx-building"></i>
                  </div>
                  <div className="menu-title">Store Cities</div>
                </a>
                <ul>
                  <li>
                    <Link to="/admin/add-city">
                      <i className="bi bi-circle"></i>Add City
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/view-cities">
                      <i className="bi bi-circle"></i>View All Cities
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <a
                  href="/"
                  className="has-arrow"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="parent-icon">
                    <i className="lni lni-world-alt"></i>
                  </div>
                  <div className="menu-title">User States</div>
                </a>
                <ul>
                  <li>
                    <Link to="/admin/add-user-state">
                      <i className="bi bi-circle"></i>Add State
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/view-user-states">
                      <i className="bi bi-circle"></i>View All States
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <a
                  href="/"
                  className="has-arrow"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="parent-icon">
                    <i className="fadeIn animated bx bx-building"></i>
                  </div>
                  <div className="menu-title">User Cities</div>
                </a>
                <ul>
                  <li>
                    <Link to="/admin/add-user-city">
                      <i className="bi bi-circle"></i>Add City
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/view-user-cities">
                      <i className="bi bi-circle"></i>View All Cities
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <a
                  href="/"
                  className="has-arrow"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="parent-icon">
                    <i className="fadeIn animated bx bx-store"></i>
                  </div>
                  <div className="menu-title">Stores</div>
                </a>
                <ul>
                  <li>
                    <Link to="/admin/add-store">
                      <i className="bi bi-circle"></i>Add Stores
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/view-stores">
                      <i className="bi bi-circle"></i>View All Stores
                    </Link>
                  </li>
                </ul>
              </li>

              {/* <li>
                <a
                  href="/"
                  className="has-arrow"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="parent-icon">
                    <i className="fadeIn animated bx bx-repost"></i>
                  </div>
                  <div className="menu-title">Reports</div>
                </a>
                <ul>
                  <li>
                    <Link to="/admin/">
                      <i className="bi bi-circle"></i>Export All Claims
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/">
                      <i className="bi bi-circle"></i>Download 1099 Report
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/reports-history">
                      <i className="bi bi-circle"></i>Reports History
                    </Link>
                  </li>
                </ul>
              </li> */}
              <li>
                <a
                  href="/"
                  className="has-arrow"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="parent-icon">
                    <i className="lni lni-library "></i>
                  </div>
                  <div className="menu-title">REPORTS</div>
                </a>
                <ul>
                  <li>
                    <a
                      href="/"
                      className="has-arrow"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <i className="bi bi-circle"></i>Export All Claims
                    </a>
                    <ul>
                      {allYears.length > 0 &&
                        allYears.map((data) => (
                          <li>
                            <Link
                              to={`https://ae-api.elitestacks.com/v1/user/reports/export-report-claims/${data}`}
                            >
                              <i className="bi bi-circle"></i>
                              {data}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="has-arrow"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <i className="bi bi-circle"></i>Download 1099 Report
                    </a>
                    <ul>
                      {allYears.length > 0 &&
                        allYears.map((data) => (
                          <li>
                            <Link
                              to={`https://ae-api.elitestacks.com/v1/user/reports/export-1099-report/${data}`}
                            >
                              <i className="bi bi-circle"></i>
                              {data}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </li>
                  <li>
                    <Link to="/admin/reports-history">
                      <i className="bi bi-circle"></i>Reports History{" "}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <a
                  href="/"
                  className="has-arrow"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="parent-icon">
                    <i className="fadeIn animated bx bx-history"></i>
                  </div>
                  <div className="menu-title">1099</div>
                </a>
                <ul>
                  <li>
                    <Link to="/admin/year">
                      <i className="bi bi-circle"></i>Claim by year{" "}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <a
                  href="/"
                  className="has-arrow"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="parent-icon">
                    <i className="lni lni-book"></i>
                  </div>
                  <div className="menu-title">Newsletter</div>
                </a>
                <ul>
                  <li>
                    <Link to="/admin/add-newsletter">
                      <i className="bi bi-circle"></i>Add Newsletter
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/manage-newsletter">
                      <i className="bi bi-circle"></i>Manage Newsletter
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/admin/">
                      <i className="bi bi-circle"></i>Add Group
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/admin/send-newsletter">
                      <i className="bi bi-circle"></i>Send Newsletter
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/send-newsletter-kings">
                      <i className="bi bi-circle"></i>Send Newsletter to
                      Kingswere
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <a
                  href="/"
                  className="has-arrow"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="parent-icon">
                    <i className="lni lni-library "></i>
                  </div>
                  <div className="menu-title">App Statistics</div>
                </a>
                <ul>
                  <li>
                    <a
                      href="/"
                      className="has-arrow"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <i className="bi bi-circle"></i>Manage RSA's
                    </a>
                    <ul>
                      <li>
                        <Link to="/admin/manage-user-type/rsa/ios">
                          <i className="bi bi-circle"></i>RSA's iOS
                        </Link>
                      </li>
                      <li>
                        <Link to="/admin/manage-user-type/rsa/android">
                          <i className="bi bi-circle"></i>RSA's Android
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="has-arrow"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <i className="bi bi-circle"></i>Manage Manager's
                    </a>
                    <ul>
                      <li>
                        <Link to="/admin/manage-user-type/manager/ios">
                          <i className="bi bi-circle"></i>Manager's iOS
                        </Link>
                      </li>
                      <li>
                        <Link to="/admin/manage-user-type/manager/android">
                          <i className="bi bi-circle"></i>Manager's Android
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              {/* TODO Work */}
              {/* <li>
                <a
                  href="/"
                  className="has-arrow"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="parent-icon">
                    <i className="lni lni-library "></i>
                  </div>
                  <div className="menu-title">Manage 1099</div>
                </a>
                <ul>
                  <li>
                    <Link to="/admin/upload_cards">
                      <i className="bi bi-circle"></i>Upload Cards
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/balance">
                      <i className="bi bi-circle"></i>Balance
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/sheet_import">
                      <i className="bi bi-circle"></i>Sheet Import
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/sale_comparison">
                      <i className="bi bi-circle"></i>Sale Comparison
                    </Link>
                  </li>
                </ul>
              </li> */}
            </>
          ) : null}

          {uType !== "dos" ? null : (
            <>
              <li>
                <a
                  href="/"
                  className="has-arrow"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="parent-icon">
                    <i className="bi bi-person-lines-fill"></i>
                  </div>
                  <div className="menu-title">MANAGE RSA's</div>
                </a>
                <ul>
                  <li>
                    <Link to="/admin/view-accepted-rsa">
                      <i className="bi bi-circle"></i>View RSA
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  href="/"
                  className="has-arrow"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="parent-icon">
                    <i className="bi bi-person-lines-fill"></i>
                  </div>
                  <div className="menu-title">MANAGE MANAGER's</div>
                </a>
                <ul>
                  <li>
                    <Link to="/admin/view-accepted-manager">
                      <i className="bi bi-circle"></i>View Manager
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <a
                  href="/"
                  className="has-arrow"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="parent-icon">
                    <i className="bi bi-grid-fill"></i>
                  </div>
                  <div className="menu-title">Claims</div>
                </a>
                <ul>
                  <li>
                    <Link to="/admin/dos-latest-claims">
                      <i className="bi bi-circle"></i>Latest Claims
                    </Link>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="has-arrow"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <i className="bi bi-circle"></i>Processed Claims
                    </a>
                    <ul>
                      <li>
                        <Link to="/admin/accepted-claim-dos">
                          <i className="bi bi-circle"></i>Accepted Claims
                        </Link>
                      </li>
                      <li>
                        <Link to="/admin/rejected-claim-dos">
                          <i className="bi bi-circle"></i>Rejected Claims
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </>
          )}
        </ul>
      </aside>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
}
