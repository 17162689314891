import axios from "axios";

const API_URL = process.env.REACT_APP_API_Link;

const getProductList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/product/list", {
    headers: headers,
  });
};

const getProductForSize = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/product/list-product", {
    headers: headers,
  });
};

const getSizeByProdId = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/product/list-size-by-product/" + id, {
    headers: headers,
  });
};

const getProduct = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/claim/list-products/" + id, {
    headers: headers,
  });
};

const getSize = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/claim/list-size-by-product/" + id, {
    headers: headers,
  });
};

const getSizeByUserProduct = (userId, prodId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}user/claim/list-size-by-product-new/${userId}/${prodId}`,
    {
      headers: headers,
    }
  );
};

const getProductForAdmin = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/product/list-admin", {
    headers: headers,
  });
};
const getSizeByAdminProduct = (prodId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}user/claim/list-size-by-product/${prodId}`, {
    headers: headers,
  });
};

const ProductService = {
  getProduct,
  getSize,
  getSizeByUserProduct,
  getProductList,
  getProductForSize,
  getSizeByProdId,
  getProductForAdmin,
  getSizeByAdminProduct,
};

export default ProductService;
