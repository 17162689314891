import React from "react";
import $ from "jquery";

export default function VideoModel() {
  return (
    <div
      id="newvideo"
      className="modal fade"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog wd modal-dialog-centered">
        <div className="modal-content video-app">
          <div className="modal-body">
            <button
              type="button"
              className="close new"
              data-bs-dismiss="modal"
              onClick={() => {
                $("#video1")[0].pause();
              }}
            >
              <img src="images/close.png" alt="" />
            </button>

            <video
              width="100%"
              id="video1"
              poster="/images/app-thumbnail.jpg"
              controls
            >
              <source src="/images/ashley-app.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  );
}
