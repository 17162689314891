import { useFormik } from "formik";
import React, { useLayoutEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import useSortableData from "../../../hooks/useSortableData";
import useTable from "../../../hooks/useTable";
import { quizSearch } from "../../../schema";
import AdminListService from "../../../services/admin-list.service";
import AdminFooter from "../includes/AdminFooter";
import CheckUtype from "../includes/CheckUtype";
import HeaderSidebar from "../includes/HeaderSidebar";
import MoveTable from "../includes/MoveTable";
import ToTop from "../includes/ToTop";
import { Helmet } from "react-helmet";
import $ from "jquery";
export default function SendNewsletterkings() {
  const TITLE = "Ashley Sleep Elite | Send Newsletter";
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [resultData, setResultData] = useState([]);
  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("");
  const [newsletter, setNewsletter] = useState([]);

  var allSelections = "";
  const allSelect = async () => {
    $("input:checkbox").prop("checked", $(".slect_all").prop("checked"));
    var checkedVals = $(".select_one:checkbox:checked")
      .map(function () {
        return this.value;
      })
      .get();

    allSelections = checkedVals.join("+");
    console.log(allSelections);
  };
  useLayoutEffect(() => {
    const getNewsletterData = async () => {
      try {
        const { data } = await AdminListService.getNewsLetterSelectOptions();

        const { response: res } = data;
        const results = [];

        res.map((value) => {
          results.push({
            key: value.id,
            value: value.description,
          });
        });

        setNewsletter([...results]);
      } catch (err) {
        setTotalPages("1");
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getNewsletterData();
  }, []);

  // const changePagination = (e) => {
  //   setCurrentPage(e);

  //   // console.log(e)
  //   let pageNo = e;
  //   // setLimit(newLimit);
  //   console.log(limit);
  //   const getResultData = async () => {
  //     setLoading(true);

  //     try {
  //       const response =
  //         await AdminListService.getPaginationNewsletterKingsList(
  //           pageNo,
  //           limit
  //         );

  //       let resultData;
  //       if (response.status === 206) {
  //         resultData = response.data.response;

  //         setResultData(resultData);
  //         setLimit(response.data.per_page);
  //         setLoading(false);
  //       }
  //     } catch (err) {
  //       if (err.response.status === 404) {
  //         setLoading(false);
  //       } else {
  //         setLoading(false);
  //       }
  //     }
  //   };
  //   getResultData();
  // };

  // const searchData = async (action) => {
  //   setLoading(true);
  //   try {
  //     const response = await AdminListService.getSearchNewsletterKingsList(
  //       values.fieldtype,
  //       values.searchval,
  //       limit
  //     );
  //     let resultData;
  //     if (response.status === 206) {
  //       setLoading(false);
  //       resultData = response.data.response;
  //       setResultData(resultData);
  //       setTotalResults(response.data.records);
  //       setTotalPages(response.data.total_pages);
  //       setLimit(response.data.per_page);
  //     } else {
  //       setTotalResults("0");
  //       setTotalPages("1");
  //       setResultData([]);
  //       setLoading(false);
  //     }
  //   } catch (err) {
  //     setTotalPages("1");
  //     setTotalResults("0");
  //     if (err.response.status === 500) {
  //       setLoading(false);
  //     } else {
  //       setLoading(false);
  //       setResultData([]);
  //     }
  //   }
  // };

  useLayoutEffect(() => {
    const getTableData = async () => {
      setLoading(true);
      try {
        const response = await AdminListService.getNewsletterKingsList();
        let resultData;
        resultData = response.data.response;
        setResultData(resultData);
        setLimit(response.data.per_page);
        setTotalResults(response.data.records);
        setTotalPages(response.data.total_pages);
        setLoading(false);
      } catch (err) {
        setTotalPages("1");
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getTableData();
  }, []);

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table className="table caption-top align-middle table-borderless table-style-1">
          <thead>
            <tr>
            <th
                scope="col"
                onClick={() => requestSort("Select")}
                className={getClassNamesFor("Select")}
              >
                <label htmlFor="Select">
                  <input
                    id="Select"
                    className="form-check-input slect_all"
                    type="checkbox"
                    onChange={allSelect}
                  />{" "}
                </label>
              </th>
              <th
                scope="col"
                onClick={() => requestSort("id")}
                className={getClassNamesFor("id")}
              >
                No
              </th>
              <th
                scope="col"
                onClick={() => requestSort("description")}
                className={getClassNamesFor("description")}
              >
                First Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("description")}
                className={getClassNamesFor("description")}
              >
                Last Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("description")}
                className={getClassNamesFor("description")}
              >
                Email Address
              </th>
              <th
                scope="col"
                onClick={() => requestSort("description")}
                className={getClassNamesFor("description")}
              >
                Store Role
              </th>
              {/* <th
                scope="col"
                onClick={() => requestSort("zip")}
                className={getClassNamesFor("zip")}
              >
                Zip Code
              </th> */}
              {/* <th
                scope="col"
                onClick={() => requestSort("Action")}
                className={getClassNamesFor("Action")}
              >
                Action
              </th> */}
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={el.id}>
                <td>
                    <input
                      className="form-check-input select_one"
                      id={el.salesID}
                      value={el.salesID}
                      type="checkbox"
                      // onChange={changeSelection}
                    />
                  </td>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>
                    {el.first_name > "" ? el.first_name.toUpperCase() : "N/A"}
                  </td>
                  <td>
                    {el.last_name > "" ? el.last_name.toUpperCase() : "N/A"}
                  </td>
                  <td>{el.email > "" ? el.email.toUpperCase() : "N/A"}</td>
                  <td>
                    {el.user_type > "" ? el.user_type.toUpperCase() : "N/A"}
                  </td>
                  {/* <td>
                    <button className="text-orange text-success">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-send-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                      </svg>{" "}
                      SEND NEWSLETTER
                    </button>
                  </td> */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* {totalResults > limit && totalPages > 1 ? (
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={parseInt(limit)}
            totalItemsCount={totalResults}
            onChange={(e) => {
              changePagination(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )} */}
      </>
    );
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,

      initialValues: {
        fieldtype: "",
        searchval: "",
      },
      validationSchema: quizSearch,
      onSubmit: (values, action) => {
        // action.resetForm();
        // searchData(action);
      },
    });
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="semi-dark">
        <div className="wrapper">
          <CheckUtype />

          <HeaderSidebar />
          <main className="page-content">
            <div className="row">
              <div className="col">
                <div className="manage-heading-2">
                  <h2>
                    Send Newsletter <span>[{totalResults}]</span>
                  </h2>
                </div>
                <div className="alert alert-danger">
                  <b>Info!</b> No Group found
                </div>
                <div className="slides-here">
                  <div className="main-content-box">
                    <div className="row">
                      <div className="col-lg-8">
                        <label className="form-label">Choose Newsletter</label>
                        <select
                          className="form-control form-select "
                          name="newsletter"
                          required=""
                        >
                          <option value="">Choose Newsletter</option>
                          {newsletter.map((res) => {
                            return (
                              <option key={res.key} value={res.value}>
                                {res.value}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-lg-4">
                        <Link
                          type="submit"
                          className="btn btn-primary width-100 px-4 back-orange mt-30"
                        >
                          <i className="bi bi-check2"></i> Send Selected
                          Individuals
                        </Link>
                      </div>
                      <div className="col-lg-12 mt-3">
                        <h2 className="manage-territories-heading">
                          Individual List
                        </h2>
                      </div>
                      <div className="table-responsive">
                        <Table data={resultData} rowsPerPage={limit} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <ToTop />
          <div className={`loader ${loading ? "in" : ""}`}>
            <div className="spinner-border main-spin"></div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
}
