import React, { useLayoutEffect, useState } from "react";
import AdminFooter from "../includes/AdminFooter";
import HeaderSidebar from "../includes/HeaderSidebar";
import MoveTable from "../includes/MoveTable";
import ToTop from "../includes/ToTop";
import $ from "jquery";
import AdminListService from "../../../services/admin-list.service";
import StoreService from "../../../services/store.service";
import useTable from "../../../hooks/useTable";
import useSortableData from "../../../hooks/useSortableData";
import Pagination from "react-js-pagination";
import CheckUtype from "../includes/CheckUtype";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { Helmet } from "react-helmet";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

export default function ManageTerritory() {
  const state = useSelector((state) => state.stateVals);
  const { id: adminId } = state;

  const TITLE = "Ashley Sleep Elite | Manage Territory";
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState("");
  const [resultData, setResultData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { height, width } = useWindowDimensions();
  const [totalResults, setTotalResults] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [ndos, setNdos] = useState([]);

  const [dos, setDos] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [accountData, setAccountData] = useState([]);
  const [zipData, setZipData] = useState([]);
  const [storeNameData, setStoreNameData] = useState([]);
  const [storeAddressData, setStoreAddressData] = useState([]);

  const resetOptions = (e) => {
    $("#store_name").prop("selectedIndex", 0);
    $("#store_state").prop("selectedIndex", 0);
    $("#store_city").prop("selectedIndex", 0);
    $("#city_zip").prop("selectedIndex", 0);
    $("#store_account").prop("selectedIndex", 0);
    $("#store_dos").prop("selectedIndex", 0);
    $("#store_ndos").prop("selectedIndex", 0);
    $("#store_visacard").prop("selectedIndex", 0);
  };

  const changeLimit = (e) => {
    let newLimit = e;
    // console.log(limit);
    const getResultData = async () => {
      try {
        setLimit(newLimit);
        setLoading(true);

        var st_name = $("#store_name").val();
        var st_address = $("#store_address").val();
        var st_state = $("#store_state").val();
        var st_city = $("#store_city").val();
        var c_zip = $("#city_zip").val();
        var st_acc = $("#store_account").val();
        var st_dos = $("#store_dos").val();
        var st_ndos = $("#store_ndos").val();
        var st_card = $("#store_visacard").val();

        const response = await AdminListService.getSearchTerritoriesList(
          newLimit,
          st_name,
          st_address,
          st_state,
          st_city,
          c_zip,
          st_acc,
          st_dos,
          st_ndos,
          st_card,
          1
        );

        let resultData;
        if (response.status === 206) {
          setLoading(false);
          resultData = response.data.response;
          setResultData(resultData);
          setTotalResults(response.data.records);
          setCurrentPage(1);
          setTotalPages(response.data.total_pages);
          setLimit(response.data.per_page);
        } else {
          setTotalResults("0");
          setTotalPages("1");
          setResultData([]);
          setLoading(false);
        }
      } catch (err) {
        setTotalResults("0");
        setTotalPages("1");
        setResultData([]);
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getResultData();
  };

  const changePagination = (e) => {
    setCurrentPage(e);

    // console.log(e)
    let pageNo = e;
    // setLimit(newLimit);
    // console.log(limit);
    const getResultData = async () => {
      setLoading(true);
      var st_name = $("#store_name").val();

      var st_address = $("#store_address").val();
      var st_state = $("#store_state").val();
      var st_city = $("#store_city").val();
      var c_zip = $("#city_zip").val();
      var st_acc = $("#store_account").val();
      var st_dos = $("#store_dos").val();
      var st_ndos = $("#store_ndos").val();
      var st_card = $("#store_visacard").val();

      try {
        const response = await AdminListService.getSearchTerritoriesList(
          limit,
          st_name,
          st_address,
          st_state,
          st_city,
          c_zip,
          st_acc,
          st_dos,
          st_ndos,
          st_card,
          pageNo
        );

        let resultData;
        if (response.status === 206) {
          resultData = response.data.response;

          setResultData(resultData);
          setLimit(response.data.per_page);
          setTotalResults(response.data.records);
          setTotalPages(response.data.total_pages);
          setLoading(false);
        }
      } catch (err) {
        setTotalPages("1");
        setResultData([]);
        setTotalResults(0);
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    getResultData();
  };

  const searchMethod = async () => {
    setLoading(true);

    var st_name = $("#store_name").val();

    var st_address = $("#store_address").val();
    var st_state = $("#store_state").val();
    var st_city = $("#store_city").val();
    var c_zip = $("#city_zip").val();
    var st_acc = $("#store_account").val();
    var st_dos = $("#store_dos").val();
    var st_ndos = $("#store_ndos").val();
    var st_card = $("#store_visacard").val();

    try {
      const response = await AdminListService.getSearchTerritoriesList(
        limit,
        st_name,
        st_address,
        st_state,
        st_city,
        c_zip,
        st_acc,
        st_dos,
        st_ndos,
        st_card,
        1
      );

      let resultData;
      if (response.status === 206) {
        resultData = response.data.response;

        setResultData(resultData);
        setLimit(response.data.per_page);
        setTotalResults(response.data.records);
        setTotalPages(response.data.total_pages);
        setCurrentPage(1);
        setLoading(false);
      }
    } catch (err) {
      setTotalPages("1");
      setResultData([]);
      setTotalResults(0);

      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const activeData = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to activate this store?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00a9e0",
      cancelButtonColor: "#e0876c",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);

        try {
          await AdminListService.activeTerritoriesData(adminId, delId);
          setLoading(false);
          toast.success("Activated Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          resetOptions();
          getResultData();
        } catch (err) {
          toast.error("Something went wrong!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }
      }
    });
  };

  const deactiveData = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to deactive this store?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00a9e0",
      cancelButtonColor: "#e0876c",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);

        try {
          await AdminListService.deactiveTerritoriesData(adminId, delId);
          setLoading(false);
          toast.success("Deactive Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          resetOptions();
          getResultData();
        } catch (err) {
          toast.error("Something went wrong!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }
      }
    });
  };

  const getResultData = async () => {
    setLoading(true);

    try {
      const response = await AdminListService.getTerritoriesList();

      let resultData;
      if (response.status === 206) {
        resultData = response.data.response;
        setResultData(resultData);
        setLimit(response.data.per_page);
        setTotalResults(response.data.records);
        setTotalPages(response.data.total_pages);
        setLoading(false);
      }
    } catch (err) {
      setTotalPages("1");
      if (err.response.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  useLayoutEffect(() => {
    const getCityData = async () => {
      setLoading(true);

      try {
        const { data } = await AdminListService.getCitySelectOptions();

        const { response: res } = data;
        const results = [];

        res.map((value) => {
          results.push({
            key: value.name,
            value: value.name,
          });
        });

        setCityData([{ key: "SELECT CITY", value: "" }, ...results]);

        setLoading(false);
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };

    const getAccountData = async () => {
      setLoading(true);

      try {
        const { data } = await AdminListService.getAccountSelectOptions();

        const { response: res } = data;
        const results = [];

        res.map((value) => {
          results.push({
            key: value.account,
            value: value.account,
          });
        });

        setAccountData([{ key: "SELECT ACCOUNT", value: "" }, ...results]);

        setLoading(false);
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    const getZipData = async () => {
      setLoading(true);

      try {
        const { data } = await AdminListService.getZipSelectOptions();

        const { response: res } = data;
        const results = [];

        res.map((value) => {
          results.push({
            key: value.zip,
            value: value.zip,
          });
        });

        setZipData([{ key: "SELECT ZIP", value: "" }, ...results]);

        setLoading(false);
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };

    const getStoreNameData = async () => {
      setLoading(true);

      try {
        const { data } = await AdminListService.getStoreNameSelectOptions();

        const { response: res } = data;
        const results = [];

        res.map((value) => {
          results.push({
            key: value.name,
            value: value.name,
          });
        });

        setStoreNameData([{ key: "SELECT STORE NAME", value: "" }, ...results]);

        setLoading(false);
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };
    const getStoreAddressData = async () => {
      setLoading(true);

      try {
        const { data } = await AdminListService.getStoreAddressSelectOptions();

        const { response: res } = data;
        const results = [];

        res.map((value) => {
          results.push({
            key: value.address,
            value: value.address,
          });
        });

        setStoreAddressData([
          { key: "SELECT STORE ADDRESS", value: "" },
          ...results,
        ]);

        setLoading(false);
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };

    const getStateData = async () => {
      try {
        const { data } = await StoreService.getStoreState();

        const { response: res } = data;
        const results = [];

        res.map((value) => {
          results.push({
            key: value.name,
            value: value.id,
          });
        });

        setStateData([{ key: "SELECT STATE", value: "" }, ...results]);
      } catch (err) {
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };

    const getNdosData = async () => {
      try {
        const { data } = await AdminListService.getNdosSelectOptions();

        const { response: res } = data;
        const results = [];

        res.map((value) => {
          results.push({
            key: value.first_name + " " + value.last_name,
            value: value.id,
          });
        });

        setNdos([{ key: "SELECT VP", value: "" }, ...results]);
      } catch (err) {
        setTotalPages("1");
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };

    const getDosData = async () => {
      try {
        const { data } = await AdminListService.getDosSelectOptions();

        const { response: res } = data;
        const results = [];

        res.map((value) => {
          results.push({
            key: value.first_name + " " + value.last_name,
            value: value.id,
          });
        });

        setDos([{ key: "SELECT DOS", value: "" }, ...results]);
      } catch (err) {
        setTotalPages("1");
        if (err.response.status === 404) {
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };

    getResultData();
    getDosData();
    getNdosData();
    getStateData();
    getCityData();
    getAccountData();
    getZipData();
    getStoreNameData();
    getStoreAddressData();
  }, []);

  // const Table = ({ data, rowsPerPage }) => {
  //   const [page, setPage] = useState(1);
  //   const { slice, range } = useTable(data, page, rowsPerPage);
  //   const { items, requestSort, sortConfig } = useSortableData(slice);

  //   const getClassNamesFor = (name) => {
  //     if (!sortConfig) {
  //       return;
  //     }
  //     return sortConfig.key === name ? sortConfig.direction : undefined;
  //   };

  //   return (
  //     <>
  //       <table className="table caption-top align-middle table-borderless table-style-1">
  //         <thead>
  //           <tr>
  //             <th
  //               scope="col"
  //               onClick={() => requestSort("id")}
  //               className={getClassNamesFor("id")}
  //             >
  //               No
  //             </th>
  //             <th
  //               style={{ textAlign: "center" }}
  //               scope="col"
  //               onClick={() => requestSort("information")}
  //               className={getClassNamesFor("information")}
  //             >
  //               Infomation
  //             </th>

  //             <th
  //               scope="col"
  //               onClick={() => requestSort("status")}
  //               className={getClassNamesFor("status")}
  //             >
  //               Status
  //             </th>
  //             <th
  //               scope="col"
  //               onClick={() => requestSort("action")}
  //               className={getClassNamesFor("action")}
  //             >
  //               Action
  //             </th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {items.length ? (
  //             items.map((el, index) => (
  //               <tr key={el.id}>
  //                 <td>
  //                   {currentPage === 1
  //                     ? index + 1
  //                     : (currentPage - 1) * limit + index + 1}
  //                 </td>
  //                 <td>
  //                   <div className="row">
  //                     <div className="col-xl-6">
  //                       <div className="claim-details-tab">
  //                         <h5 className="claim-detail-heading">Store Info</h5>
  //                         <ul className="rob-rollmann">
  //                           <li>
  //                             <b>Store Name: </b>
  //                             {el.name}
  //                           </li>
  //                           <li>
  //                             <b>Store Address: </b>
  //                             {el.address}
  //                           </li>
  //                           <li>
  //                             <b>Store State: </b>
  //                             {el.store_state}
  //                           </li>
  //                           <li>
  //                             <b>Store City: </b>
  //                             {el.store_city}
  //                           </li>
  //                           <li>
  //                             <b>Zip: </b>
  //                             {el.zip}
  //                           </li>
  //                           <li>
  //                             <b>Account: </b>
  //                             {el.account}
  //                           </li>
  //                         </ul>
  //                       </div>
  //                     </div>

  //                     <div className="col-xl-6 mt-4">
  //                       <div className="claim-details-tab">
  //                         <ul className="rob-rollmann">
  //                           <li>
  //                             <b>DOS: </b>
  //                             {el.dos_name.length > 0
  //                               ? el.dos_name.toUpperCase()
  //                               : "N/A"}
  //                           </li>
  //                           <li>
  //                             <b>VP: </b>
  //                             {el.ndos_name.trim().length > 0
  //                               ? el.ndos_name.toUpperCase()
  //                               : "N/A"}
  //                           </li>
  //                           <li>
  //                             <b>Visa Card: </b>
  //                             {el.visaCard}
  //                           </li>
  //                         </ul>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </td>

  //                 <td>
  //                   <span
  //                     style={{
  //                       color:
  //                         el.status === "active"
  //                           ? "#00a9e0"
  //                           : el.status === "inactive"
  //                           ? "#e0876c"
  //                           : "",
  //                       textTransform: "uppercase",
  //                       fontWeight: "bold",
  //                     }}
  //                   >
  //                     {el.status}
  //                   </span>
  //                 </td>
  //                 <td className="add-edit-delete-inline">
  //                   {el.status == "inactive" ? (
  //                     <button
  //                       className="btn btn-primary px-4 d-block back-blue"
  //                       onClick={() => activeData(el.id)}
  //                     >
  //                       Activate <i class="bi bi-person-check-fill"></i>
  //                     </button>
  //                   ) : (
  //                     <button
  //                       className="btn btn-outline-secondary px-4 d-block"
  //                       onClick={() => deactiveData(el.id)}
  //                     >
  //                       Deactive <i class="bi bi-person-x-fill"></i>
  //                     </button>
  //                   )}
  //                 </td>
  //               </tr>
  //             ))
  //           ) : (
  //             <tr>
  //               <td colSpan="10" className="text-center text-capitalize">
  //                 No record found
  //               </td>
  //             </tr>
  //           )}
  //         </tbody>
  //       </table>
  //       {/* {totalResults > limit && totalPages > 1 ? (
  //         <Pagination
  //           activePage={currentPage}
  //           itemsCountPerPage={parseInt(limit)}
  //           totalItemsCount={totalResults}
  //           onChange={(e) => {
  //             changePagination(e);
  //           }}
  //           pageRangeDisplayed={8}
  //           itemClass="page-item"
  //           linkClass="page-link"
  //           firstPageText="First Page"
  //           lastPageText="Last Page"
  //         />
  //       ) : (
  //         ""
  //       )} */}
  //     </>
  //   );
  // };

  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice, range } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);

    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <>
        <table className=" caption-top align-middle table-borderless table-style-1">
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("id")}
                className={getClassNamesFor("id")}
              >
                No
              </th>
              <th
                scope="col"
                onClick={() => requestSort("name")}
                className={getClassNamesFor("name")}
              >
                Store Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("address")}
                className={getClassNamesFor("address")}
              >
                Store Address
              </th>
              <th
                scope="col"
                onClick={() => requestSort("store_state")}
                className={getClassNamesFor("store_state")}
              >
                Store State
              </th>
              <th
                scope="col"
                onClick={() => requestSort("store_city")}
                className={getClassNamesFor("store_city")}
              >
                Store City
              </th>
              <th
                scope="col"
                onClick={() => requestSort("zip")}
                className={getClassNamesFor("zip")}
              >
                Zip
              </th>
              <th
                scope="col"
                onClick={() => requestSort("account")}
                className={getClassNamesFor("account")}
              >
                Account
              </th>
              <th
                scope="col"
                onClick={() => requestSort("dos_name")}
                className={getClassNamesFor("dos_name")}
              >
                DOS
              </th>
              <th
                scope="col"
                onClick={() => requestSort("ndos_name")}
                className={getClassNamesFor("ndos_name")}
              >
                VP
              </th>
              <th
                scope="col"
                onClick={() => requestSort("visa_card")}
                className={getClassNamesFor("visa_card")}
              >
                Visa Card
              </th>
              <th
                scope="col"
                onClick={() => requestSort("status")}
                className={getClassNamesFor("status")}
              >
                Status
              </th>
              <th
                scope="col"
                onClick={() => requestSort("action")}
                className={getClassNamesFor("action")}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={el.id}>
                  <td>
                    {currentPage === 1
                      ? index + 1
                      : (currentPage - 1) * limit + index + 1}
                  </td>
                  <td>{el.name}</td>
                  <td>{el.address}</td>
                  <td>{el.store_state}</td>
                  <td>{el.store_city}</td>
                  <td>{el.zip}</td>

                  <td>{el.account}</td>
                  {/* <td>{el.dos_name}</td> */}
                  <td>
                    {el.dos_name.length > 0 ? el.dos_name.toUpperCase() : "N/A"}
                  </td>
                  <td>{el.ndos_name}</td>
                  <td style={{ textTransform: "capitalize" }}>{el.visaCard}</td>
                  {/* <td>{el.status}</td> */}

                  <td>
                    <span
                      style={{
                        color:
                          el.status === "active"
                            ? "#00a9e0"
                            : el.status === "inactive"
                            ? "#e0876c"
                            : "",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}
                    >
                      {el.status}
                    </span>
                  </td>
                  <td className="add-edit-delete-inline">
                    {el.status == "inactive" ? (
                      <button
                        className="btn btn-primary px-2 d-block back-blue"
                        onClick={() => activeData(el.id)}
                      >
                        Activate <i class="bi bi-person-check-fill"></i>
                      </button>
                    ) : (
                      <button
                        className="btn btn-outline-secondary px-2 d-block"
                        onClick={() => deactiveData(el.id)}
                      >
                        Deactive <i class="bi bi-person-x-fill"></i>
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="10" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="semi-dark">
        <div className="wrapper">
          <ToastContainer />
          <CheckUtype />
          <HeaderSidebar />
          <main className="page-content">
            <div className="row">
              <div className="col">
                <div className="manage-heading-2">
                  <h2>
                    Manage Territories <span>[{totalResults}]</span>
                  </h2>
                </div>

                <div className="slides-here">
                  <div className="alert alert-info">
                    <strong>Info!</strong> You can search your required data by
                    putting text in search box
                  </div>
                  <div className="main-content-box rzl-tabl">
                    <div className="manage-territories-box mb-30">
                      <div className="row">
                        <div className="col-lg-12">
                          <h2 className="manage-territories-heading">
                            Manage Territories
                          </h2>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group custom-group">
                            <label className="form-label">
                              Items Per Page:
                            </label>
                            <select
                              className="form-select"
                              value={limit}
                              onChange={(e) => changeLimit(e.target.value)}
                            >
                              <option>10</option>
                              <option>50</option>
                              <option>100</option>
                              <option>200</option>
                              <option>300</option>
                              <option>400</option>
                              <option>500</option>
                              <option>600</option>
                              <option>700</option>
                              <option>800</option>
                              <option>900</option>
                              <option>1000</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group custom-group mb-0">
                            <label className="form-label">
                              Show Territory From:
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div
                            className={`form-group custom-group ${
                              ndos.length ? "" : "placeholder-glow"
                            }`}
                          >
                            <select
                              className={`form-select ${
                                ndos.length ? "" : "placeholder"
                              }`}
                              id="store_ndos"
                              onChange={searchMethod}
                            >
                              {ndos.map((res) => {
                                return (
                                  <option key={res.value} value={res.value}>
                                    {res.key}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div
                            className={`form-group custom-group ${
                              dos.length ? "" : "placeholder-glow"
                            }`}
                          >
                            <select
                              className={`form-select ${
                                dos.length ? "" : "placeholder"
                              }`}
                              id="store_dos"
                              onChange={searchMethod}
                            >
                              {dos.map((res) => {
                                return (
                                  <option key={res.value} value={res.value}>
                                    {res.key}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div
                            className={`form-group custom-group ${
                              accountData.length ? "" : "placeholder-glow"
                            }`}
                          >
                            <select
                              className={`form-select ${
                                accountData.length ? "" : "placeholder"
                              }`}
                              id="store_account"
                              onChange={searchMethod}
                            >
                              {accountData.map((res) => {
                                return (
                                  <option key={res.value} value={res.value}>
                                    {res.key}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div
                            className={`form-group custom-group ${
                              stateData.length ? "" : "placeholder-glow"
                            }`}
                          >
                            <select
                              id="store_state"
                              className={`store_state form-select ${
                                stateData.length ? "" : "placeholder"
                              }`}
                              onChange={searchMethod}
                            >
                              {stateData.map((res) => {
                                return (
                                  <option key={res.value} value={res.value}>
                                    {res.key}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div
                            className={`form-group custom-group ${
                              cityData.length ? "" : "placeholder-glow"
                            }`}
                          >
                            <select
                              className={`form-select ${
                                cityData.length ? "" : "placeholder"
                              }`}
                              id="store_city"
                              onChange={searchMethod}
                            >
                              {cityData.map((res) => {
                                return (
                                  <option key={res.value} value={res.value}>
                                    {res.key}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div
                            className={`form-group custom-group ${
                              zipData.length ? "" : "placeholder-glow"
                            }`}
                          >
                            <select
                              className={`form-select ${
                                zipData.length ? "" : "placeholder"
                              }`}
                              id="city_zip"
                              onChange={searchMethod}
                            >
                              {zipData.map((res) => {
                                return (
                                  <option key={res.value} value={res.value}>
                                    {res.key}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div
                            className={`form-group custom-group ${
                              storeNameData.length ? "" : "placeholder-glow"
                            }`}
                          >
                            <select
                              className={`form-select ${
                                storeNameData.length ? "" : "placeholder"
                              }`}
                              id="store_name"
                              onChange={searchMethod}
                            >
                              {storeNameData.map((res) => {
                                return (
                                  <option key={res.value} value={res.value}>
                                    {res.key}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div
                            className={`form-group custom-group ${
                              storeAddressData.length ? "" : "placeholder-glow"
                            }`}
                          >
                            <select
                              id="store_address"
                              className={`store_address form-select ${
                                storeAddressData.length ? "" : "placeholder"
                              }`}
                              onChange={searchMethod}
                            >
                              {storeAddressData.map((res) => {
                                return (
                                  <option key={res.value} value={res.value}>
                                    {res.key}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-group custom-group">
                            <select
                              id="store_visacard"
                              className="form-select"
                              onChange={searchMethod}
                            >
                              <option value="">SELECT VISA CARD</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-4 text-end">
                          <button
                            className="btn btn-outline-secondary w-100 d-block"
                            type="reset"
                            onClick={() => window.location.reload(false)}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <MoveTable /> */}
                    <div className="claim-table">
                      <Table data={resultData} rowsPerPage={limit} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="pagi">
                  {totalResults > limit && totalPages > 1 ? (
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={parseInt(limit)}
                      totalItemsCount={totalResults}
                      onChange={(e) => {
                        changePagination(e);
                      }}
                      pageRangeDisplayed={
                        width >= 1024
                          ? 8
                          : width >= 768
                          ? 6
                          : width >= 425
                          ? 3
                          : width >= 375
                          ? 2
                          : 1
                      }
                      itemClass="page-item"
                      linkClass="page-link"
                      firstPageText="First Page"
                      lastPageText="Last Page"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </main>
          <ToTop />
          <div className={`loader ${loading ? "in" : ""}`}>
            <div className="spinner-border main-spin"></div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
}
