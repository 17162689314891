import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const API_URL = process.env.REACT_APP_API_Link;

const login = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/login-admin",
    {
      username: values.name,
      password: values.password,
    },
    {
      headers: headers,
    }
  );
};
const login1099 = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/login-1099",
    {
      username: values.name,
      password: values.password,
    },
    {
      headers: headers,
    }
  );
};
const forgotPass = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/forgot-admin",
    {
      email: values.email,
    },
    {
      headers: headers,
    }
  );
};

const getStoreAddressSelectOptions = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/territory/listStoreAddress", {
    headers: headers,
  });
};

const getNdosSelectOptions = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-ndos", {
    headers: headers,
  });
};
const getNewsLetterSelectOptions = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/newsletter/list-template", {
    headers: headers,
  });
};

const getDosSelectOptions = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/dos", {
    headers: headers,
  });
};

const getZipSelectOptions = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/territory/listZip", {
    headers: headers,
  });
};
const getAccountSelectOptions = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/territory/listAccounts", {
    headers: headers,
  });
};

const getCitySelectOptions = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/city/list", {
    headers: headers,
  });
};
const getTerritoriesList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/territory/listAdmin", {
    headers: headers,
  });
};

const getTerritoriesDosList = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}store/territory/listDos/${id}`, {
    headers: headers,
  });
};
const getSearchTerritoriesList = (
  limit,
  st_name,
  st_address,
  st_state,
  st_city,
  c_zip,
  st_acc,
  st_dos,
  st_ndos,
  st_card,
  pageNo
) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "store/territory/listAdmin?limit=" +
      limit +
      "&title=" +
      st_name +
      "&page=" +
      pageNo +
      "&store_address=" +
      st_address.replaceAll("#", "%23") +
      "&zip=" +
      c_zip +
      "&state=" +
      st_state +
      "&account=" +
      st_acc +
      "&dos=" +
      st_dos +
      "&ndos=" +
      st_ndos +
      "&city=" +
      st_city +
      "&visa_card=" +
      st_card,
    {
      headers: headers,
    }
  );
};
const getSearchRsaStoreList = (limit, st_name, col, val, pageNo) => {
  if (col === "1") {
    col = "emp_number";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "username";
  } else if (col === "5") {
    col = "email";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "state";
  } else if (col === "8") {
    col = "city";
  } else if (col === "9") {
    col = "zip";
  } else if (col === "10") {
    col = "created_at";
  } else if (col === "11") {
    col = "store";
  } else if (col === "12") {
    col = "store_address";
  } else if (col === "13") {
    col = "store_city";
  } else if (col === "14") {
    col = "store_zip";
  } else if (col === "15") {
    col = "store_account";
  }

  const headers = {
    "Content-Type": "application/json",
  };
  let query = `${API_URL}user/list-rsa?limit=${limit}&store=${st_name}&page=${pageNo}`;
  if (col && val) {
    query = `${API_URL}user/list-rsa?${col}=${val}&limit=${limit}&store=${st_name}&page=${pageNo}`;
  }
  return axios.get(query, {
    headers: headers,
  });
};

const getSearchTerritoriesDosList = (id, col, val, limit, pageNo) => {
  const headers = {
    "Content-Type": "application/json",
  };

  if (col === "1") {
    col = "title";
  } else if (col === "2") {
    col = "store_address";
  } else if (col === "3") {
    col = "state";
  } else if (col === "4") {
    col = "city";
  } else if (col === "5") {
    col = "zip";
  } else if (col === "6") {
    col = "account";
  } else if (col === "7") {
    col = "dos";
  } else if (col === "8") {
    col = "visa_card";
  }

  let query = ``;
  if (col && val) {
    query = `${API_URL}store/territory/listDos/${id}?${col}=${val}&limit=${limit}&page=${pageNo}`;
  } else {
    query = `${API_URL}store/territory/listDos/${id}?limit=${limit}&page=${pageNo}`;
  }

  return axios.get(query, {
    headers: headers,
  });
};
const puttStateList = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "store/state/add",
    {
      name: values.stateName,
      created_by: 1,
      created_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const getNewLimitStateList = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/state/listAdmin" + "?limit=" + limit, {
    headers: headers,
  });
};

const getSearchStateList = (col, val, limit) => {
  if (col === "1") {
    col = "id";
  } else if (col === "2") {
    col = "title";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "store/state/listAdmin" +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

const getPaginationStateList = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "store/state/listAdmin" + "?page=" + pageNo + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};
const getStateList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/state/listAdmin", {
    headers: headers,
  });
};

const getNewLimitUserStateList = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/state/listAdmin" + "?limit=" + limit, {
    headers: headers,
  });
};

const getSearchUserStateList = (col, val, limit) => {
  if (col === "1") {
    col = "id";
  } else if (col === "2") {
    col = "title";
  } else if (col === "3") {
    col = "code";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/state/listAdmin" +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};

const getPaginationUserStateList = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/state/listAdmin" + "?page=" + pageNo + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};
const getUserStateList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/state/listAdmin", {
    headers: headers,
  });
};

const addCity = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "store/city/add",
    {
      name: values.cityName,
      zip: values.zipCode,
      state_id: values.stateName,
      created_by: 1,
      created_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const addState = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "store/state/add",
    {
      name: values.stateName,
      created_by: values.id,
      created_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};
const addUserState = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/state/add",
    {
      name: values.stateName,
      code: values.code,
      created_by: values.id,
      created_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const addUserCity = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/city/add",
    {
      city: values.cityName,
      zip: values.zipCode,
      state_id: values.stateName,
      created_by: 1,
      created_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const addProduct = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/product/add",
    {
      name: values.productName,
      number: values.productNumber,
      created_by: values.id,
      created_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const addProductSize = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "user/product/addSize",
    {
      prod_id: values.productName,
      size: values.productSize,
      price: values.productPrice,
      code: values.code,
      created_by: values.id,
      created_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const getNewLimitCityList = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/city/listAdmin" + "?limit=" + limit, {
    headers: headers,
  });
};

const getSearchCityList = (col, val, limit) => {
  if (col === "1") {
    col = "title";
  } else if (col === "2") {
    col = "zip";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "store/city/listAdmin" +
      "?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit,
    {
      headers: headers,
    }
  );
};
const getPaginationCityList = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "store/city/listAdmin" + "?page=" + pageNo + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};

const getCityList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/city/listAdmin", {
    headers: headers,
  });
};

const addStore = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "store/add",
    {
      name: values.storeName,
      address: values.storeAddress,
      zip: values.zipCode,
      account: values.storeAccount,
      dos: values.dos,
      ndos: values.ndos,
      city_id: values.city,
      created_by: 1,
      created_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const getNewLimitStoreList = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/listAdmin" + "?limit=" + limit, {
    headers: headers,
  });
};

const getReportHistoryLimit = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return axios.get(
    `${API_URL}user/reports/list-report-history?limit=${limit}`,
    {
      headers: headers,
    }
  );
};

const getNewLimitProductList = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}user/product/list?limit=${limit}`, {
    headers: headers,
  });
};
const getSearchStoreList = (col, val, limit) => {
  if (col === "1") {
    col = "title";
  } else if (col === "2") {
    col = "address";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "store/listAdmin" + "?" + col + "=" + val + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};

const getSearchReportHistory = (col, val, limit) => {
  if (col === "1") {
    col = "title";
  } else if (col === "2") {
    col = "created_at";
  }

  const headers = {
    "Content-Type": "application/json",
  };

  return axios.get(
    `${API_URL}user/reports/list-report-history?${col}=${val}&limit=${limit}`,
    {
      headers: headers,
    }
  );
};
const getSearchProductList = (col, val, limit) => {
  if (col === "1") {
    col = "title";
  } else if (col === "2") {
    col = "size";
  } else if (col === "3") {
    col = "code";
  } else if (col === "4") {
    col = "price";
  } else if (col === "5") {
    col = "store_status";
  }
  const headers = {
    "Content-Type": "application/json",
  };

  return axios.get(`${API_URL}user/product/list?${col}=${val}&limit=${limit}`, {
    headers: headers,
  });
};
const getUserByDeviceSearchLimit = (
  userType,
  userDevice,
  col,
  val,
  limit,
  pageNo
) => {
  const headers = {
    "Content-Type": "application/json",
  };

  if (col === "1") {
    col = "emp_number";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "username";
  } else if (col === "5") {
    col = "email";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "state";
  } else if (col === "8") {
    col = "city";
  } else if (col === "9") {
    col = "zip";
  } else if (col === "10") {
    col = "created_at";
  } else if (col === "11") {
    col = "store";
  } else if (col === "12") {
    col = "store_address";
  } else if (col === "13") {
    col = "store_city";
  } else if (col === "14") {
    col = "store_zip";
  }

  let query = `${API_URL}user/stats/list-user/${userType}/${userDevice}?page=${pageNo}&limit=${limit}`;
  if (col && val) {
    query += `&${col}=${val}`;
  }
  return axios.get(query, {
    headers: headers,
  });
};

const getPaginationStoreList = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "store/listAdmin" + "?page=" + pageNo + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};

const getPaginatedReportHistory = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}user/reports/list-report-history?page=${pageNo}&limit=${limit}`,
    {
      headers: headers,
    }
  );
};

const getPaginationProductList = (col, val, pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  if (col == 1) {
    col = "title";
  } else if (col == 2) {
    col = "size";
  } else if (col == 3) {
    col = "code";
  } else if (col == 4) {
    col = "price";
  }
  let query = `${API_URL}user/product/list?page=${pageNo}&limit=${limit}`;

  if (col && val) {
    query = `${API_URL}user/product/list?${col}=${val}&page=${pageNo}&limit=${limit}`;
  }
  return axios.get(query, {
    headers: headers,
  });
};
const getStoreList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/listAdmin", {
    headers: headers,
  });
};

const getReportHistoryList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/reports/list-report-history", {
    headers: headers,
  });
};

const getProductList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/product/list", {
    headers: headers,
  });
};
const getUserBalanceList = (type, col, val, sales, tina, start, end) => {
  console.log(
    `type=${type}, col=${col}, val=${val}, start=${start}, end=${end}`
  );
  const headers = {
    "Content-Type": "application/json",
  };

  if (col === "1") {
    col = "udf2";
  } else if (col === "2") {
    col = "username";
  } else if (col === "3") {
    col = "email";
  } else if (col === "4") {
    col = "db_sale";
  } else if (col === "5") {
    col = "tina_sale";
  }

  if (start == "") {
    start = "0000-00-00";
  }

  if (end == "") {
    end = "0000-00-00";
  }

  let query = `${API_URL}user/1099/list-${type}-balance/${sales}/${tina}/${start}/${end}`;
  // if (col && val) {
  //   query = `${API_URL}user/1099/list-${type}-balance/${sales}/${tina}/${start}/${end}?${col}=${val}`;
  // } else {
  //   query = `${API_URL}user/1099/list-${type}-balance/${sales}/${tina}/${start}/${end}`;
  // }

  return axios.get(query, {
    headers: headers,
  });
};
const getUserComparisonList = (
  col,
  val,
  sales,
  tina,
  start,
  end,
  limit,
  pageNo
) => {
  console.log(
    `col=${col}, val=${val}, start=${start}, end=${end}, limit=${limit}, pageNo=${pageNo}`
  );
  const headers = {
    "Content-Type": "application/json",
  };

  if (col === "1") {
    col = "udf2";
  } else if (col === "2") {
    col = "username";
  } else if (col === "3") {
    col = "email";
  } else if (col === "4") {
    col = "db_sale";
  } else if (col === "5") {
    col = "tina_sale";
  }

  if (start == "") {
    start = "0000-00-00";
  }

  if (end == "") {
    end = "0000-00-00";
  }

  // let query = `${API_URL}user/1099/list-sale-comparison/${sales}/${tina}/${start}/${end}?${col}=${val}`;
  let query = `${API_URL}user/1099/list-sale-comparison/${sales}/${tina}/${start}/${end}`;
  // if (col && val) {
  // } else {
  //   query = `${API_URL}user/1099/list-sale-comparison/${sales}/${tina}/${start}/${end}?limit=${limit}&page=${pageNo}`;
  // }

  return axios.get(query, {
    headers: headers,
  });
};
const getStateNameById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/state/get-name-by-id/" + id, {
    headers: headers,
  });
};

const updateStateNameById = (id, values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "store/state/update/" + id,
    {
      name: values.stateName,
      updated_by: 1,
      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const getStateNameByIdUser = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/state/get-name-by-id/" + id, {
    headers: headers,
  });
};

const updateStateNameByIdUser = (id, values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "user/state/update/" + id,
    {
      name: values.stateName,
      code: values.code,
      updated_by: 1,
      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const getNewLimitCityListUser = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/city/listAdmin" + "?limit=" + limit, {
    headers: headers,
  });
};

const getSearchCityListUser = (col, val, limit) => {
  if (col === "1") {
    col = "title";
  } else if (col === "2") {
    col = "zip";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/city/listAdmin" + "?" + col + "=" + val + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};
const getPaginationCityListUser = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/city/listAdmin" + "?page=" + pageNo + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};

const getCityListUser = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/city/listAdmin", {
    headers: headers,
  });
};

const getCityInfoByIdUser = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/city/get-info-by-id/" + id, {
    headers: headers,
  });
};

const getCityInfoById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/city/get-info-by-id/" + id, {
    headers: headers,
  });
};

const getProductInfoById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}user/product/list-size-by-product/${id}`, {
    headers: headers,
  });
};

const getProductPriceById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}user/product/get-price-by-product/${id}`, {
    headers: headers,
  });
};
const getProductPriceBySizeId = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}user/product/get-price-by-size/${id}`, {
    headers: headers,
  });
};

const updateCityList = (id, values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "store/city/update/" + id,
    {
      name: values.cityName,
      zip: values.zipCode,
      state_id: values.stateName,
      updated_by: 1,
      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};
const updateCityListUser = (id, values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "user/city/update/" + id,
    {
      city: values.cityName,
      zip: values.zipCode,
      state_id: values.stateName,
      updated_by: 1,
      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const updateProductList = (id, values) => {
  console.log(values);
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    `${API_URL}user/product/update/${id}`,
    {
      price: values.price,
      updated_by: values.updated_by,
      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};
const updateRsaList = (id, values) => {
  console.log(values);
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    `${API_URL}user/update-user-by-id/${id}`,
    {
      ssn: values.ssn_number,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      address1: values.address1,
      state_id: values.state,
      zip: values.zip,
      city_id: values.city,
      phone: values.phone,
      updated_by: values.updated_by,
      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};
const getStoreinfoById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/get-by-id-admin/" + id, {
    headers: headers,
  });
};

const updateStoreList = (id, values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "store/update/" + id,
    {
      name: values.storeName,
      address: values.storeAddress,
      zip: values.zipCode,
      account: values.storeAccount,
      dos: values.dos,
      ndos: values.ndos,
      city_id: values.city,
      updated_by: values.updated_by,
      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const updateUserList = (id, values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    API_URL + "user/update/" + id,
    {
      ssnNumber: values.ssn_number,
      udfNo: values.udf_no,
      firstName: values.first_name,
      LastName: values.last_name,
      userName: values.username,
      password: values.password,
      email: values.email,
      address: values.address1,
      state: values.state,
      city: values.city,
      zipCode: values.zip,
      addOn: values.add_on,
      updated_by: 1,
      updated_ip: secureLocalStorage.getItem("ip"),
    },
    {
      headers: headers,
    }
  );
};

const getUserById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/get-by-id/" + id, {
    headers: headers,
  });
};
const getRsaList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-rsa", {
    headers: headers,
  });
};
const getDosRsaList = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-dos-rsa/" + id, {
    headers: headers,
  });
};
const getManagerList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-manager", {
    headers: headers,
  });
};
const getDosManagerList = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-dos-manager/" + id, {
    headers: headers,
  });
};
const getCorporateRsaList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-corporate-rsa", {
    headers: headers,
  });
};
const getDeactiveRsaList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-deactive-rsa", {
    headers: headers,
  });
};
const GetDeactiveManagerList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-deactive-manager", {
    headers: headers,
  });
};
const Get1099ClaimsList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}user/claim/1099/list-claims`, {
    headers: headers,
  });
};

const getSearchRsaList = (col, val, store, limit, pageNo) => {
  if (col === "1") {
    col = "emp_number";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "username";
  } else if (col === "5") {
    col = "email";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "state";
  } else if (col === "8") {
    col = "city";
  } else if (col === "9") {
    col = "zip";
  } else if (col === "10") {
    col = "created_at";
  } else if (col === "11") {
    col = "store";
  } else if (col === "12") {
    col = "store_address";
  } else if (col === "13") {
    col = "store_city";
  } else if (col === "14") {
    col = "store_zip";
  } else if (col === "15") {
    col = "store_account";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  let query = ``;
  if (col && val && store) {
    query = `${API_URL}user/list-rsa?${col}=${val}&store=${store}&limit=${limit}&page=${pageNo}`;
  } else if (col && val) {
    query = `${API_URL}user/list-rsa?${col}=${val}&limit=${limit}&page=${pageNo}`;
  } else if (store) {
    query = `${API_URL}user/list-rsa?store=${store}&limit=${limit}&page=${pageNo}`;
  } else {
    query = `${API_URL}user/list-rsa?limit=${limit}&page=${pageNo}`;
  }
  return axios.get(query, {
    headers: headers,
  });
};

const getSearchDosRsaList = (id, col, val, limit, pageNo) => {
  if (col === "1") {
    col = "emp_number";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "username";
  } else if (col === "5") {
    col = "email";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "state";
  } else if (col === "8") {
    col = "city";
  } else if (col === "9") {
    col = "zip";
  } else if (col === "10") {
    col = "store";
  } else if (col === "11") {
    col = "store_address";
  } else if (col === "12") {
    col = "store_city";
  } else if (col === "13") {
    col = "store_zip";
  }

  const headers = {
    "Content-Type": "application/json",
  };
  let query = ``;
  if (col && val) {
    query = `${API_URL}user/list-dos-rsa/${id}?${col}=${val}&limit=${limit}&page=${pageNo}`;
  } else {
    query = `${API_URL}user/list-dos-rsa/${id}?limit=${limit}&page=${pageNo}`;
  }
  return axios.get(query, {
    headers: headers,
  });
};
const getSearchManagerList = (col, val, limit, pageNo) => {
  if (col === "1") {
    col = "emp_number";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "username";
  } else if (col === "5") {
    col = "email";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "state";
  } else if (col === "8") {
    col = "city";
  } else if (col === "9") {
    col = "zip";
  } else if (col === "10") {
    col = "created_at";
  } else if (col === "11") {
    col = "store";
  } else if (col === "12") {
    col = "store_address";
  } else if (col === "13") {
    col = "store_city";
  } else if (col === "14") {
    col = "store_zip";
  }

  const headers = {
    "Content-Type": "application/json",
  };

  if (col !== "" && val !== "") {
    console.log("col");
    return axios.get(
      API_URL +
        "user/list-manager?" +
        col +
        "=" +
        val +
        "&limit=" +
        limit +
        "&page=" +
        pageNo,
      {
        headers: headers,
      }
    );
  } else {
    console.log("limit");
    return axios.get(
      API_URL + "user/list-manager?limit=" + limit + "&page=" + pageNo,
      {
        headers: headers,
      }
    );
  }
};

const getSearchDosManagerList = (id, col, val, limit, pageNo) => {
  if (col === "1") {
    col = "emp_number";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "username";
  } else if (col === "5") {
    col = "email";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "state";
  } else if (col === "8") {
    col = "city";
  } else if (col === "9") {
    col = "zip";
  } else if (col === "10") {
    col = "store";
  } else if (col === "11") {
    col = "store_address";
  } else if (col === "12") {
    col = "store_city";
  } else if (col === "13") {
    col = "store_zip";
  }

  const headers = {
    "Content-Type": "application/json",
  };

  if (col !== "" && val !== "") {
    console.log("col");
    return axios.get(
      `${API_URL}user/list-dos-manager/${id}?${col}=${val}&limit=${limit}&page=${pageNo}`,
      {
        headers: headers,
      }
    );
  } else {
    console.log("limit");
    return axios.get(
      `${API_URL}user/list-dos-manager/${id}?limit=${limit}&page=${pageNo}`,
      {
        headers: headers,
      }
    );
  }
};
const getSearchCorporateRsaList = (col, val, limit, pageNo) => {
  if (col === "1") {
    col = "emp_number";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "username";
  } else if (col === "5") {
    col = "email";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "state";
  } else if (col === "8") {
    col = "city";
  } else if (col === "9") {
    col = "zip";
  } else if (col === "10") {
    col = "created_at";
  } else if (col === "11") {
    col = "store";
  } else if (col === "12") {
    col = "store_address";
  } else if (col === "13") {
    col = "store_city";
  } else if (col === "14") {
    col = "store_zip";
  }

  const headers = {
    "Content-Type": "application/json",
  };

  if (col !== "" && val !== "") {
    console.log("col");
    return axios.get(
      API_URL +
        "user/list-corporate-rsa?" +
        col +
        "=" +
        val +
        "&limit=" +
        limit +
        "&page=" +
        pageNo,
      {
        headers: headers,
      }
    );
  } else {
    console.log("limit");
    return axios.get(
      API_URL + "user/list-corporate-rsa?limit=" + limit + "&page=" + pageNo,
      {
        headers: headers,
      }
    );
  }
};
const getSearchDeactiveRsaList = (col, val, limit, pageNo) => {
  if (col === "1") {
    col = "emp_number";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "username";
  } else if (col === "5") {
    col = "email";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "state";
  } else if (col === "8") {
    col = "city";
  } else if (col === "9") {
    col = "zip";
  } else if (col === "10") {
    col = "created_at";
  } else if (col === "11") {
    col = "store";
  } else if (col === "12") {
    col = "store_address";
  } else if (col === "13") {
    col = "store_city";
  } else if (col === "14") {
    col = "store_zip";
  }

  const headers = {
    "Content-Type": "application/json",
  };

  if (col !== "" && val !== "") {
    console.log("col");
    return axios.get(
      API_URL +
        "user/list-deactive-rsa?" +
        col +
        "=" +
        val +
        "&limit=" +
        limit +
        "&page=" +
        pageNo,
      {
        headers: headers,
      }
    );
  } else {
    console.log("limit");
    return axios.get(
      API_URL + "user/list-deactive-rsa?limit=" + limit + "&page=" + pageNo,
      {
        headers: headers,
      }
    );
  }
};

const getsearchDeactiveManagerList = (col, val, limit, pageNo) => {
  if (col === "1") {
    col = "emp_number";
  } else if (col === "2") {
    col = "first_name";
  } else if (col === "3") {
    col = "last_name";
  } else if (col === "4") {
    col = "username";
  } else if (col === "5") {
    col = "email";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "state";
  } else if (col === "8") {
    col = "city";
  } else if (col === "9") {
    col = "zip";
  } else if (col === "10") {
    col = "created_at";
  } else if (col === "11") {
    col = "store";
  } else if (col === "12") {
    col = "store_address";
  } else if (col === "13") {
    col = "store_city";
  } else if (col === "14") {
    col = "store_zip";
  }

  const headers = {
    "Content-Type": "application/json",
  };

  if (col !== "" && val !== "") {
    console.log("col");
    return axios.get(
      API_URL +
        "user/list-deactive-manager?" +
        col +
        "=" +
        val +
        "&limit=" +
        limit +
        "&page=" +
        pageNo,
      {
        headers: headers,
      }
    );
  } else {
    console.log("limit");
    return axios.get(
      API_URL + "user/list-deactive-manager?limit=" + limit + "&page=" + pageNo,
      {
        headers: headers,
      }
    );
  }
};

const getsearch1099ClaimsList = (col, val, limit, pageNo) => {
  if (col === "1") {
    col = "amount";
  } else if (col === "2") {
    col = "email";
  } else if (col === "3") {
    col = "first_name";
  } else if (col === "4") {
    col = "last_name";
  } else if (col === "5") {
    col = "address";
  } else if (col === "6") {
    col = "city";
  } else if (col === "7") {
    col = "state";
  } else if (col === "8") {
    col = "zip";
  } else if (col === "9") {
    col = "emp_number";
  }

  const headers = {
    "Content-Type": "application/json",
  };

  if (col !== "" && val !== "") {
    console.log("col");
    return axios.get(
      `${API_URL}user/claim/1099/list-claims?${col}=${val}&limit=${limit}&page=${pageNo}`,
      {
        headers: headers,
      }
    );
  } else {
    return axios.get(
      `${API_URL}user/claim/1099/list-claims?limit=${limit}&page=${pageNo}`,
      {
        headers: headers,
      }
    );
  }
};
const getSearchRsaByDate = (col, val, limit, pageNo) => {
  if (col === "1") {
    col = "first_name";
  } else if (col === "2") {
    col = "last_name";
  } else if (col === "3") {
    col = "username";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "store";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "zip";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/list-rsa?" +
      col +
      "=" +
      val +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};
const getSearchDosRsaByDate = (id, col, val, createDate, limit, pageNo) => {
  if (col === "1") {
    col = "first_name";
  } else if (col === "2") {
    col = "last_name";
  } else if (col === "3") {
    col = "username";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "store";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "zip";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/list-dos-rsa?" +
      id +
      col +
      "=" +
      val +
      "&created_at=" +
      createDate +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};

const getSearchManagerByDate = (col, val, createDate, limit, pageNo) => {
  if (col === "1") {
    col = "first_name";
  } else if (col === "2") {
    col = "last_name";
  } else if (col === "3") {
    col = "username";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "store";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "zip";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/list-manager?" +
      col +
      "=" +
      val +
      "&created_at=" +
      createDate +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};
const getSearchDosManagerByDate = (id, col, val, createDate, limit, pageNo) => {
  if (col === "1") {
    col = "first_name";
  } else if (col === "2") {
    col = "last_name";
  } else if (col === "3") {
    col = "username";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "store";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "zip";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}user/list-dos-manager/${id}?${col}col=${val}&created_at=${createDate}&limit=${limit}&page=${pageNo}`,
    {
      headers: headers,
    }
  );
};
const getSearchCorporateRsaByDate = (col, val, createDate, limit, pageNo) => {
  if (col === "1") {
    col = "first_name";
  } else if (col === "2") {
    col = "last_name";
  } else if (col === "3") {
    col = "username";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "store";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "zip";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/list-corporate-rsa?" +
      col +
      "=" +
      val +
      "&created_at=" +
      createDate +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};
const getSearchDeactiveRsaByDate = (col, val, createDate, limit, pageNo) => {
  if (col === "1") {
    col = "first_name";
  } else if (col === "2") {
    col = "last_name";
  } else if (col === "3") {
    col = "username";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "store";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "zip";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/list-deactive-rsa?" +
      col +
      "=" +
      val +
      "&created_at=" +
      createDate +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};

const getSearchDeactiveManagerByDate = (
  col,
  val,
  createDate,
  limit,
  pageNo
) => {
  if (col === "1") {
    col = "first_name";
  } else if (col === "2") {
    col = "last_name";
  } else if (col === "3") {
    col = "username";
  } else if (col === "4") {
    col = "email";
  } else if (col === "5") {
    col = "store";
  } else if (col === "6") {
    col = "address";
  } else if (col === "7") {
    col = "zip";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL +
      "user/list-deactive-manager?" +
      col +
      "=" +
      val +
      "&created_at=" +
      createDate +
      "&limit=" +
      limit +
      "&page=" +
      pageNo,
    {
      headers: headers,
    }
  );
};
const deleteParticipantsData = (recordId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.delete(API_URL + "user/delete/" + recordId, {
    headers: headers,
    data: {
      deleted_by: 1,
      deleted_ip: secureLocalStorage.getItem("ip"),
    },
  });
};
const deactiveParticipantsData = (admin, recordId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(`${API_URL}user/deactivate/${admin}/${recordId}`, {
    headers: headers,
  });
};

const activeParticipantsData = (admin, recordId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(`${API_URL}user/activate/${admin}/${recordId}`, {
    headers: headers,
  });
};
const deactiveTerritoriesData = (admin, recordId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    `${API_URL}store/territory/deactivate/${admin}/${recordId}`,
    {
      headers: headers,
    }
  );
};

const activeTerritoriesData = (admin, recordId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(`${API_URL}store/territory/activate/${admin}/${recordId}`, {
    headers: headers,
  });
};

const deleteSelectedParticipants = (recordId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.delete(API_URL + "user/delete-selected", {
    headers: headers,
    data: {
      deleted_by: 1,
      user_id: recordId,
    },
  });
};

const deleteProduct = (recordId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.delete(`${API_URL}user/product/delete/${recordId}`, {
    headers: headers,
    data: {
      deleted_by: 1,
      deleted_ip: secureLocalStorage.getItem("ip"),
    },
  });
};

const getNewLimitDosList = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-dos" + "?limit=" + limit, {
    headers: headers,
  });
};
const getNewLimitNDosList = (limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-ndos" + "?limit=" + limit, {
    headers: headers,
  });
};
const getSearchDosList = (col, val, limit) => {
  if (col === "1") {
    col = "first_name";
  } else if (col === "2") {
    col = "username";
  } else if (col === "3") {
    col = "last_name";
  }

  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/list-dos" + "?" + col + "=" + val + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};
const getSearchNDosList = (col, val, limit) => {
  if (col === "1") {
    col = "first_name";
  } else if (col === "2") {
    col = "username";
  } else if (col === "3") {
    col = "last_name";
  }

  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/list-ndos" + "?" + col + "=" + val + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};
const getPaginationDosList = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/list-dos" + "?page=" + pageNo + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};
const getPaginationNDosList = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    API_URL + "user/list-ndos" + "?page=" + pageNo + "&limit=" + limit,
    {
      headers: headers,
    }
  );
};
const getDosList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-dos", {
    headers: headers,
  });
};
const getNDosList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "user/list-ndos", {
    headers: headers,
  });
};

const getStoreNameSelectOptions = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(API_URL + "store/territory/listStoreName", {
    headers: headers,
  });
};

const getNewsletterList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}user/newsletter/list`, {
    headers: headers,
  });

  // return axios.get(
  //   `${API_URL}user/newsletter/list&page=${pageNo}&limit=${limit}`,
  //   {
  //     headers: headers,
  //   }
  // );
};
const getNewsletterKingsList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}user/newsletter/list-kingswere`, {
    headers: headers,
  });
};

const getNewsletterGenList = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}user/newsletter/list-newsletter`, {
    headers: headers,
  });
};

const getNewsletterById = (id) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}user/newsletter/get-by-id/${id}`, {
    headers: headers,
  });
};

const updateNewsletterById = (id, body) => {
  const headers = {
    "Content-Type": "application/json",
  };
  // console.log(body);
  return axios.put(`${API_URL}user/newsletter/update/${id}`, body, {
    headers: headers,
  });
};

const getSearchNewsletterList = (col, val, limit) => {
  if (col === "1") {
    col = "title";
  } else if (col === "2") {
    col = "zip";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}user/newsletter/list?${col}=${val}&limit=${limit}`,
    {
      headers: headers,
    }
  );
};
const getSearchNewsletterKingsList = (col, val, limit) => {
  if (col === "1") {
    col = "title";
  } else if (col === "2") {
    col = "zip";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}user/newsletter/list-kingswere?${col}=${val}&limit=${limit}`,
    {
      headers: headers,
    }
  );
};
const getPaginationNewsletterList = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}user/newsletter/list?page=${pageNo}&limit=${limit}`,
    {
      headers: headers,
    }
  );
};
const getPaginationNewsletterKingsList = (pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}user/newsletter/list-kingswere?page=${pageNo}&limit=${limit}`,
    {
      headers: headers,
    }
  );
};
const getPaginationUserByDevice = (user, device, pageNo, limit) => {
  const headers = {
    "Content-Type": "application/json",
  };

  return axios.get(
    `${API_URL}user/stats/list-user/${user}/${device}?page=${pageNo}&limit=${limit}`,
    {
      headers: headers,
    }
  );
};

const getUserByDevice = (user, device) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}user/stats/list-user/${user}/${device}`, {
    headers: headers,
  });
};

const getAllYears = () => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}user/reports/list-all-years`, {
    headers: headers,
  });
};
const importTinaSheet = (fileUp) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return axios.post(API_URL + "user/1099/tina-sheet", fileUp, headers);
};
const importExpiredCard = (fileUp) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return axios.post(API_URL + "user/1099/expired-cards", fileUp, headers);
};
const importMissingSales = (fileUp, date) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return axios.post(
    `${API_URL}user/1099/missing-sales/${date}`,
    fileUp,
    headers
  );
};

const addSaleTable = (values) => {
  // console.log(values);
  const { sales, date, start, end } = values;

  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}user/1099/separate-monthly-sales/${sales}/${date}/${start}/${end}`,
    {
      headers: headers,
    }
  );
};

const AdminListService = {
  login,
  login1099,
  forgotPass,
  getStateList,
  getNewLimitUserStateList,
  getSearchUserStateList,
  getPaginationUserStateList,
  getUserStateList,
  getStoreAddressSelectOptions,
  getNdosSelectOptions,
  getNewsLetterSelectOptions,
  getDosSelectOptions,
  getZipSelectOptions,
  getAccountSelectOptions,
  getCitySelectOptions,
  getTerritoriesList,
  getTerritoriesDosList,
  getSearchTerritoriesList,
  getSearchRsaStoreList,
  getSearchTerritoriesDosList,
  puttStateList,
  getNewLimitStateList,
  getSearchStateList,
  getPaginationStateList,
  addCity,
  addState,
  addUserState,
  addUserCity,
  addProduct,
  addProductSize,
  getNewLimitCityList,
  getSearchCityList,
  getPaginationCityList,
  getCityList,
  addStore,
  getNewLimitStoreList,
  getNewLimitProductList,
  getSearchStoreList,
  getSearchReportHistory,
  getReportHistoryLimit,
  getPaginatedReportHistory,
  getReportHistoryList,
  getSearchProductList,
  getUserByDeviceSearchLimit,
  getPaginationStoreList,
  getPaginationProductList,
  getStoreList,
  getProductList,
  getUserBalanceList,
  getUserComparisonList,
  getStateNameById,
  updateStateNameById,
  getCityInfoById,
  getProductInfoById,
  getProductPriceById,
  getProductPriceBySizeId,
  updateCityList,
  updateCityListUser,
  updateProductList,
  updateRsaList,
  getStoreinfoById,
  updateStoreList,
  updateUserList,
  getRsaList,
  getDosRsaList,
  getManagerList,
  getDosManagerList,
  getCorporateRsaList,
  getDeactiveRsaList,
  GetDeactiveManagerList,
  Get1099ClaimsList,
  getSearchRsaList,
  getSearchDosRsaList,
  getSearchManagerList,
  getSearchDosManagerList,
  getSearchCorporateRsaList,
  getSearchDeactiveRsaList,
  getsearchDeactiveManagerList,
  getsearch1099ClaimsList,
  getSearchRsaByDate,
  getSearchDosRsaByDate,
  getSearchManagerByDate,
  getSearchDosManagerByDate,
  getSearchCorporateRsaByDate,
  getSearchDeactiveRsaByDate,
  getSearchDeactiveManagerByDate,
  deleteParticipantsData,
  deactiveParticipantsData,
  activeParticipantsData,
  deactiveTerritoriesData,
  activeTerritoriesData,
  deleteSelectedParticipants,
  deleteProduct,
  getNewLimitDosList,
  getNewLimitNDosList,
  getSearchDosList,
  getSearchNDosList,
  getPaginationDosList,
  getPaginationNDosList,
  getDosList,
  getNDosList,
  getStoreNameSelectOptions,
  getUserById,
  getNewsletterList,
  getNewsletterGenList,
  getNewsletterById,
  updateNewsletterById,
  getNewsletterKingsList,
  getSearchNewsletterList,
  getSearchNewsletterKingsList,
  getPaginationNewsletterList,
  getPaginationNewsletterKingsList,
  getUserByDevice,
  getPaginationUserByDevice,
  getAllYears,
  importTinaSheet,
  importExpiredCard,
  importMissingSales,
  addSaleTable,
  updateStateNameByIdUser,
  getStateNameByIdUser,
  getCityListUser,
  getCityInfoByIdUser,
  getSearchCityListUser,
  getPaginationCityListUser,
  getNewLimitCityListUser,
};

export default AdminListService;
